import { Preferences } from '@capacitor/preferences'

export const key = 'regex-state'

export const loadState = async <State = any>() => {
	try {
		const { value } = await Preferences.get({ key })
		if (value !== null) {
			return JSON.parse(value) as State | undefined
		}
	} catch {
		/* ignore */
	}

	return undefined
}

export const saveState = async (state: any) => {
	try {
		const value = JSON.stringify(state, filterProps)
		await Preferences.set({ key, value })
	} catch {
		/* ignore */
	}
}

/** Set props like "error" to undefined when saving state. */
const filterProps = (key: string, value: any) =>
	key === 'error' || key === 'pending' ? undefined : value
