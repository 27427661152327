export function scrollTo(elm: HTMLElement, to: number, duration = 500) {
	const start = elm.scrollLeft
	const change = to - start
	const increment = 20
	let currentTime = 0

	const animateScroll = () => {
		currentTime += increment

		const val = easeInOutQuad(currentTime, start, change, duration)

		elm.scrollTo(val, val)

		if (currentTime < duration) {
			requestAnimationFrame(animateScroll)
		}
	}

	animateScroll()
}

// easing functions http://goo.gl/5HLl8
const easeInOutQuad = function (t: number, b: number, c: number, d: number) {
	t /= d / 2
	if (t < 1) {
		return (c / 2) * t * t + b
	}
	t--
	return (-c / 2) * (t * (t - 2) - 1) + b
}
/*
const easeInCubic = function (t, b, c, d) {
	var tc = (t /= d) * t * t
	return b + c * tc
}

const inOutQuintic = function (t, b, c, d) {
	var ts = (t /= d) * t,
		tc = ts * t
	return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc)
}
*/
