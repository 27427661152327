const importAll = (r: __WebpackModuleApi.RequireContext) =>
	r.keys().reduce((map, item) => {
		map[item.replace('./', '')] = r(item)
		return map
	}, {} as { [key: string]: string })

const images = importAll(
	require.context('../assets/images/achievements', false, /\.webp$/)
)

export const getImgPath = (id: string) => images[`${id}@2.webp`]
