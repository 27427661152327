import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app'

export const AppUrlListener: React.FC<any> = () => {
	const navigate = useNavigate()

	useEffect(() => {
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			const path = new URL(event.url).pathname
			if (path) {
				navigate(path)
			}
		})
	}, [navigate])

	return null
}
