import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar } from '@capacitor/status-bar'
import { polyfill as dragDropPolyfill } from 'mobile-drag-drop'
import { noop } from './noop'

// Still necessary for some browsers (iOS)
dragDropPolyfill({
	// Necessary for iPad Air 5th gen+ as user agent no longer includes "iPad"
	forceApply: Capacitor.getPlatform() === 'ios',
})

// Try to disable overscroll/"drag page" on iOS
window.addEventListener('touchmove', () => undefined, { passive: false })

// Hide the splash (normally shows for 3s)
SplashScreen.hide().catch(noop)

// Hide status bar on iOS/Android
StatusBar.hide().catch(noop)
