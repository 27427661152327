import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'
import { tutorialSelector } from '../../redux/selectors'
import { Puzzle } from '../Puzzle/Puzzle'
import { FloatingBox } from '../common/FloatingBox/FloatingBox'
import { Seo } from '../common/Seo/Seo'

export const Tutorial5: React.FC = (props) => {
	const navigate = useNavigate()

	const tutorial = useAppSelector(tutorialSelector)

	return (
		<div>
			<Seo title="Tutorial: 5" />
			<FloatingBox pointer="top" selector="[data-testid=clues]" show>
				<p>
					<b>Special characters</b> <code>\w</code>, <code>\d</code> and{' '}
					<code>\s</code> represents a <b>word</b> (letter), digit or
					whitespace.
				</p>
			</FloatingBox>
			<FloatingBox
				pointer="bottom"
				selector="[data-testid=keyboard] [data-value=' ']"
				show
			>
				Black means a <b>space</b>.
			</FloatingBox>
			<Puzzle
				{...props}
				onBackNavigation={() => navigate('/')}
				onSuccessDismiss={() => navigate('/puzzles')}
				puzzle={tutorial[4]}
				showErrors
			/>
		</div>
	)
}
