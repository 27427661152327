// extracted by mini-css-extract-plugin
var _1 = "yVSdvVIdVCi5xv_eM7Sy";
var _2 = "cRIewl1wjfsNwx2eY4n6";
var _3 = "tuDvIyXjiVEmasw76N4t";
var _4 = "FqVy3dROP_r7ESh2RJrO";
var _5 = "bYnJTnT0klWuRIz8EABK";
var _6 = "piCiLqt6S6obaDoJqOw8";
var _7 = "oZnTnXPFJ9zkgypqQn6E";
var _8 = "u_wx8XUHWFqOe_946C3T";
export { _1 as "active", _2 as "button", _3 as "disabled", _4 as "hover", _5 as "linkButton", _6 as "loading", _7 as "secondary", _8 as "transparent" }
export default { "active": _1, "button": _2, "disabled": _3, "hover": _4, "linkButton": _5, "loading": _6, "secondary": _7, "transparent": _8 }
