import React from 'react'
import { useAppSelector } from '../../redux/hooks'
import { isAuthenticatedSelector } from '../../redux/selectors'
import { Login } from '../Login/Login'

export const Secure: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const isAuthenticated = useAppSelector(isAuthenticatedSelector)
	if (!isAuthenticated) {
		return <Login />
	}
	return <>{children}</>
}
