import React from 'react'
import { useAppSelector } from '../../../redux/hooks'
import { prefersReducedMotionSelector } from '../../../redux/selectors'
import styles from './Snow.scss'

const isDecember = new Date().getMonth() === 11

export const Snow: React.FC = () => {
	const reducedMotion = useAppSelector(prefersReducedMotionSelector)

	if (!isDecember || reducedMotion) {
		return null
	}

	return (
		<div className={styles.snow} aria-hidden="true">
			{new Array(50).fill(0).map((_, i) => (
				<div className={styles.snowflake} key={`snowflake-${i}`} />
			))}
		</div>
	)
}
