import classNames from 'classnames'
import { throttle } from 'lodash-es'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './FloatingBox.scss'

type Position = {
	top: number
	left: number
	transform?: string
}

type Props = React.HTMLAttributes<HTMLDivElement> & {
	/** Delay calculation of the position to account for animations in progress. */
	delay?: number
	/** Display a pointer in a given direction. */
	pointer?: 'top' | 'right' | 'bottom' | 'left'
	/** Selector for the element to position next to. */
	selector: string
	/** Should component be visible. */
	show?: boolean
}

export const FloatingBox: React.FC<Props> = (props) => {
	const [node, setNode] = useState<Element | null>(null)
	const [pos, setPos] = useState<Position>()

	useEffect(
		() => setNode(document.querySelector(props.selector)),
		[props.selector]
	)

	useLayoutEffect(() => {
		const handleResize = throttle(() => setPos(getElementPosition(node)), 200)
		window.addEventListener('resize', handleResize)
		setTimeout(handleResize, props.delay || 100)
		return () => window.removeEventListener('resize', handleResize)
	}, [node, props.delay])

	if (!pos) {
		return null
	}

	return (
		<CSSTransition
			appear
			in={props.show}
			classNames="fade"
			timeout={300}
			unmountOnExit
		>
			<div
				className={classNames(
					styles.floatingBox,
					{
						[styles.pointer]: !!props.pointer,
						[styles.pointerTop]: props.pointer === 'top',
						[styles.pointerRight]: props.pointer === 'right',
						[styles.pointerBottom]: props.pointer === 'bottom',
						[styles.pointerLeft]: props.pointer === 'left',
					},
					props.className
				)}
				style={pos}
			>
				{props.children}
			</div>
		</CSSTransition>
	)
}

const getElementPosition = (node: Element | null) => {
	if (!node) {
		return undefined
	}

	const rect = node.getBoundingClientRect()
	const p: Position = {
		left: Math.max(10, rect.left),
		top: Math.max(10, rect.bottom + 10),
	}

	if (p.top > window.innerHeight - 100) {
		p.top = Math.max(10, rect.top - 10)
		p.transform = 'translateY(-100%)'
	}

	return p
}
