import { Share } from '@capacitor/share'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import iconSharing from '../../../assets/images/icons/share.svg'
import config from '../../../config'
import type { Puzzle } from '../../../redux/api/index'
import { noop } from '../../../services/noop'
import { Button } from '../../common/Button/Button'
import { Dialog } from '../../common/Dialog/Dialog'
import { IconButton } from '../../common/IconButton/IconButton'
import { Pill } from '../../common/Pill/Pill'
import styles from './PlayerPuzzleAbout.scss'

type Props = {
	puzzle: Pick<
		Puzzle,
		'id' | 'playerNo' | 'name' | 'ratingAvg' | 'votes' | 'dateUpdated'
	>
}

export const PlayerPuzzleAbout: React.FC<Props> = ({ puzzle }) => {
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)

	const handleShare = async () => {
		Share.share({
			title: `Regex Crossword: ${puzzle.name}`,
			text: 'Check out this cool puzzle on Regex Crossword!',
			url: `${config.host.url}/playerpuzzles/${puzzle.id}`,
			dialogTitle: 'Share with friends',
		})
			.catch(noop)
			.finally(handleClose)
	}

	return (
		<>
			<IconButton
				alt="Sharing"
				className={styles.openButton}
				onClick={() => setShow(true)}
				src={iconSharing}
			/>
			{show && (
				<Dialog onClose={handleClose}>
					<h2>{puzzle.name}</h2>
					<p>
						by Player{' '}
						<Link to={`/players/${puzzle.playerNo}`}>#{puzzle.playerNo}</Link>
						{puzzle.dateUpdated && (
							<div className={styles.date}>
								{new Date(puzzle.dateUpdated * 1000).toLocaleDateString()}
							</div>
						)}
					</p>
					<p>
						<Pill className={styles.pill}>Rating {puzzle.ratingAvg}</Pill>
						<Pill className={styles.pill}>Votes {puzzle.votes}</Pill>
					</p>
					<Button data-testid="share" onClick={handleShare}>
						Share...
					</Button>
				</Dialog>
			)}
		</>
	)
}
