import { createAsyncThunk } from '@reduxjs/toolkit'
import type { Network } from '../../services/social'

export const loginSocial = createAsyncThunk(
	'auth/loginSocial',
	async (network: Network) => {
		const { login } = await import('../../services/social')
		login(network)
	}
)
export const logoutSocial = createAsyncThunk(
	'auth/logoutSocial',
	async (network: Network) => {
		const { logout } = await import('../../services/social')
		logout(network)
	}
)
