import { IAnswer } from '@omichelsen/regex-lib'
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

type SolvedPayload = {
	puzzleId: string
	answer: IAnswer
	startTime: number
	endTime: number
}

export const solved = createAction<SolvedPayload>('solved')

export default createSlice({
	name: 'solved',
	initialState: [] as SolvedPayload[],
	reducers: {
		add: (state, action: PayloadAction<SolvedPayload>) => {
			state.push(action.payload)
		},
		remove: (state, action: PayloadAction<string>) => {
			const index = state.findIndex(
				({ puzzleId }) => puzzleId === action.payload
			)
			if (index !== -1) state.splice(index, 1)
		},
	},
})
