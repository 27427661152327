import classNames from 'classnames'
import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import iconQuestion from '../../../assets/images/icons/question.svg'
import { IconButton } from '../IconButton/IconButton'
import styles from './ContextMenu.scss'

type Props = {
	children: React.ReactNode
	className?: string
}

export const ContextMenu: React.FC<Props> = (props) => {
	const [show, setShow] = useState(false)
	return (
		<div className={classNames(styles.contextMenu, props.className)}>
			<IconButton
				alt="More info..."
				onClick={() => setShow(!show)}
				src={iconQuestion}
			/>
			<CSSTransition classNames="fade" in={show} unmountOnExit timeout={500}>
				<div className={styles.menu}>{props.children}</div>
			</CSSTransition>
		</div>
	)
}
