import classNames from 'classnames'
import React, { useState } from 'react'
import styles from './TextField.scss'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
	errorText?: string
	helperText?: string
	label?: string
	selectOnFocus?: boolean
}

const handleSelectOnFocus = (e: React.ChangeEvent<HTMLInputElement>) =>
	setTimeout(() => e.target.select(), 1)

export const TextField: React.FC<Props> = ({
	className,
	errorText,
	helperText,
	label,
	selectOnFocus,
	...rest
}) => {
	const [isDirty, setIsDirty] = useState(false)

	if (selectOnFocus) {
		rest.onFocus = handleSelectOnFocus
	}

	return (
		<label>
			{label && <span className={styles.label}>{label}</span>}
			<input
				className={classNames(styles.textField, className, {
					[styles.dirty]: isDirty,
				})}
				onBlur={() => setIsDirty(true)}
				type="text"
				{...rest}
			/>
			{errorText && <div className={styles.error}>{errorText}</div>}
			{!errorText && helperText && (
				<div className={styles.helper}>{helperText}</div>
			)}
		</label>
	)
}
