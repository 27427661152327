import classNames from 'classnames'
import React from 'react'
import styles from './AnimSquares.scss'

type Props = React.HTMLAttributes<HTMLElement> & {
	count?: number
}

export const AnimSquares: React.FC<Props> = ({
	className,
	count = 10,
	...rest
}) => (
	<ul
		{...rest}
		className={classNames(styles.squares, className)}
		aria-hidden="true"
	>
		{Array.from({ length: count }).map((_, i) => (
			<li key={i}></li>
		))}
	</ul>
)
