import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { logoutSocial } from '../../redux/auth/actions'
import slice from '../../redux/auth/slice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { authSelector } from '../../redux/selectors'
import { Button } from '../common/Button/Button'
import { Dialog } from '../common/Dialog/Dialog'
import { TextButton } from '../common/TextButton/TextButton'

export const LogoutButton: React.FC = () => {
	const navigate = useNavigate()

	const [show, setShow] = useState(false)

	const dispatch = useAppDispatch()

	const me = useAppSelector(authSelector)
	if (!me.isAuthenticated) {
		return null
	}

	const handleLogout = async () => {
		if (me.network) {
			dispatch(logoutSocial(me.network))
		} else {
			dispatch(slice.actions.logout())
		}
		navigate('/')
	}

	return (
		<>
			<TextButton onClick={() => setShow(true)}>Logout...</TextButton>
			{show && (
				<Dialog onClose={() => setShow(false)}>
					<p>Logout and clear data from device.</p>
					<Button data-testid="logout" onClick={handleLogout}>
						Logout
					</Button>
				</Dialog>
			)}
		</>
	)
}
