import React from 'react'
import { useGetStatsQuery } from '../../redux/api'
import { Content } from '../common/Content/Content'
import { Header } from '../common/Header/Header'
import { Meter } from '../common/Meter/Meter'
import { Navbar } from '../common/Navbar/Navbar'
import { RtkLoader } from '../common/RtkLoader/RtkLoader'
import { Screen } from '../common/Screen/Screen'
import styles from './Stats.scss'

export const Stats: React.FC = () => {
	const stats = useGetStatsQuery()

	const maxChallengeSolvedCount = stats.data?.challenges
		? Math.max(...stats.data.challenges.map(({ completed }) => completed))
		: 0

	return (
		<Screen
			title="Stats"
			description="Game stats showing number of players, puzzles, solved and how many has completed the Regex Crossword challenges."
		>
			<Navbar />
			<Header>
				<h1>Stats</h1>
			</Header>
			<RtkLoader className={styles.content} {...stats}>
				{(data) => (
					<Content>
						<div className={styles.stats}>
							<div className={styles.badge}>
								<span className={styles.number}>
									{data.totalUsers.toLocaleString()}
								</span>
								<span className={styles.label}>players</span>
							</div>
							<div className={styles.badge}>
								<span className={styles.number}>
									{data.totalPuzzles.toLocaleString()}
								</span>
								<span className={styles.label}>puzzles</span>
							</div>
							<div className={styles.badge}>
								<span className={styles.number}>
									{data.totalPlayerPuzzles.toLocaleString()}
								</span>
								<span className={styles.label}>player puzzles</span>
							</div>
							<div className={styles.badge}>
								<span className={styles.number}>
									{data.totalAnswers.toLocaleString()}
								</span>
								<span className={styles.label}>answers</span>
							</div>
						</div>
						<h2 className={styles.h2}>Challenges</h2>
						{data.challenges.map(({ name, completed }) => (
							<Meter
								key={name}
								label={name}
								max={maxChallengeSolvedCount}
								value={completed}
							/>
						))}
					</Content>
				)}
			</RtkLoader>
		</Screen>
	)
}
