import { cleanHexagonalAnswer } from '@omichelsen/regex-lib'
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
	useGetPuzzleQuery,
	useGetPuzzleSolutionQuery,
	useUpdatePuzzleMutation,
} from '../../redux/api'
import type { Puzzle } from '../../redux/api/index'
import { NotFound } from '../NotFound/NotFound'
import { RtkLoader } from '../common/RtkLoader/RtkLoader'
import { Seo } from '../common/Seo/Seo'
import { Builder } from './Builder'

export const BuilderContainer: React.FC = () => {
	const { puzzleId } = useParams()

	const puzzle = useGetPuzzleQuery(puzzleId!)
	const solution = useGetPuzzleSolutionQuery(puzzleId!)

	const [updatePuzzle] = useUpdatePuzzleMutation()
	const handleSave = useCallback(
		(puzzle: Puzzle) => updatePuzzle(puzzle).unwrap(),
		[updatePuzzle]
	)

	if (
		(puzzle.error as any)?.status === 404 ||
		(solution.isSuccess && !solution.data)
	) {
		return <NotFound />
	}

	const merged =
		puzzle.data && solution.data
			? sanitize({
					...JSON.parse(JSON.stringify(puzzle.data)),
					...solution.data,
			  })
			: undefined

	return (
		<RtkLoader {...puzzle} data={merged}>
			{(data) => (
				<>
					<Seo title={`Builder: ${data.name}`} />
					<Builder puzzle={data} onSave={handleSave as any} />
				</>
			)}
		</RtkLoader>
	)
}

/** legacy cleanup of existing puzzles */
const sanitize = (p: Puzzle) => {
	if (p.hexagonal && p.solution) {
		p.solution = cleanHexagonalAnswer(p.solution)
	}

	return p
}
