import { AppState } from './app/slice'

const queryStringParams = new URLSearchParams(window.location.search)

/** Subset of app settings that can be overwritten. */
type AppOverrides = Partial<Pick<AppState, 'useKeyboard'>>
const appKeys: Array<keyof AppOverrides> = ['useKeyboard']

export const overrides = {
	/** Subset of app settings. */
	app: appKeys.reduce((o, key) => {
		if (queryStringParams.has(key)) {
			o[key] = Boolean(queryStringParams.get(key))
		}
		return o
	}, {} as AppOverrides),
	/** List of solved puzzles (for recordings and tests). */
	solved: queryStringParams
		.get('solved')
		?.split(',')
		.reduce((o, id) => {
			o[id] = true
			return o
		}, {} as Record<string, true>),
}
