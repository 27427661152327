import { useEffect, useState } from 'react'

/**
 * Listen for a media query and return whether it matches.
 * @param query Media query to listen to.
 */
export const useMediaQuery = (query: string) => {
	const [matches, setMatches] = useState(false)

	useEffect(() => {
		const mediaQuery = window.matchMedia(query)
		const changeHandler = () => setMatches(mediaQuery.matches)

		changeHandler()

		mediaQuery.addEventListener('change', changeHandler)

		return () => {
			mediaQuery.removeEventListener('change', changeHandler)
		}
	}, [query])

	return matches
}
