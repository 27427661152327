import React, { useState } from 'react'
import { Disqus } from '../Disqus/Disqus'
import { IconButton } from '../common/IconButton/IconButton'
import { Modal } from '../common/Modal/Modal'
import styles from './Comments.scss'
import iconBubbles from './bubbles.svg'

export const Comments: React.FC<{
	puzzleId: string
	puzzleName: string
}> = ({ puzzleId, puzzleName }) => {
	const [show, setShow] = useState(false)

	return (
		<>
			<IconButton
				alt="Comments"
				className={styles.icon}
				onClick={() => setShow(true)}
				src={iconBubbles}
			/>
			<Modal
				className={styles.modal}
				onClose={() => setShow(false)}
				show={show}
			>
				<Disqus
					shortname="regexcrossword"
					identifier={puzzleId}
					url={`https://regexcrossword.com/playerpuzzles/${puzzleId}`}
					pageTitle={puzzleName}
				/>
			</Modal>
		</>
	)
}
