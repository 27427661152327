import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { wait } from '../../../services/wait'

type Props = {
	children?: React.ReactNode
	loader: React.ReactNode
	rehydrator: Promise<any>
}

export const ReduxRehydrateGate: React.FC<Props> = ({
	children,
	loader,
	rehydrator,
}) => {
	const [done, setDone] = useState(false)

	useEffect(() => {
		// show loader for at least `wait` time before resolving
		Promise.all([rehydrator, wait(500)]).then(() => setDone(true))
	}, [rehydrator])

	return (
		<>
			<CSSTransition
				classNames="fadeScaleOut"
				in={!done}
				unmountOnExit
				timeout={300}
			>
				{loader}
			</CSSTransition>
			{done && children}
		</>
	)
}
