import React from 'react'
import { Button } from '../common/Button/Button'
import { Svg } from '../common/Svg/Svg'
import styles from './SolvedChallenge.scss'
import imgTrophy from './trophy.svg'

type Props = {
	dismiss?: () => void
}

export const SolvedChallenge: React.FC<Props> = ({ dismiss }) => (
	<div className={styles.solved} onClick={dismiss}>
		<div className={styles.background}>
			<div className={styles.rays} />
		</div>
		<div className={styles.content}>
			<Svg className={styles.trophy} src={imgTrophy} title="Trophy" />
			<div className={styles.text}>Completed!</div>
		</div>
		<Button className={styles.nextButton}>Home</Button>
	</div>
)
