import { debounce } from 'lodash-es'
import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetPlayerQuery, useUpdatePlayerMutation } from '../../redux/api'
import app from '../../redux/app/slice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { appSelector, authSelector } from '../../redux/selectors'
import { LogoutButton } from '../LogoutButton/LogoutButton'
import { Button } from '../common/Button/Button'
import { Content } from '../common/Content/Content'
import { Header } from '../common/Header/Header'
import { InputLine } from '../common/InputLine/InputLine'
import { Navbar } from '../common/Navbar/Navbar'
import { Seo } from '../common/Seo/Seo'
import { Switch } from '../common/Switch/Switch'
import { TextButton } from '../common/TextButton/TextButton'
import { TextField } from '../common/TextField/TextField'
import styles from './Settings.scss'

const { toggleHaptics, toggleKeyboard, toggleMusic, toggleSoundEffects } =
	app.actions

export const Settings: React.FC = () => {
	const navigate = useNavigate()

	const dispatch = useAppDispatch()
	const {
		disableHaptics,
		disableMusic,
		disableSoundEffects,
		prefersReducedMotion,
		useKeyboard,
	} = useAppSelector(appSelector)

	const auth = useAppSelector(authSelector)

	const formRef = useRef<HTMLFormElement>(null)

	const me = useGetPlayerQuery(auth.playerNo!)
	const [updatePlayer] = useUpdatePlayerMutation()

	const handleNicknameChange = debounce(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (formRef.current?.reportValidity()) {
				updatePlayer({
					playerNo: auth.playerNo!,
					nickname: e.target.value,
				})
			}
		},
		500
	)

	return (
		<>
			<Seo
				title="Settings"
				description="Toggle game settings such as music, effects, keyboard, haptics and reduced motion."
			/>
			<Navbar>
				<LogoutButton />
			</Navbar>
			<Header>
				<h1>Settings</h1>
			</Header>
			<Content className={styles.content}>
				<section className={styles.section}>
					<form ref={formRef} onSubmit={(e) => e.preventDefault()}>
						<TextField
							autoComplete="nickname"
							data-testid="nickname"
							label="Nickname"
							placeholder="Nickname"
							pattern="^[a-zA-Z0-9_]{3,15}$"
							minLength={3}
							maxLength={15}
							defaultValue={me.data?.nickname}
							onChange={handleNicknameChange}
						/>
					</form>
				</section>
				<section className={styles.section}>
					<InputLine>
						<span>Music</span>
						<Switch
							checked={!disableMusic}
							onChange={() => dispatch(toggleMusic())}
						/>
					</InputLine>
					<InputLine>
						<span>Sound effects</span>
						<Switch
							checked={!disableSoundEffects}
							onChange={() => dispatch(toggleSoundEffects())}
						/>
					</InputLine>
					<InputLine>
						<span>
							Keyboard
							<span className={styles.note}>
								Enable keyboard input in addition to touch
							</span>
						</span>
						<Switch
							checked={useKeyboard}
							onChange={() => dispatch(toggleKeyboard())}
						/>
					</InputLine>
					<InputLine>
						<span>Haptics (vibration)</span>
						<Switch
							checked={!disableHaptics}
							onChange={() => dispatch(toggleHaptics())}
						/>
					</InputLine>
					<InputLine>
						<span>
							Reduced motion
							<span className={styles.note}>Controlled in system settings</span>
						</span>
						<Switch checked={prefersReducedMotion} disabled />
					</InputLine>
				</section>
				{auth.hasUsername ? (
					<Button
						data-testid="changePasswordButton"
						onClick={() => navigate('/settings/password')}
					>
						Change password...
					</Button>
				) : (
					<>
						<p>
							To login without your social account you can optionally set a
							username and password.
						</p>
						<Button onClick={() => navigate('/register')}>
							Set username and password
						</Button>
					</>
				)}
				<div className={styles.footer}>
					<div className={styles.delete}>
						<TextButton onClick={() => navigate('/settings/delete')}>
							Delete player...
						</TextButton>
					</div>
					<div className={styles.version}>
						Version: {new Date(__BUILDTS__).toLocaleString()}
					</div>
				</div>
			</Content>
		</>
	)
}
