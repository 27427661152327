import { orderBy } from 'lodash-es'
import React, { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FixedSizeList as List } from 'react-window'
import useResizeObserver from 'use-resize-observer'
import iconTrophy from '../../assets/images/icons/trophy.svg'
import { useGetPlayerPuzzlesQuery } from '../../redux/api'
import app from '../../redux/app/slice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { appSelector, solvedSelector } from '../../redux/selectors'
import { ButtonGroup } from '../common/ButtonGroup/ButtonGroup'
import { Header } from '../common/Header/Header'
import { IconButton } from '../common/IconButton/IconButton'
import { Navbar } from '../common/Navbar/Navbar'
import { RtkLoader } from '../common/RtkLoader/RtkLoader'
import { Screen } from '../common/Screen/Screen'
import { Select } from '../common/Select/Select'
import { Switch } from '../common/Switch/Switch'
import { TextButton } from '../common/TextButton/TextButton'
import styles from './PlayerPuzzles.scss'
import { PuzzleListItem } from './PuzzleListItem'

const { toggleDesktopPuzzles, sortPuzzlesBy: sortPuzzlesByAction } = app.actions

export const PlayerPuzzles: React.FC = () => {
	const { ref, height: heightContent = 1 } = useResizeObserver<HTMLDivElement>()
	const navigate = useNavigate()

	const dispatch = useAppDispatch()

	const { showDesktopPuzzles, sortPuzzlesBy } = useAppSelector(appSelector)
	const playerPuzzles = useGetPlayerPuzzlesQuery(showDesktopPuzzles)
	const solved = useAppSelector(solvedSelector)

	const [sortByField, sortByOrder] = sortPuzzlesBy.split(' ') as [
		string,
		'asc' | 'desc'
	]

	const sorted = useMemo(
		() =>
			orderBy(
				playerPuzzles.data?.map((p) => ({ ...p, ['solved']: p.id in solved })),
				[sortByField],
				[sortByOrder]
			),
		[playerPuzzles.data, sortByField, sortByOrder, solved]
	)

	return (
		<Screen
			className={styles.screen}
			title="Player puzzles"
			description="Puzzles created by the community, ranked by rating. You can also try
					and create some yourself?"
		>
			<Navbar onBackNavigation={() => navigate('/')}>
				<ButtonGroup>
					<TextButton
						data-testid="builderButton"
						onClick={() => navigate('/builder')}
					>
						Puzzle Builder
					</TextButton>
					<IconButton
						alt="Leaderboard"
						className={styles.icon}
						onClick={() => navigate('/leaderboard')}
						src={iconTrophy}
					/>
				</ButtonGroup>
			</Navbar>
			<Header>
				<h1>Player Puzzles</h1>
				<p>
					Puzzles created by the community. You can also{' '}
					<Link to="/builder/new/grid" style={{ color: 'black' }}>
						create some yourself
					</Link>
					?
				</p>
			</Header>
			<div className={styles.controls}>
				<label className={styles.group}>
					<Switch
						checked={showDesktopPuzzles}
						variant="small"
						onChange={() => dispatch(toggleDesktopPuzzles())}
					/>
					<div className={styles.label}>Desktop puzzles</div>
				</label>
				<Select
					className={styles.select}
					items={[
						{ value: 'name', label: 'Name' },
						{ value: 'rating', label: 'Rating' },
						{ value: 'solved', label: 'Solved' },
						{ value: 'dateUpdated desc', label: 'Date' },
					]}
					selected={sortPuzzlesBy}
					onChange={(value) => dispatch(sortPuzzlesByAction(value))}
				/>
			</div>
			<RtkLoader {...playerPuzzles} data={sorted} myRef={ref}>
				{(puzzles) => (
					<List
						className={styles.list}
						height={heightContent}
						itemCount={puzzles.length}
						itemSize={50}
						width="100%"
					>
						{({ index, style }) => (
							<PuzzleListItem
								puzzle={puzzles[index]}
								solved={puzzles[index].id in solved}
								style={style}
								url={`/playerpuzzles/${puzzles[index].id}`}
							/>
						)}
					</List>
				)}
			</RtkLoader>
		</Screen>
	)
}
