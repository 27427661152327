import React from 'react'
import { Content } from '../common/Content/Content'
import { Navbar } from '../common/Navbar/Navbar'
import { Screen } from '../common/Screen/Screen'
import { Seo } from '../common/Seo/Seo'

export const Privacy: React.FC = () => (
	<>
		<Seo
			title="Privacy Policy"
			description="This Privacy Policy governs the manner in which Regex Crossword collects,
			uses, maintains and discloses information collected from users."
		/>
		<h1>Privacy Policy</h1>

		<p>
			This Privacy Policy governs the manner in which Regex Crossword collects,
			uses, maintains and discloses information collected from users (each, a
			&quot;User&quot;) of the{' '}
			<a href="https://regexcrossword.com">https://regexcrossword.com</a>{' '}
			website (&quot;Site&quot;). This privacy policy applies to the Site and
			all products and services offered by Regex Crossword.
		</p>

		<h3>Personal identification information</h3>

		<p>
			We may collect personal identification information from Users in a variety
			of ways in connection with activities, services, features or resources we
			make available on our Site. Users may visit our Site anonymously. We will
			collect personal identification information from Users only if they
			voluntarily submit such information to us. Users can always refuse to
			supply personally identification information, except that it may prevent
			them from engaging in certain Site related activities.
		</p>

		<h3>Non-personal identification information</h3>

		<p>
			We may collect non-personal identification information about Users
			whenever they interact with our Site. Non-personal identification
			information may include the browser name, the type of computer and
			technical information about Users means of connection to our Site, such as
			the operating system and the Internet service providers utilized and other
			similar information.
		</p>

		<h3>Web browser cookies</h3>

		<p>
			Our Site may use &quot;cookies&quot; to enhance User experience.
			User&apos;s web browser places cookies on their hard drive for
			record-keeping purposes and sometimes to track information about them.
			User may choose to set their web browser to refuse cookies, or to alert
			you when cookies are being sent. If they do so, note that some parts of
			the Site may not function properly.
		</p>

		<h3>How we use collected information</h3>

		<p>
			Regex Crossword may collect and use Users personal information for the
			following purposes:
		</p>
		<ul>
			<li>
				<i>- To improve our Site</i>
				<br />
				We may use feedback you provide to improve our products and services.
			</li>
		</ul>

		<h3>How we protect your information</h3>

		<p>
			We adopt appropriate data collection, storage and processing practices and
			security measures to protect against unauthorized access, alteration,
			disclosure or destruction of your personal information, username,
			password, transaction information and data stored on our Site.
		</p>

		<h3>Sharing your personal information</h3>

		<p>
			We do not sell, trade, or rent Users personal identification information
			to others. We may share generic aggregated demographic information not
			linked to any personal identification information regarding visitors and
			users with our business partners, trusted affiliates and advertisers for
			the purposes outlined above.
		</p>

		<h3>Deletion</h3>

		<p>
			You have the right to request the deletion of your personal information
			from our systems. Please contact us on ideas@regexcrossword.com to request
			deletion.
		</p>

		<h3>Third party websites</h3>

		<p>
			Users may find advertising or other content on our Site that link to the
			sites and services of our partners, suppliers, advertisers, sponsors,
			licensors and other third parties. We do not control the content or links
			that appear on these sites and are not responsible for the practices
			employed by websites linked to or from our Site. In addition, these sites
			or services, including their content and links, may be constantly
			changing. These sites and services may have their own privacy policies and
			customer service policies. Browsing and interaction on any other website,
			including websites which have a link to our Site, is subject to that
			website&apos;s own terms and policies.
		</p>

		<h3>Changes to this privacy policy</h3>

		<p>
			Regex Crossword has the discretion to update this privacy policy at any
			time. When we do, we will revise the updated date at the bottom of this
			page. We encourage Users to frequently check this page for any changes to
			stay informed about how we are helping to protect the personal information
			we collect. You acknowledge and agree that it is your responsibility to
			review this privacy policy periodically and become aware of modifications.
		</p>

		<h3>Your acceptance of these terms</h3>

		<p>
			By using this Site, you signify your acceptance of this policy. If you do
			not agree to this policy, please do not use our Site. Your continued use
			of the Site following the posting of changes to this policy will be deemed
			your acceptance of those changes.
		</p>

		<h3>Contacting us</h3>

		<p>
			If you have any questions about this Privacy Policy, the practices of this
			site, or your dealings with this site, please contact us at:
		</p>
		<p>ideas@regexcrossword.com</p>

		<p>This document was last updated on September 22, 2021</p>
	</>
)

export const PrivacyScreen: React.FC = () => (
	<Screen>
		<Navbar />
		<Content>
			<Privacy />
		</Content>
	</Screen>
)
