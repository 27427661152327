import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useChangePasswordMutation } from '../../redux/auth/api'
import { Button } from '../common/Button/Button'
import { Error } from '../common/Error/Error'
import { Header } from '../common/Header/Header'
import { Navbar } from '../common/Navbar/Navbar'
import { Seo } from '../common/Seo/Seo'
import { TextField } from '../common/TextField/TextField'
import styles from './ChangePassword.scss'

export const ChangePassword: React.FC = () => {
	const navigate = useNavigate()

	const [oldPassword, setOldPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')

	const [changePassword, result] = useChangePasswordMutation()

	useEffect(() => {
		if (result.isSuccess) navigate(-1)
	}, [result.isSuccess, navigate])

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		changePassword({ oldPassword, newPassword })
	}

	return (
		<>
			<Seo
				title="Change password"
				description="Change your account password for Regex Crossword."
			/>
			<Navbar />
			<Header>
				<h1>Change password</h1>
			</Header>
			<form
				className={styles.form}
				data-testid="changePasswordForm"
				onSubmit={handleSubmit}
			>
				<TextField
					autoComplete="current-password"
					data-testid="changePasswordOld"
					minLength={5}
					onChange={(e) => setOldPassword(e.target.value)}
					placeholder="Old password"
					required
					type="password"
					value={oldPassword}
				/>
				<TextField
					autoComplete="new-password"
					data-testid="changePasswordNew"
					minLength={5}
					onChange={(e) => setNewPassword(e.target.value)}
					placeholder="New password"
					required
					type="password"
					value={newPassword}
				/>
				<Button
					className={styles.submit}
					data-testid="changePasswordSubmit"
					error={result.error}
					loading={result.isLoading}
					loadingText="Changing..."
					type="submit"
				>
					Change
				</Button>
				<Error error={result.error} />
			</form>
		</>
	)
}
