import React, { useState } from 'react'
import { Modal } from '../common/Modal/Modal'
import { TextButton } from '../common/TextButton/TextButton'

export const RulesModal: React.FC = () => {
	const [show, setShow] = useState(false)

	return (
		<>
			<TextButton onClick={() => setShow(true)}>Rules</TextButton>
			<Modal onClose={() => setShow(false)} show={show}>
				<h1>Rules</h1>
				<p>
					In order to publish your puzzle, the following criteria must be met:
				</p>
				<ol className="list">
					<li>At least one puzzle clue for each row/column.</li>
					<li>You must fill out the (correct) answer for the puzzle.</li>
					<li>Puzzle must be unambiguous (one solution).</li>
					<li>You must name your puzzle.</li>
					<li>Except for space invisible characters are disallowed.</li>
				</ol>
				<p>
					(Optional) If your puzzle solution is scrambled, you can enter a
					correct order of the characters which will show when the user solves
					the puzzle.
				</p>
				<p>Mobile puzzles have special constraints:</p>
				<ol className="list">
					<li>Recommended max size 3x3 to allow for small screen sizes.</li>
					<li>Recommended max different 9 characters in solution.</li>
				</ol>
				<h3>Ambiguous</h3>
				<p>
					If your puzzle has been marked as ambiguous, it means that more than
					one valid solution was submitted by players. You will see a list of
					the different solutions in the summary field. When you fix the
					ambiguity and save, the puzzle will no longer be marked as ambiguous.
					Ambiguous puzzles does not count in the leaderboard.
				</p>
			</Modal>
		</>
	)
}
