import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeleteMeMutation } from '../../redux/api'
import { logoutSocial } from '../../redux/auth/actions'
import slice from '../../redux/auth/slice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { authSelector } from '../../redux/selectors'
import type { Network } from '../../services/social'
import { Button } from '../common/Button/Button'
import { Error } from '../common/Error/Error'
import { Header } from '../common/Header/Header'
import { Navbar } from '../common/Navbar/Navbar'
import { Seo } from '../common/Seo/Seo'
import { TextField } from '../common/TextField/TextField'
import styles from './DeletePlayer.scss'

export const DeletePlayer: React.FC = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const [verification, setVerification] = useState('')

	const creds = useAppSelector(authSelector)

	const [deleteMe, deleteMeResult] = useDeleteMeMutation()

	useEffect(() => {
		const logoutSocialAsync = async (network: Network) => {
			dispatch(logoutSocial(network))
		}

		if (creds && deleteMeResult.isSuccess) {
			if (creds.network) {
				logoutSocialAsync(creds.network)
			} else {
				dispatch(slice.actions.logout())
			}
			navigate('/')
		}
	}, [dispatch, deleteMeResult.isSuccess, creds, navigate])

	if (!creds) {
		return null
	}

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		deleteMe()
	}

	return (
		<>
			<Seo
				title="Delete player"
				description="Delete your player account and all solved puzzles, progress in challenges and achievements earned."
			/>
			<Navbar />
			<Header>
				<h1>Delete player</h1>
			</Header>
			<form
				className={styles.form}
				data-testid="deletePlayerForm"
				onSubmit={handleSubmit}
			>
				<p>
					Are you sure you want to delete all your player and all associated
					data? This will permanently delete:
				</p>
				<ul className="list">
					<li>Your player number</li>
					<li>Your progress</li>
					<li>Your achievements</li>
					<li>Your ratings</li>
				</ul>
				<p>This is permanent and irreversible!</p>
				<p>Type in your player number to confirm that you want to proceed:</p>
				<TextField
					autoComplete="off"
					data-testid="verification"
					minLength={1}
					onChange={(e) => setVerification(e.target.value.trim())}
					placeholder="Player number"
					required
					type="number"
				/>
				<Button
					className={styles.submitButton}
					data-testid="deletePlayerSubmit"
					disabled={verification !== creds.playerNo?.toString()}
					loadingText="Deleting..."
					loading={deleteMeResult.isLoading}
					type="submit"
				>
					Delete player
				</Button>
				<Error error={deleteMeResult.error} />
			</form>
		</>
	)
}
