import React from 'react'
import iconCheckboxChecked from '../../assets/images/icons/checkbox-checked.svg'
import iconCheckbox from '../../assets/images/icons/checkbox.svg'
import iconKeyboard from '../../assets/images/icons/keyboard.svg'
import iconForward from '../../assets/images/icons/nav-forward.svg'
import type { PuzzleList } from '../../redux/api/index'
import { Icon } from '../common/Icon/Icon'
import { ListItem, ListItemText } from '../common/ListItem/ListItem'
import { Pill } from '../common/Pill/Pill'
import styles from './PlayerPuzzles.scss'
import iconHexagon from './hexagon.svg'

type Props = {
	puzzle: PuzzleList[number]
	solved?: boolean
	style?: React.CSSProperties
	url: string
}

export const PuzzleListItem: React.FC<Props> = ({
	puzzle,
	solved,
	style,
	url,
}) => {
	return (
		<ListItem url={url} style={style}>
			{solved !== undefined &&
				(solved ? (
					<Icon className={styles.icon} src={iconCheckboxChecked} />
				) : (
					<Icon className={styles.icon} src={iconCheckbox} />
				))}
			<ListItemText>
				{puzzle.name}
				<span className={styles.fieldPlayer}>
					{' '}
					by Player #{puzzle.playerNo}
				</span>
			</ListItemText>
			{puzzle.ambiguous && (
				<Pill
					className={styles.fieldAmbiguous}
					title="Puzzle does not count towards leaderboard score"
				>
					Ambiguous
				</Pill>
			)}
			<Pill className={styles.fieldDate}>
				{puzzle.dateUpdated &&
					new Date(puzzle.dateUpdated * 1000).toLocaleDateString()}
			</Pill>
			<Pill className={styles.fieldSize}>Size {puzzle.size}</Pill>
			{!puzzle.mobile && <Icon src={iconKeyboard} />}
			{puzzle.hexagonal && <Icon src={iconHexagon} />}
			<Pill>{puzzle.ratingAvg}</Pill>
			<Icon src={iconForward} />
		</ListItem>
	)
}
