import { IAnswer } from '@omichelsen/regex-lib'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import config from '../../config'
import type { components } from '../../typings.api'
import type { RootState } from '../store'

export type Puzzle = components['schemas']['Puzzle'] & {
	solution?: IAnswer
}

/** Fields to filter on /puzzles endpoint queries. */
const puzzleListFields = [
	'ambiguous',
	'dateUpdated',
	'hexagonal',
	'id',
	'mobile',
	'name',
	'playerNo',
	'ratingAvg',
	'size',
	'votes',
] as const

/** Concat version of `puzzleListFields`. */
const puzzleListFieldsString = puzzleListFields.join(',')

/** Partial type derived by using &fields=x filter on /puzzles endpoint queries. */
export type PuzzleList = Pick<Puzzle, (typeof puzzleListFields)[number]>[]

export type Player = components['schemas']['Player']
export type PlayerAchievement = components['schemas']['PlayerAchievement']

export const regexApi = createApi({
	reducerPath: 'regexApi',
	baseQuery: fetchBaseQuery({
		baseUrl: config.api.url,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token
			if (token) {
				headers.set('Authorization', `Bearer ${token}`)
			}
			return headers
		},
	}),
	keepUnusedDataFor: 60 * 60 * 24 * 30, // store all queries for 30 days
	refetchOnMountOrArgChange: 60 * 5, // refresh after 5 min
	tagTypes: ['Puzzle', 'Solved', 'Player', 'Rating'],
	endpoints: (builder) => ({
		addPuzzle: builder.mutation<Puzzle, Puzzle>({
			query: (puzzle) => ({
				url: 'puzzles',
				method: 'POST',
				body: puzzle,
			}),
			invalidatesTags: ['Puzzle'],
		}),
		updatePuzzle: builder.mutation<Puzzle, Puzzle>({
			query: (puzzle) => ({
				url: `puzzles/${puzzle.id}`,
				method: 'PUT',
				body: puzzle,
			}),
			invalidatesTags: ['Puzzle'],
		}),
		getPuzzleRatings: builder.query<
			components['schemas']['RatingResponse'],
			string
		>({
			query: (puzzleId) => `puzzles/${puzzleId}/ratings`,
			providesTags: ['Rating'],
		}),
		ratePuzzle: builder.mutation<void, { puzzleId: string; rating: number }>({
			query: ({ puzzleId, rating }) => ({
				url: `/puzzles/${puzzleId}/ratings`,
				method: 'POST',
				body: { rating },
			}),
			onQueryStarted({ puzzleId, rating }, { dispatch }) {
				dispatch(
					regexApi.util.updateQueryData(
						'getPuzzleRatings',
						puzzleId,
						(draft) => {
							Object.assign(draft, { rating })
						}
					)
				)
			},
			invalidatesTags: ['Rating'],
		}),
		addPlayerAchievement: builder.mutation<
			PlayerAchievement[],
			{ playerNo: number; achievementId: string }
		>({
			query: ({ playerNo, achievementId }) => ({
				url: `/players/${playerNo}/achievements`,
				method: 'POST',
				body: { achievementId },
			}),
		}),
		getPlayerAchievements: builder.query<Record<string, number>, number>({
			query: (playerNo) => `/players/${playerNo}/achievements`,
			transformResponse: (response: PlayerAchievement[]) =>
				response.reduce((map, item) => {
					map[item.achievementId] = item.date
					return map
				}, {} as Record<string, number>),
		}),
		getPuzzle: builder.query<Puzzle, string>({
			query: (puzzleId) => `puzzles/${puzzleId}`,
			providesTags: ['Puzzle'],
		}),
		getPuzzleSolution: builder.query<
			components['schemas']['SolutionResponse'],
			string
		>({
			query: (puzzleId) => `puzzles/${puzzleId}/solution`,
		}),
		getPlayerPuzzles: builder.query<PuzzleList, boolean>({
			query: (showDesktopPuzzles = false) => ({
				url: 'puzzles',
				params: {
					mobile: showDesktopPuzzles ? 0 : 1,
					fields: puzzleListFieldsString,
				},
			}),
			providesTags: ['Puzzle'],
		}),
		getMyPuzzles: builder.query<PuzzleList, number>({
			query: (playerNo) => ({
				url: 'puzzles',
				params: { playerNo, fields: puzzleListFieldsString },
			}),
		}),
		getPuzzleAmbiguous: builder.query<string[], string>({
			query: (puzzleId) => `/puzzles/${puzzleId}/ambiguous`,
		}),
		getChallengePuzzles: builder.query<Puzzle[], string>({
			query: (id) => `challenges/${id}`,
		}),
		getPlayers: builder.query<Player[], void>({
			query: () => 'players',
		}),
		getPlayer: builder.query<Player, number>({
			query: (playerNo) => `players/${playerNo}`,
			providesTags: ['Player'],
		}),
		updatePlayer: builder.mutation<
			Player,
			Pick<Player, 'playerNo'> & Partial<Player>
		>({
			query: ({ playerNo, nickname }) => ({
				url: `players/${playerNo}`,
				method: 'PATCH',
				body: { nickname },
			}),
			onQueryStarted({ playerNo, nickname }, { dispatch }) {
				dispatch(
					regexApi.util.updateQueryData('getPlayer', playerNo, (draft) => {
						Object.assign(draft, { nickname })
					})
				)
			},
			invalidatesTags: ['Player'],
		}),
		getSolved: builder.query<Record<string, true>, void>({
			query: () => 'solved',
			transformResponse: (response: string[]) =>
				response.reduce((map, item) => {
					map[item] = true
					return map
				}, {} as Record<string, true>),
			providesTags: ['Solved'],
		}),
		addSolved: builder.mutation<null, { puzzleId: string; answer: IAnswer }>({
			query: (args) => ({
				url: `solved`,
				method: 'POST',
				body: args,
			}),
			onQueryStarted: ({ puzzleId }, { dispatch }) => {
				dispatch(
					regexApi.util.updateQueryData('getSolved', undefined, (draft) => {
						draft[puzzleId] = true
					})
				)
			},
			invalidatesTags: ['Solved'],
		}),
		getStats: builder.query<components['schemas']['StatsResponse'], void>({
			query: () => 'stats',
		}),
		deleteMe: builder.mutation<
			components['schemas']['DeletePlayerResponse'],
			void
		>({
			query: () => ({
				url: 'players',
				method: 'DELETE',
			}),
		}),
	}),
})

export const {
	useAddPlayerAchievementMutation,
	useAddPuzzleMutation,
	useDeleteMeMutation,
	useGetChallengePuzzlesQuery,
	useGetMyPuzzlesQuery,
	useGetPlayerAchievementsQuery,
	useGetPlayerPuzzlesQuery,
	useGetPlayerQuery,
	useGetPlayersQuery,
	useGetPuzzleAmbiguousQuery,
	useGetPuzzleQuery,
	useGetPuzzleRatingsQuery,
	useGetPuzzleSolutionQuery,
	useGetSolvedQuery,
	useGetStatsQuery,
	useRatePuzzleMutation,
	useUpdatePlayerMutation,
	useUpdatePuzzleMutation,
} = regexApi
