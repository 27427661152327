import {
	Coordinates,
	IAnswer,
	IPuzzle,
	setAnswerValue,
	validateAnswer,
} from '@omichelsen/regex-lib'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { appSelector } from '../../redux/selectors'
import { solved } from '../../redux/solved/slice'
import { Comments } from '../Comments/Comments'
import { HelpModal } from '../HelpModal/HelpModal'
import { KeyboardRequiredNotice } from '../KeyboardRequiredNotice/KeyboardRequiredNotice'
import { PlayerPuzzleAbout } from '../PlayerPuzzles/PlayerPuzzleAbout/PlayerPuzzleAbout'
import { Solved } from '../Solved/Solved'
import { ButtonGroup } from '../common/ButtonGroup/ButtonGroup'
import { Navbar } from '../common/Navbar/Navbar'
import { Screen } from '../common/Screen/Screen'
import { TextButton } from '../common/TextButton/TextButton'
import { FocusPatterns } from './FocusPatterns/FocusPatterns'
import { Grid } from './Grid/Grid'
import { createEmptyAnswer } from './Helpers'
import { Hexagonal } from './Hexagonal/Hexagonal'
import { Keyboard } from './Keyboard/Keyboard'
import styles from './Puzzle.scss'
import { Unscramble } from './Unscramble/Unscramble'

type Props = {
	allowRating?: boolean
	answer?: IAnswer
	puzzle: IPuzzle
	showComments?: boolean
	showEdit?: boolean
	showErrors?: boolean
	showSharing?: boolean
	onAnswer?: (puzzleId: string, answer: IAnswer) => void
	onBackNavigation?: () => void
	onPressField?: (coords: Coordinates) => void
	onSuccessDismiss?: () => void
}

export const Puzzle: React.FC<Props> = (props) => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { useKeyboard } = useAppSelector(appSelector)

	const puzzle = props.puzzle
	const [answer, setAnswer] = useState(
		props.answer ?? createEmptyAnswer(props.puzzle)
	)
	const [startTime] = useState(Date.now())
	const [selectedChar, setSelectedChar] = useState<string>()
	const [focus, setFocus] = useState<Coordinates>([0, 0])

	const [showUnscramble, setShowUnscramble] = useState(false)
	const [showSolved, setShowSolved] = useState(false)
	const [showSolvedDelay] = useState(puzzle.solutionMap ? 0 : 3000) // aligned with .solvedAnimation

	const solutionValidation = useMemo(
		() =>
			validateAnswer(
				{
					patternsX: puzzle.patternsX,
					patternsY: puzzle.patternsY,
					patternsZ: puzzle.patternsZ,
				},
				answer
			),
		[puzzle.patternsX, puzzle.patternsY, puzzle.patternsZ, answer]
	)

	useEffect(() => {
		if (solutionValidation.isValid) {
			dispatch(
				solved({
					puzzleId: puzzle.id,
					answer: answer,
					startTime,
					endTime: Date.now(),
				})
			)
		}
	}, [dispatch, solutionValidation.isValid, puzzle.id, answer, startTime])

	useEffect(() => {
		if (solutionValidation.isValid) {
			if (puzzle.solutionMap) {
				setShowUnscramble(true)
			} else {
				setShowSolved(true)
			}
		}
	}, [solutionValidation.isValid, puzzle.solutionMap])

	const setFieldValue = (coords: Coordinates, value: string) => {
		const newAnswer = setAnswerValue(answer, coords, value)
		props.onAnswer?.(puzzle.id, newAnswer)
		setAnswer(newAnswer)
		setSelectedChar(undefined)
	}

	const handlePressField = (coords: Coordinates) => {
		if (selectedChar) {
			setFieldValue(coords, selectedChar)
		}
		setFocus(coords)
		props.onPressField?.(coords)
	}

	const handleCharClick = useCallback((e: React.MouseEvent) => {
		setSelectedChar((e.target as HTMLElement).dataset.value)
	}, [])

	const PuzzleTemplate = puzzle.hexagonal ? Hexagonal : Grid

	return (
		<Screen className={styles.container}>
			<Navbar onBackNavigation={props.onBackNavigation}>
				<ButtonGroup>
					{props.showEdit && (
						<TextButton
							data-testid="edit-button"
							onClick={() => navigate(`/builder/${puzzle.id}`)}
						>
							Edit
						</TextButton>
					)}
					{props.showSharing && <PlayerPuzzleAbout puzzle={puzzle as any} />}
					{props.showComments && (
						<Comments puzzleId={puzzle.id} puzzleName={puzzle.name} />
					)}
					<HelpModal />
				</ButtonGroup>
			</Navbar>
			<FocusPatterns
				coords={focus}
				hexagonal={!!puzzle.hexagonal}
				patterns={puzzle}
			/>
			<PuzzleTemplate
				allowInput={useKeyboard}
				answer={answer}
				focus={focus}
				puzzle={puzzle}
				solutionValidation={props.showErrors ? solutionValidation : undefined}
				solved={solutionValidation.isValid}
				onFocusField={setFocus}
				onPressField={handlePressField}
				setFieldValue={setFieldValue}
			/>
			<Keyboard
				characters={puzzle.characters}
				hexagonal={puzzle.hexagonal}
				selectedChar={selectedChar}
				onCharClick={handleCharClick}
			/>
			{!puzzle.mobile && <KeyboardRequiredNotice />}
			{showUnscramble && puzzle.solutionMap && (
				<Unscramble
					map={puzzle.solutionMap}
					unmountAfter={20000}
					onClick={() => {
						setShowUnscramble(false)
						setShowSolved(true)
					}}
				/>
			)}
			<Solved
				dismiss={props.onSuccessDismiss!}
				enableRating={props.allowRating}
				puzzleId={puzzle.id}
				delay={showSolvedDelay}
				show={showSolved}
			/>
		</Screen>
	)
}
