import React from 'react'
import { Grid } from '../Puzzle/Grid/Grid'
import { Navbar } from '../common/Navbar/Navbar'
import { Screen } from '../common/Screen/Screen'
import styles from './NotFound.scss'

const puzzle = Object.freeze({
	id: '404',
	name: 'Not found',
	patternsX: [
		['Page', 'Page'],
		['not', 'not'],
		['found', 'found'],
	],
	patternsY: [
		['Page', 'Page'],
		['not', 'not'],
		['found', 'found'],
	],
})

const answer = [
	['4', '0', '4'],
	['0', '0', '0'],
	['4', '0', '4'],
]

export const NotFound: React.FC = () => {
	return (
		<Screen className={styles.notFound} title="Not found">
			<Navbar />
			<div className={styles.content}>
				<Grid puzzle={puzzle} answer={answer} />
			</div>
		</Screen>
	)
}
