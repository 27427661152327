import {
	isRejectedWithValue,
	Middleware,
	MiddlewareAPI,
} from '@reduxjs/toolkit'
import { authApi } from '../auth/api'
import auth from '../auth/slice'

export const authMiddleware: Middleware =
	({ dispatch, getState }: MiddlewareAPI) =>
	(next) =>
	(action) => {
		// Intercept 401 Unauthorized (expired token)
		if (
			isRejectedWithValue(action) &&
			action.error?.message?.includes('401') &&
			!authApi.endpoints.changePassword.matchRejected(action)
		) {
			const state = getState()
			if (state.auth.isAuthenticated) {
				dispatch(auth.actions.logout())
			}
		}

		next(action)
	}
