import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'
import { tutorialSelector } from '../../redux/selectors'
import { Puzzle } from '../Puzzle/Puzzle'
import { FloatingBox } from '../common/FloatingBox/FloatingBox'
import { Pointer } from '../common/Pointer/Pointer'
import { Seo } from '../common/Seo/Seo'
import styles from './Tutorial.scss'

export const Tutorial2: React.FC = (props) => {
	const navigate = useNavigate()

	const tutorial = useAppSelector(tutorialSelector)

	const [step, setStep] = React.useState(0)

	const next = () => {
		setStep(Number(!step))
	}

	return (
		<div>
			<Seo title="Tutorial: 2" />
			<FloatingBox selector="[data-testid=clues]" show={step === 0}>
				<p>
					The <code>?</code> means <b>one or zero</b> and <code>*</code> is{' '}
					<b>zero or infinite</b>. Is <b>A or B</b> required in the first field?
				</p>
			</FloatingBox>
			{step === 0 && (
				<>
					<Pointer
						className={styles.pointer1}
						delay={300}
						selector="[data-testid=keyboard] :first-child"
					/>
					<Pointer
						className={styles.pointer1}
						delay={300}
						selector="[data-testid=keyboard] :nth-child(2)"
					/>
				</>
			)}
			<FloatingBox selector="[data-testid=keyboard]" show={step === 1}>
				<p>
					The second field is tricky. Hint: one of the letters <i>has</i> to
					appear one time.
				</p>
			</FloatingBox>
			<Puzzle
				{...props}
				onBackNavigation={() => navigate('/')}
				onPressField={next}
				onSuccessDismiss={() => navigate('/tutorial/3')}
				puzzle={tutorial[1]}
				showErrors
			/>
		</div>
	)
}
