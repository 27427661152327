import {
	createEmptyGridAnswer,
	createEmptyHexAnswer,
	IPatterns,
	IPuzzle,
	IPuzzlePatterns,
} from '@omichelsen/regex-lib'

export const createEmptyAnswer = ({
	patternsX,
	patternsY,
	hexagonal,
}: IPuzzle) => {
	if (hexagonal) {
		return createEmptyHexAnswer(patternsX.length, patternsY.length)
	} else {
		return createEmptyGridAnswer(patternsX.length, patternsY.length)
	}
}

export const getMaxPatternLength = (patterns: IPatterns = []) =>
	(patterns || []).reduce(
		(prev, [a = '', b = '']) => Math.max(prev, a.length, b.length),
		0
	)

export const getMaxClueLength = (puzzle: IPuzzlePatterns) =>
	Math.max(
		getMaxPatternLength(puzzle.patternsX),
		getMaxPatternLength(puzzle.patternsY),
		getMaxPatternLength(puzzle.patternsZ ?? [])
	)

/** Is `i` the last position of `a`? */
export const isLast = (i: number, a: Array<any>) => i === a.length - 1

/** Check if value is not null (or undefined). */
export const notNull = (v: any) => v != null

/** Counts number of falsy elements in a row. */
export const getEmptyOffset = (a: any[]) => a.length - a.filter(notNull).length

/** Scroll element to center. */
export const scrollToCenter = (div?: HTMLElement | null) => {
	if (!div) return
	const xMax = div.scrollWidth - div.clientWidth
	const xHalf = Math.floor(xMax / 2)
	const yMax = div.scrollHeight - div.clientHeight
	const yHalf = Math.floor(yMax / 2)
	div.scrollTo(xHalf, yHalf)
}

export const getPosition = (element: HTMLElement) => {
	if (!element) return { x: 0, y: 0 }

	const rect = element.getBoundingClientRect()
	const scrollLeft = window.scrollX || document.documentElement.scrollLeft
	const scrollTop = window.scrollY || document.documentElement.scrollTop

	return { x: rect.left + scrollLeft, y: rect.top + scrollTop }
}

export const animateFlyTo = (source: HTMLElement, target: HTMLElement) => {
	const sourcePos = getPosition(source)
	const targetPos = getPosition(target)

	const clone = source.cloneNode() as HTMLElement
	clone.dataset.type = 'clone'
	clone.style.position = 'absolute'
	clone.style.transition = 'top 1s, left 1s'
	clone.style.top = sourcePos.y + window.pageYOffset + 'px'
	clone.style.left = sourcePos.x + window.pageXOffset + 'px'
	// fix for hex
	clone.style.margin = '0'
	// only include input field not label
	clone.appendChild(source.querySelector('input')?.cloneNode() as HTMLElement)

	document.body.append(clone)

	setTimeout(() => {
		clone.style.top = targetPos.y + window.pageYOffset + 'px'
		clone.style.left = targetPos.x + window.pageXOffset + 'px'
	}, 300)
}

/** Animates all fields into their new position based on array map */
export const unscramble = (map: number[]) => {
	const fields = document.querySelectorAll<HTMLElement>('[data-id^=field]')
	fields.forEach((field, i) => animateFlyTo(field, fields[map[i]]))
}

export const cleanupUnscramble = () =>
	document
		.querySelectorAll('[data-type=clone]')
		.forEach((clone) => document.body.removeChild(clone))
