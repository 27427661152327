import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { CircularProgressbarWrapperProps } from 'react-circular-progressbar/dist/types'
import './Progress.scss'

type Props = CircularProgressbarWrapperProps

export const Progress: React.FC<Props> = ({
	maxValue,
	value,
	text,
	...props
}) => {
	return (
		<CircularProgressbar
			background
			backgroundPadding={6}
			maxValue={maxValue}
			value={value}
			text={text ?? `${value}/${maxValue}`}
			styles={{
				background: {
					fill: 'hsl(var(--primary-light))',
				},
				path: {
					stroke: 'hsl(var(--primary-dark))',
				},
				text: {
					fill: 'hsl(var(--on-primary-light))',
					fontSize: '18px',
					fontWeight: 'bold',
				},
				trail: {
					stroke: 'transparent',
				},
			}}
			{...props}
		/>
	)
}
