import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { Coordinates } from '@omichelsen/regex-lib'
import classNames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../redux/hooks'
import { appSelector } from '../../../redux/selectors'
import { TextField } from '../../common/TextField/TextField'
import { Field } from '../Field/Field'
import type { Props } from '../Grid/Grid'
import {
	getEmptyOffset,
	getMaxClueLength,
	isLast,
	notNull,
	scrollToCenter,
} from '../Helpers'
import styles from '../Puzzle.scss'
import stylesHex from './Hexagonal.scss'

export const Hexagonal: React.FC<Props> = ({
	allowEdit = false,
	allowInput = false,
	answer,
	focus: propsFocus = [0, 0],
	puzzle,
	patternsValidation,
	solutionValidation,
	solved = false,
	onFocusField,
	onPressField,
	setFieldValue,
	setPatternValue,
}) => {
	const [focus, setFocus] = useState<Coordinates>(propsFocus)
	const { disableHaptics } = useAppSelector(appSelector)

	/** Accounts for x being shifted past the median. */
	const getHexCoords = useCallback(
		(x: number, y: number): Coordinates => [x + getEmptyOffset(answer[y]), y],
		[answer]
	)

	const hexRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		requestAnimationFrame(() => scrollToCenter(hexRef.current))
	}, [])

	const handlePressField = (coords: Coordinates, e: React.MouseEvent) => {
		setFocus(coords)
		const hexCoords = getHexCoords(...coords)
		onFocusField?.(hexCoords)
		onPressField?.(hexCoords)
		// allows click to focus since pointer-events are disabled
		if (allowInput) {
			;(e.target as HTMLElement).querySelector('input')?.focus()
		}
	}

	const handleDoubleClickField = (coords: Coordinates) => {
		// assumes that single click always fires as well (only works on desktop)
		setFieldValue?.(coords, '')
	}

	const handleDrop = (coords: Coordinates, e: React.DragEvent) => {
		e.preventDefault()
		;(e.target as HTMLElement).classList.remove(stylesHex.over)
		const char = e.dataTransfer!.getData('text/plain')
		setFocus(coords)
		const hexCoords = getHexCoords(...coords)
		onFocusField?.(hexCoords)
		setFieldValue?.(hexCoords, char)
		if (!disableHaptics)
			Haptics.impact({ style: ImpactStyle.Light }).catch(() => {
				// ignore fail on devices without haptics
			})
	}

	const clueLength = getMaxClueLength(puzzle)
	const median = Math.floor(puzzle.patternsY.length / 2)

	return (
		<div className={stylesHex.container} data-testid="puzzle" ref={hexRef}>
			<div
				className={classNames(
					stylesHex.hexagonalPuzzle,
					styles[`clueWidth${clueLength}`]
				)}
			>
				{puzzle.patternsY.map(([patternA], y) => (
					<div className={stylesHex.row} key={`clueYA${y}`}>
						{answer[y].filter(notNull).map((char, x) => (
							<Field
								className={classNames(stylesHex.field, {
									[stylesHex[`solvedAnimation${x + y}`]]: solved,
								})}
								data-id={`field${x}-${y}`}
								hexagonal
								highlight={x === focus[0] && y === focus[1]}
								error={
									solutionValidation?.answer[y]?.[
										x + getEmptyOffset(answer[y])
									] === false
								}
								data-value={char}
								key={`field${x}-${y}`}
								onClick={handlePressField.bind(this, [x, y])}
								onDoubleClick={handleDoubleClickField.bind(
									this,
									getHexCoords(x, y)
								)}
								onDrop={handleDrop.bind(this, [x, y])}
								readOnly={!allowInput}
								value={char!}
								onChange={(e) =>
									setFieldValue?.(getHexCoords(x, y), e.target.value)
								}
								onFocus={() => {
									setFocus([x, y])
									onFocusField?.(getHexCoords(x, y))
								}}
							>
								{/* X (first row or last column in row < median) */}
								{(y === 0 || (x === answer[y].length - 1 && y <= median)) && (
									<div
										className={classNames(stylesHex.clue, stylesHex.clueTop, {
											[stylesHex.highlight]:
												x ===
												focus[0] + (focus[1] <= median ? 0 : focus[1] - median),
											[stylesHex.error]:
												patternsValidation?.patternsX[x][0] === false,
										})}
									>
										<TextField
											className={classNames(styles.input, styles.right)}
											tabIndex={-1}
											maxLength={50}
											value={puzzle.patternsX[x][0]}
											readOnly={!allowEdit}
											onChange={(e) =>
												setPatternValue?.('X', x, 0, e.target.value)
											}
										/>
									</div>
								)}
								{/* Y (first column)  */}
								{x === 0 && (
									<div
										className={classNames(stylesHex.clue, stylesHex.clueLeft, {
											[stylesHex.highlight]: y === focus[1],
											[stylesHex.error]:
												patternsValidation?.patternsY[y][0] === false,
										})}
									>
										<TextField
											className={classNames(styles.input, styles.right)}
											tabIndex={-1}
											maxLength={50}
											title={patternA}
											value={patternA}
											readOnly={!allowEdit}
											onChange={(e) =>
												setPatternValue?.('Y', y, 0, e.target.value)
											}
										/>
									</div>
								)}
								{/* Z (last row or last column in row > median) */}
								{(isLast(y, puzzle.patternsY) ||
									(isLast(x, answer[y].filter(notNull)) && y >= median)) && (
									<div
										className={classNames(
											stylesHex.clue,
											stylesHex.clueBottom,
											{
												[stylesHex.highlight]:
													x ===
													focus[0] +
														(focus[1] >= median ? 0 : median - focus[1]),
												[stylesHex.error]:
													patternsValidation?.patternsZ?.[x][0] === false,
											}
										)}
									>
										<TextField
											className={classNames(styles.input, styles.right)}
											tabIndex={-1}
											maxLength={50}
											value={puzzle.patternsZ?.[x][0]}
											readOnly={!allowEdit}
											onChange={(e) =>
												setPatternValue?.('Z', x, 0, e.target.value)
											}
										/>
									</div>
								)}
							</Field>
						))}
					</div>
				))}
			</div>
		</div>
	)
}
