import React, { useCallback } from 'react'
import iconQuestion from '../../assets/images/icons/question.svg'
import app from '../../redux/app/slice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { appSelector } from '../../redux/selectors'
import { Help } from '../Help/Help'
import { IconButton } from '../common/IconButton/IconButton'
import { Modal } from '../common/Modal/Modal'

export const HelpModal: React.FC = () => {
	const dispatch = useAppDispatch()
	const toggleHelp = useCallback(
		() => dispatch(app.actions.toggleHelp()),
		[dispatch]
	)
	const { showHelp } = useAppSelector(appSelector)

	return (
		<>
			<IconButton alt="Help" onClick={toggleHelp} src={iconQuestion} />
			<Modal onClose={toggleHelp} show={showHelp}>
				<Help />
			</Modal>
		</>
	)
}
