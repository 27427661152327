import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AchievementNotification = {
	id: string
	type: 'achievement'
	data: {
		id: string
		date: number
	}
}

export type Notification = AchievementNotification

export default createSlice({
	name: 'notifications',
	initialState: [] as Notification[],
	reducers: {
		dismissNotification: (state, action: PayloadAction<Notification>) => {
			const index = state.findIndex(({ id }) => id === action.payload.id)
			if (index !== -1) state.splice(index, 1)
		},
		showNotification: (state, action: PayloadAction<Notification>) => {
			state.push(action.payload)
		},
		showAchievementNotification: (state, action: PayloadAction<string>) => {
			state.push({
				id: action.payload,
				type: 'achievement',
				data: { id: action.payload, date: Date.now() },
			})
		},
	},
})
