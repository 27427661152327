import { isAnyOf } from '@reduxjs/toolkit'
import { regexApi } from '../api'
import notifications from '../notifications/slice'
import { rehydrate } from '../rehydrator'
import {
	authSelector,
	challengePuzzlesSolvedSelector,
	challengesMapSelector,
	isPuzzlesCompletedSelector,
	isTutorialCompletedSelector,
} from '../selectors'
import { solved } from '../solved/slice'
import { startAppListening } from './listenerMiddleware'

const { addPlayerAchievement, addPuzzle, getMyPuzzles, getPlayerAchievements } =
	regexApi.endpoints
const { showAchievementNotification } = notifications.actions

startAppListening({
	matcher: isAnyOf(rehydrate, solved, addPuzzle.matchFulfilled),
	effect: async (action, { dispatch, getState }) => {
		const state = getState()
		const { playerNo } = authSelector(state)
		if (!playerNo) {
			return
		}

		const achievements = await dispatch(
			getPlayerAchievements.initiate(playerNo)
		)

		const earnAchievementIfMissing = (achievementId: string) => {
			if (achievements.data && !(achievementId in achievements.data)) {
				dispatch(showAchievementNotification(achievementId))
				dispatch(addPlayerAchievement.initiate({ playerNo, achievementId }))
			}
		}

		if (solved.match(action)) {
			// Has the player completed tutorial and earned the mobile achievement?
			if (isTutorialCompletedSelector(state)) {
				earnAchievementIfMissing('mobile')
			}

			// Has the player completed all puzzles and earned the mythology achievement?
			if (isPuzzlesCompletedSelector(state)) {
				earnAchievementIfMissing('mythology')
			}

			// Has any of the challenges been completed?
			const challenges = challengesMapSelector(state)
			const challengesSolved = challengePuzzlesSolvedSelector(state)
			for (const [key, value] of Object.entries(challengesSolved)) {
				if (value.solved === value.total) {
					const achievementId = challenges.get(key as any)?.achievementId
					if (achievementId) earnAchievementIfMissing(achievementId)
				}
			}
		} else if (addPuzzle.matchFulfilled(action)) {
			// When the player creates their own puzzle
			earnAchievementIfMissing('builder')
		} else if (rehydrate.match(action)) {
			// When the player has created a highly rated puzzle
			const puzzles = await dispatch(getMyPuzzles.initiate(playerNo))
			const qualify = puzzles.data?.some(
				(p) => (p.ratingAvg ?? 0) >= 3 && (p.votes ?? 0) >= 30
			)
			if (qualify) {
				earnAchievementIfMissing('masterpuzzler')
			}
		}
	},
})
