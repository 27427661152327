import { combineReducers } from 'redux'
import answers from './answers/slice'
import { regexApi } from './api'
import app from './app/slice'
import auth from './auth/slice'
import notifications from './notifications/slice'
import solved from './solved/slice'

import achievements from '../../data/achievements.json'
import challenges from '../../data/challenges.json'
import puzzles from '../../data/puzzles.json'
import tutorial from '../../data/tutorial.json'

export const rootReducer = combineReducers({
	// json
	achievements: () => achievements,
	challenges: () => challenges,
	puzzles: () => puzzles,
	tutorial: () => tutorial,
	// reducers
	answers: answers.reducer,
	app: app.reducer,
	auth: auth.reducer,
	notifications: notifications.reducer,
	solved: solved.reducer,
	// api
	[regexApi.reducerPath]: regexApi.reducer,
})
