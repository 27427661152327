import classNames from 'classnames'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styles from './LinkButton.scss'

type Props = LinkProps & {
	secondary?: boolean
	transparent?: boolean
	[key: string]: any
}

export const LinkButton: React.FC<Props> = ({
	children,
	className,
	secondary,
	transparent,
	...rest
}) => {
	return (
		<Link
			className={classNames(
				styles.linkButton,
				{
					[styles.secondary]: secondary,
					[styles.transparent]: transparent,
				},
				className
			)}
			draggable={false}
			{...rest}
		>
			{children}
		</Link>
	)
}
