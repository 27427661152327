import React from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import notifications, {
	AchievementNotification as AchievementNotificationType,
} from '../../redux/notifications/slice'
import { achievementsSelector } from '../../redux/selectors'
import { getImgPath } from '../../services/achievementImages'
import { SuccessModal } from '../common/SuccessModal/SuccessModal'
import styles from './AchievementNotification.scss'

type Props = AchievementNotificationType

export const AchievementNotification: React.FC<Props> = (props) => {
	const dispatch = useAppDispatch()

	const achievements = useAppSelector(achievementsSelector)

	const achievement = achievements.find(({ id }) => id === props.data.id)!

	return (
		<SuccessModal
			buttonText="Close"
			dismiss={() => dispatch(notifications.actions.dismissNotification(props))}
			showBackgroundAnimation
		>
			<img
				className={styles.image}
				src={getImgPath(achievement.id)}
				alt={achievement.name}
				draggable={false}
			/>
			<div className={styles.text}>{achievement.name}</div>
		</SuccessModal>
	)
}
