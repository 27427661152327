import classNames from 'classnames'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import iconCheckboxChecked from '../../assets/images/icons/checkbox-checked.svg'
import iconCheckbox from '../../assets/images/icons/checkbox.svg'
import { useAppSelector } from '../../redux/hooks'
import { puzzlesSelector, solvedSelector } from '../../redux/selectors'
import { AnimSquares } from '../common/AnimSquares/AnimSquares'
import { Content } from '../common/Content/Content'
import { Header } from '../common/Header/Header'
import { Icon } from '../common/Icon/Icon'
import { Navbar } from '../common/Navbar/Navbar'
import { Screen } from '../common/Screen/Screen'
import styles from './Puzzles.scss'

export const Puzzles: React.FC = () => {
	const navigate = useNavigate()

	const puzzles = useAppSelector(puzzlesSelector)
	const solved = useAppSelector(solvedSelector)

	return (
		<Screen className={styles.puzzles} title="Puzzles">
			<AnimSquares />
			<Navbar onBackNavigation={() => navigate('/')} />
			<Header>
				<h1>Puzzles</h1>
			</Header>
			<Content>
				<div className={styles.grid}>
					{puzzles.map((p, i) => (
						<Link
							className={styles.button}
							key={p.id}
							to={`/puzzles/${p.id}`}
							draggable={false}
						>
							<Icon
								className={classNames(styles.icon, {
									[styles.checked]: p.id in solved,
								})}
								src={p.id in solved ? iconCheckboxChecked : iconCheckbox}
							/>
							<span className={styles.label}>{i + 1}</span>
						</Link>
					))}
				</div>
				{!!Object.keys(solved).length && (
					<p style={{ textAlign: 'center' }}>
						Want more of a challenge? We have many more{' '}
						<Link to="/challenges">difficult puzzles</Link>.
					</p>
				)}
			</Content>
		</Screen>
	)
}
