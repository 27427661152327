import React from 'react'
import { Button } from '../Button/Button'
import styles from './SuccessModal.scss'

type Props = {
	buttonText: string
	children?: React.ReactNode
	dismiss: () => void
	showBackgroundAnimation?: boolean
}

export const SuccessModal: React.FC<Props> = (props) => (
	<div className={styles.container} onClick={props.dismiss}>
		{props.showBackgroundAnimation && (
			<div className={styles.background}>
				<div className={styles.rays} />
			</div>
		)}
		<div className={styles.content}>{props.children}</div>
		<Button className={styles.button}>{props.buttonText}</Button>
	</div>
)
