import { startAppListening } from '../middleware/listenerMiddleware'
import { solved } from '../solved/slice'
import slice from './slice'

// remove answer once solved
startAppListening({
	actionCreator: solved,
	effect: (action, listenerApi) => {
		listenerApi.dispatch(slice.actions.remove(action.payload.puzzleId))
	},
})
