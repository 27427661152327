import { useState, useEffect } from 'react'

export const useAudio = (url: string) => {
	const [audio] = useState(new Audio(url))
	const [playing, setPlaying] = useState(false)

	const toggle = () => setPlaying(!playing)

	useEffect(() => {
		playing ? audio.play() : audio.pause()
	}, [audio, playing])

	useEffect(() => {
		const stopPlaying = () => setPlaying(false)
		audio.addEventListener('ended', stopPlaying)
		return () => audio.removeEventListener('ended', stopPlaying)
	}, [audio])

	return [playing, toggle] as const
}
