import classNames from 'classnames'
import React from 'react'
import iconClose from '../../../assets/images/icons/close.svg'
import { useKeyPress } from '../../../hooks/useKeyPress'
import { IconButton } from '../IconButton/IconButton'
import styles from './Dialog.scss'

type Props = {
	children: React.ReactNode
	className?: string
	onClose: () => void
	[key: string]: any
}

export const Dialog: React.FC<Props> = ({
	children,
	className,
	onClose,
	...rest
}) => {
	useKeyPress(['Escape'], (e: KeyboardEvent) => {
		e.stopPropagation()
		onClose()
	})

	return (
		<div className={classNames(styles.dialog, className)} {...rest}>
			<div className={styles.inner}>
				<IconButton
					alt="Close"
					className={styles.closeButton}
					data-testid="dialogCloseButton"
					onClick={onClose}
					src={iconClose}
				/>
				{children}
			</div>
		</div>
	)
}
