/* eslint-disable react/no-danger */
import React from 'react'

type Props = React.HTMLAttributes<HTMLElement> & {
	src: string
}

export const Svg: React.FC<Props> = (props) => {
	const { src: __html, ...rest } = props
	return <i dangerouslySetInnerHTML={{ __html }} {...rest} />
}
