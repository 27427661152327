import React, { useImperativeHandle, useLayoutEffect, useRef } from 'react'
import Swipe from 'swipe-js-iso'

export type SwipeHandle = ReturnType<typeof Swipe>
export type ReactSwipeHandle = Pick<SwipeHandle, 'next' | 'prev' | 'slide'>

type Props = {
	children: React.ReactNode
	className?: string
	onChange?: (index: number, element: Element) => void
	style?: {
		container?: React.CSSProperties
		wrapper?: React.CSSProperties
		child?: React.CSSProperties
	}
}

export const ReactSwipe = React.forwardRef<ReactSwipeHandle, Props>(
	function ReactSwipe(props, ref) {
		const container = useRef<HTMLDivElement>(null)
		const swipe = useRef<SwipeHandle>()

		useLayoutEffect(() => {
			swipe.current = Swipe(container.current!, { callback: props.onChange })
			return () => swipe.current?.kill()
		}, [container, swipe, props.onChange])

		useImperativeHandle(ref, () => ({
			next: () => swipe.current?.next(),
			prev: () => swipe.current?.prev(),
			slide: (index: number, duration?: number) =>
				swipe.current?.slide(index, duration),
		}))

		return (
			<div
				className={props.className}
				ref={container}
				style={{
					overflow: 'hidden',
					visibility: 'hidden',
					position: 'relative',
					...props.style?.container,
				}}
			>
				<div
					style={{
						overflow: 'hidden',
						position: 'relative',
						...props.style?.wrapper,
					}}
				>
					{React.Children.map(props.children, (child) => {
						if (!React.isValidElement(child)) {
							return null
						}

						const childNode: React.ReactNode = child

						return React.cloneElement(childNode, {
							style: {
								float: 'left',
								width: '100%',
								position: 'relative',
								transitionProperty: 'transform',
								...props.style?.child,
								...child.props.style,
							},
						})
					})}
				</div>
			</div>
		)
	}
)
