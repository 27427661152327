import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	useAddCredentialsMutation,
	useRegisterMutation,
} from '../../redux/auth/api'
import { useAppSelector } from '../../redux/hooks'
import { authSelector } from '../../redux/selectors'
import { Button } from '../common/Button/Button'
import { Error } from '../common/Error/Error'
import { Header } from '../common/Header/Header'
import { Navbar } from '../common/Navbar/Navbar'
import { Screen } from '../common/Screen/Screen'
import { TextField } from '../common/TextField/TextField'
import styles from './Register.scss'

export const Register: React.FC = () => {
	const navigate = useNavigate()
	const auth = useAppSelector(authSelector)

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	const [register, registerResult] = useRegisterMutation()
	const [addCredentials, addCredentialsResult] = useAddCredentialsMutation()

	useEffect(() => {
		if (registerResult.isSuccess || addCredentialsResult.isSuccess) {
			navigate('/')
		}
	}, [registerResult.isSuccess, addCredentialsResult.isSuccess, navigate])

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()

		if (auth.isAuthenticated && !auth.hasUsername) {
			addCredentials({ username, password })
		} else {
			register({ username, password })
		}
	}

	return (
		<Screen
			title="Register new player"
			description="Register as a new player to save your progress and earn achievements for solving puzzles. Allows you to create your own puzzles and contribute to your community."
		>
			<Navbar />
			<Header>
				<h1>
					{!auth.isAuthenticated
						? 'Register new player'
						: 'Set username & password'}
				</h1>
			</Header>
			<form
				className={styles.form}
				data-testid="registerForm"
				onSubmit={handleSubmit}
			>
				<TextField
					autoCapitalize="off"
					autoComplete="username"
					data-testid="registerEmail"
					onChange={(e) => setUsername(e.target.value)}
					placeholder="Email"
					pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.[a-zA-Z]{2,6}$"
					required
					value={username}
				/>
				<TextField
					autoComplete="new-password"
					data-testid="registerPassword"
					minLength={5}
					onChange={(e) => setPassword(e.target.value)}
					placeholder="Password"
					required
					type="password"
					value={password}
				/>
				<Button
					data-testid="registerSubmit"
					loadingText="Registering..."
					loading={registerResult.isLoading || addCredentialsResult.isLoading}
					type="submit"
				>
					Register
				</Button>
				<Error error={registerResult.error || addCredentialsResult.error} />
			</form>
		</Screen>
	)
}
