import React from 'react'
import { FixedSizeList as List } from 'react-window'
import useResizeObserver from 'use-resize-observer'
import { useGetPlayersQuery } from '../../redux/api'
import { useAppSelector } from '../../redux/hooks'
import { authSelector } from '../../redux/selectors'
import { PlayerName } from '../PlayerName/PlayerName'
import { Header } from '../common/Header/Header'
import { ListItem, ListItemText } from '../common/ListItem/ListItem'
import { Navbar } from '../common/Navbar/Navbar'
import { Pill } from '../common/Pill/Pill'
import { RtkLoader } from '../common/RtkLoader/RtkLoader'
import { Screen } from '../common/Screen/Screen'
import styles from './Leaderboard.scss'

export const Leaderboard: React.FC = () => {
	const { playerNo } = useAppSelector(authSelector)
	const players = useGetPlayersQuery()

	const { ref, height: heightContent = 1 } = useResizeObserver<HTMLDivElement>()

	return (
		<Screen
			className={styles.leaderboard}
			title="Leaderboard"
			description="Ranking of players based on points achieved by solving puzzles and earning achievements."
		>
			<Navbar />
			<Header>
				<h1>Leaderboard</h1>
			</Header>
			<RtkLoader {...players} myRef={ref}>
				{(p) => (
					<List
						height={heightContent}
						itemCount={p.length}
						itemSize={50}
						width="100%"
					>
						{({ index, style }) => (
							<ListItem
								url={`/players/${p[index].playerNo}`}
								style={style}
								highlight={p[index].playerNo === playerNo}
							>
								<Pill>{p[index].ranking}</Pill>
								<ListItemText>
									<PlayerName {...p[index]} />
								</ListItemText>
								<span className={styles.score}>{p[index].score}</span>
							</ListItem>
						)}
					</List>
				)}
			</RtkLoader>
		</Screen>
	)
}
