import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { loginSocial } from '../../redux/auth/actions'
import { useLoginMutation } from '../../redux/auth/api'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { appSelector } from '../../redux/selectors'
import { Button } from '../common/Button/Button'
import { Error } from '../common/Error/Error'
import { Header } from '../common/Header/Header'
import { Navbar } from '../common/Navbar/Navbar'
import { Screen } from '../common/Screen/Screen'
import { TextButton } from '../common/TextButton/TextButton'
import { TextField } from '../common/TextField/TextField'
import styles from './Login.scss'

export const Login: React.FC<{ redirectTo?: string }> = ({
	redirectTo = '/',
}) => {
	const navigate = useNavigate()

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	const { deviceInfo } = useAppSelector(appSelector)

	const dispatch = useAppDispatch()

	const [login, loginResult] = useLoginMutation()

	useEffect(() => {
		if (loginResult.isSuccess) navigate(redirectTo)
	}, [redirectTo, loginResult.isSuccess, navigate])

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		login({ username, password })
	}

	return (
		<Screen
			title="Login"
			description="Login to your Regex Crossword account to track your progress and earn achievements. Use credentials or your preferred social account."
		>
			<Navbar>
				<TextButton onClick={() => navigate('/register')}>Register</TextButton>
			</Navbar>
			<Header>
				<h1>Login</h1>
			</Header>
			<form
				className={styles.form}
				data-testid="loginForm"
				onSubmit={handleSubmit}
			>
				<TextField
					autoCapitalize="off"
					autoComplete="username"
					data-testid="loginEmail"
					onChange={(e) => setUsername(e.target.value)}
					placeholder="Email"
					pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.[a-zA-Z]{2,6}$"
					required
					value={username}
				/>
				<TextField
					autoComplete="current-password"
					data-testid="loginPassword"
					minLength={5}
					onChange={(e) => setPassword(e.target.value)}
					placeholder="Password"
					required
					type="password"
					value={password}
				/>
				<Button
					data-testid="loginSubmit"
					loadingText="Logging in..."
					loading={loginResult.isLoading}
					type="submit"
				>
					Login
				</Button>
				<Error error={loginResult.error} />
				<p>
					<Link to="/reset_password" draggable={false}>
						Forgot password?
					</Link>
				</p>
				{!['ios', 'android'].includes(deviceInfo?.platform ?? '') && (
					<div className={styles.socialButtons}>
						{socialNetworks.map(([id, name]) => (
							<Button
								key={id}
								onClick={async () => {
									await dispatch(loginSocial(id))
									navigate(redirectTo)
								}}
								secondary
							>
								{name}
							</Button>
						))}
					</div>
				)}
				<p>
					<Link to="/privacy" draggable={false}>
						Privacy Policy
					</Link>
				</p>
			</form>
		</Screen>
	)
}

const socialNetworks = [
	['facebook', 'Facebook'],
	['google', 'Google'],
	['twitter', 'Twitter'],
	['github', 'GitHub'],
	['windows', 'Windows Live'],
] as const
