import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { AboutScreen } from './About/About'
import { AchievementNotification } from './AchievementNotification/AchievementNotification'
import { BuilderContainer } from './Builder/BuilderContainer'
import { BuilderLanding } from './Builder/BuilderLanding/BuilderLanding'
import { BuilderNewContainer } from './Builder/BuilderNewContainer'
import { BuilderScreen } from './Builder/BuilderScreen'
import { ChallengePuzzleContainer } from './Challenges/ChallengePuzzleContainer'
import { ChallengePuzzles } from './Challenges/ChallengePuzzles/ChallengePuzzles'
import { Challenges } from './Challenges/Challenges'
import { ChallengesScreen } from './Challenges/ChallengesScreen'
import { ChangePassword } from './ChangePassword/ChangePassword'
import { DeletePlayer } from './DeletePlayer/DeletePlayer'
import { Help } from './Help/Help'
import { Home } from './Home/Home'
import { HowToPlay } from './HowToPlay/HowToPlay'
import { Leaderboard } from './Leaderboard/Leaderboard'
import { Login } from './Login/Login'
import { MyPuzzles } from './MyPuzzles/MyPuzzles'
import { NotFound } from './NotFound/NotFound'
import { Notifications } from './Notifications/Notifications'
import { PlayerPuzzleContainer } from './PlayerPuzzles/PlayerPuzzleContainer'
import { PlayerPuzzles } from './PlayerPuzzles/PlayerPuzzles'
import { PlayerPuzzlesScreen } from './PlayerPuzzles/PlayerPuzzlesScreen'
import { PrivacyScreen } from './Privacy/Privacy'
import { Profile } from './Profile/Profile'
import { PuzzleContainer } from './Puzzle/PuzzleContainer'
import { Puzzles } from './Puzzles/Puzzles'
import { Register } from './Register/Register'
import { ResetPassword } from './ResetPassword/ResetPassword'
import { Settings } from './Settings/Settings'
import { SettingsScreen } from './Settings/SettingsScreen'
import { Solved } from './Solved/Solved'
import { SolvedChallenge } from './SolvedChallenge/SolvedChallenge'
import { Splash } from './Splash/Splash'
import { Stats } from './Stats/Stats'
import { SolvedContainer } from './Test/SolvedContainer'
import { Tutorial1 } from './Tutorial/Tutorial1'
import { Tutorial2 } from './Tutorial/Tutorial2'
import { Tutorial3 } from './Tutorial/Tutorial3'
import { Tutorial4 } from './Tutorial/Tutorial4'
import { Tutorial5 } from './Tutorial/Tutorial5'
import { TutorialScreen } from './Tutorial/TutorialScreen'
import { ButtonExample } from './common/Button/Button.example'
import { Seo } from './common/Seo/Seo'
import { TextFieldExample } from './common/TextField/TextField.example'

export const App: React.FC = () => {
	const location = useLocation()

	return (
		<>
			<Seo
				title="Regex Crossword"
				description="A crossword puzzle game using regular expressions. Earn achievements completing puzzle challenges. Easy tutorials for people new to regular expressions."
			/>
			<TransitionGroup>
				<CSSTransition key={location.key} classNames="page" timeout={200}>
					<Routes location={location}>
						<Route path="/" element={<Home />} />
						<Route path="/tutorial" element={<TutorialScreen />}>
							<Route path="1" element={<Tutorial1 />} />
							<Route path="2" element={<Tutorial2 />} />
							<Route path="3" element={<Tutorial3 />} />
							<Route path="4" element={<Tutorial4 />} />
							<Route path="5" element={<Tutorial5 />} />
						</Route>
						<Route path="/challenges" element={<ChallengesScreen />}>
							<Route
								path=":challengeId/puzzles/:puzzleId"
								element={<ChallengePuzzleContainer />}
							/>
							<Route path=":challengeId" element={<ChallengePuzzles />} />
							<Route path="" element={<Challenges />} />
						</Route>
						<Route path="/puzzles/:puzzleId" element={<PuzzleContainer />} />
						<Route path="/puzzles" element={<Puzzles />} />
						<Route path="/register" element={<Register />} />
						<Route path="/reset_password" element={<ResetPassword />} />
						<Route path="/login" element={<Login redirectTo="/" />} />
						<Route path="/players" element={<PlayerPuzzlesScreen />}>
							<Route path=":playerNo" element={<Profile />} />
							<Route
								path="/players/:playerNo/puzzles"
								element={<MyPuzzles />}
							/>
						</Route>
						<Route path="/playerpuzzles" element={<PlayerPuzzlesScreen />}>
							<Route path="" element={<PlayerPuzzles />} />
							<Route path=":puzzleId" element={<PlayerPuzzleContainer />} />
						</Route>
						<Route path="/settings" element={<SettingsScreen />}>
							<Route path="" element={<Settings />} />
							<Route path="delete" element={<DeletePlayer />} />
							<Route path="password" element={<ChangePassword />} />
						</Route>
						<Route path="/builder" element={<BuilderScreen />}>
							<Route path="" element={<BuilderLanding />} />
							<Route path="new/grid" element={<BuilderNewContainer />} />
							<Route
								path="new/hex"
								element={<BuilderNewContainer hexagonal />}
							/>
							<Route path=":puzzleId" element={<BuilderContainer />} />
						</Route>
						<Route path="/leaderboard" element={<Leaderboard />} />
						<Route path="/stats" element={<Stats />} />
						<Route path="/help" element={<Help />} />
						<Route path="/about" element={<AboutScreen />} />
						<Route path="/howtoplay" element={<HowToPlay />} />
						<Route path="/privacy" element={<PrivacyScreen />} />
						<Route path="/test">
							<Route path="button" element={<ButtonExample />} />
							<Route path="text" element={<TextFieldExample />} />
							<Route path="solved/:puzzleId" element={<SolvedContainer />} />
							<Route path="solved" element={<Solved />} />
							<Route path="completed" element={<SolvedChallenge />} />
							<Route
								path="achievement"
								element={
									<AchievementNotification
										id="mobile"
										type="achievement"
										data={{ id: 'mobile', date: Date.now() }}
									/>
								}
							/>
							<Route path="splash" element={<Splash />} />
						</Route>
						<Route path="*" element={<NotFound />} />
					</Routes>
				</CSSTransition>
			</TransitionGroup>
			<Notifications />
		</>
	)
}
