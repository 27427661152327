declare global {
	interface Window {
		gtag?(
			event: 'event',
			action: string,
			options?: {
				event_category?: string
				event_label?: string
				value?: string
				non_interaction?: boolean
			}
		): void
		gtag?(
			event: 'config',
			measurement_id: string,
			options: {
				user_id: string
			}
		): void
	}
}

export const setConfig = (setting: any) =>
	window.gtag?.('config', 'UA-38894114-1', setting)

export const sendEvent = (
	event_category: string,
	action: string,
	event_label?: string,
	non_interaction = false
) => {
	window.gtag?.('event', action, {
		event_category,
		event_label,
		non_interaction,
	})
}

/**
 * Tracks a JavaScript error with optional fields object overrides.
 * This function is exported so it can be used in other parts of the codebase.
 * E.g.:
 *
 *    `fetch('/api.json').catch(trackError);`
 */
export const trackError = (err?: Error, category = 'Error') => {
	sendEvent(
		category,
		err?.name || '(no error name)',
		`${err?.message}\n${err?.stack || '(no stack trace)'}`,
		true
	)
}

/**
 * Tracks any errors that may have occured on the page prior to analytics being
 * initialized, then adds an event handler to track future errors.
 */
const trackErrors = () => {
	// Errors that have occurred prior to this script running are stored on
	// `window.__e.q`, as specified in `index.html`.
	const loadErrorEvents = ((window as any).__e && (window as any).__e.q) || [] // eslint-disable-line

	const trackErrorEvent = (event: any) => {
		// Some browsers don't have an error property, so we fake it.
		const err = event.error || {
			message: `${event.message} (${event.lineno}:${event.colno})`,
		}

		// Use a different eventCategory for uncaught errors.
		trackError(err, 'Uncaught Error')
	}

	// Replay any stored load error events.
	for (const event of loadErrorEvents) {
		// eslint-disable-line
		trackErrorEvent(event)
	}

	// Add a new listener to track event immediately.
	window.addEventListener('error', trackErrorEvent)
}

export const init = () => {
	trackErrors()
}
