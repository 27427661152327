import classNames from 'classnames'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Screen } from '../common/Screen/Screen'
import styles from './Challenges.scss'

export const ChallengesScreen: React.FC = () => {
	return (
		<Screen className={classNames(styles.screen)} title="Challenges">
			<Outlet />
		</Screen>
	)
}
