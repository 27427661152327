import { Haptics } from '@capacitor/haptics'
import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useAppSelector } from '../../redux/hooks'
import { appSelector } from '../../redux/selectors'
import { Rate } from '../Rate/Rate'
import { Button } from '../common/Button/Button'
import { Svg } from '../common/Svg/Svg'
import styles from './Solved.scss'
import imgCheckmark from './checkmark.svg'

type Props = {
	dismiss?: () => void
	enableRating?: boolean
	puzzleId?: string
	show?: boolean
	delay?: number
}

export const Solved: React.FC<Props> = ({
	dismiss,
	enableRating,
	puzzleId,
	show = false,
	/** Delay showing the component by X ms */
	delay = 0,
}) => {
	const [start, setStart] = useState(false)
	const { disableHaptics } = useAppSelector(appSelector)

	useEffect(() => {
		if (show) {
			if (!disableHaptics)
				Haptics.vibrate().catch(() => {
					// ignore fail on devices without haptics
				})
			setTimeout(() => setStart(true), delay)
		}
	}, [show, delay, disableHaptics])

	return (
		<CSSTransition in={start} mountOnEnter classNames="fade" timeout={200}>
			<div className={styles.solved} onClick={dismiss}>
				<div className={styles.content}>
					<Svg src={imgCheckmark} title="Checkmark" />
					<div className={styles.text}>Solved!</div>
					{enableRating && puzzleId && <Rate puzzleId={puzzleId} />}
				</div>
				<Button className={styles.nextButton}>Next</Button>
			</div>
		</CSSTransition>
	)
}
