import React, { useEffect } from 'react'
import iconBack from '../../assets/images/icons/nav-back.svg'
import iconForward from '../../assets/images/icons/nav-forward.svg'
import { useKeyPress } from '../../hooks/useKeyPress'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { IconButton } from '../common/IconButton/IconButton'
import { ReactSwipe, ReactSwipeHandle } from '../common/Swipe/Swipe'
import styles from './Home.scss'

type Props = {
	children: React.ReactNode
	onChange?: (index: number, element: Element) => void
	selectedIndex?: number
}

export const HomeCards: React.FC<Props> = ({
	children,
	onChange,
	selectedIndex = 0,
}) => {
	const swipe = React.useRef<ReactSwipeHandle>(null)

	const disableSwipe = useMediaQuery('(min-width: 1200px)')

	useEffect(() => {
		swipe.current?.slide(selectedIndex)
	}, [swipe, selectedIndex])

	useKeyPress(['ArrowLeft'], () => swipe.current?.prev())
	useKeyPress(['ArrowRight'], () => swipe.current?.next())

	if (disableSwipe) {
		return <div className={styles.desktop}>{children}</div>
	}

	return (
		<>
			<ReactSwipe
				ref={swipe}
				onChange={onChange}
				style={{
					container: {
						width: '100vw',
						height: '100vh',
					},
				}}
			>
				{children}
			</ReactSwipe>
			<IconButton
				alt="Previous"
				className={styles.navButtonLeft}
				onClick={() => swipe.current?.prev()}
				src={iconBack}
			/>
			<IconButton
				alt="Next"
				className={styles.navButtonRight}
				onClick={() => swipe.current?.next()}
				src={iconForward}
			/>
		</>
	)
}
