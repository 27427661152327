import { isAnyOf } from '@reduxjs/toolkit'
import { sendEvent, setConfig } from '../../services/analytics'
import app from '../app/slice'
import { authApi } from '../auth/api'
import slice from '../auth/slice'
import { solved } from '../solved/slice'
import { startAppListening } from './listenerMiddleware'

startAppListening({
	actionCreator: app.actions.getIsAppInstalledToHomeScreen,
	effect: (_, listenerApi) => {
		if (listenerApi.getState().app.isInstalledToHomeScreen)
			sendEvent('Install', 'HomeScreen')
	},
})

startAppListening({
	matcher: authApi.endpoints.register.matchFulfilled,
	effect: () => {
		sendEvent('Auth', 'Register')
	},
})

startAppListening({
	actionCreator: slice.actions.authenticated,
	effect: (action) => {
		setConfig({ user_id: action.payload.playerNo })
		sendEvent('Auth', 'Login')
	},
})

startAppListening({
	matcher: isAnyOf(slice.actions.logout /*, logoutSocial.fulfilled*/),
	effect: () => {
		sendEvent('Auth', 'Logout')
	},
})

startAppListening({
	actionCreator: solved,
	effect: (action) => {
		sendEvent('Puzzles', 'Solved', action.payload.puzzleId)
	},
})

startAppListening({
	matcher: authApi.endpoints.ratePuzzle.matchFulfilled,
	effect: () => {
		sendEvent('Puzzles', 'Rate')
	},
})
