import type { Coordinates, IPuzzlePatterns } from '@omichelsen/regex-lib'
import classNames from 'classnames'
import React from 'react'
import styles from './FocusPatterns.scss'

type Props = {
	coords: Coordinates
	hexagonal: boolean
	patterns: IPuzzlePatterns
}

export const FocusPatterns: React.FC<Props> = ({
	coords,
	hexagonal,
	patterns: { patternsX, patternsY, patternsZ },
}) => {
	if (!coords) {
		return null
	}

	const median = Math.floor(patternsY.length / 2)

	const x = patternsX[coords[0]]
	const y = patternsY[coords[1]]
	const z = patternsZ?.[coords[0] - (coords[1] - median)]

	return (
		<div className={styles.clues} data-testid="clues">
			<div
				className={classNames([styles.clue, styles.horizontal])}
				data-testid="clue-horizontal"
			>
				<div className={styles.dir}>→</div>
				<div>{y[0]}</div>
				{y[0] !== y[1] && <div>{y[1]}</div>}
			</div>
			<div
				className={classNames([styles.clue, styles.vertical])}
				data-testid="clue-vertical"
			>
				<div className={styles.dir}>{hexagonal ? '↙' : '↓'}</div>
				<div>{x[0]}</div>
				{x[0] !== x[1] && <div>{x[1]}</div>}
			</div>
			{z && (
				<div
					className={classNames([styles.clue, styles.diagonal])}
					data-testid="clue-diagonal"
				>
					<div className={styles.dir}>↖</div>
					<div>{z[0]}</div>
					{z[0] !== z[1] && <div>{z[1]}</div>}
				</div>
			)}
		</div>
	)
}
