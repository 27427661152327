import { configureStore } from '@reduxjs/toolkit'
import { merge } from 'lodash-es'
import auth from './auth/slice'
import { middleware } from './middleware'
import { listenerMiddleware } from './middleware/listenerMiddleware'
import { rehydrate, subscribeRehydrator } from './rehydrator'
import { rootReducer } from './rootReducer'

const reducer = (state?: ReturnType<typeof rootReducer>, action: any = {}) => {
	switch (action.type) {
		case rehydrate.toString():
			// merge localStorage state with default state (could be a race between already pending promises)
			if (state && action.payload) {
				return rootReducer(merge({}, state, action.payload), action)
			} else {
				return rootReducer(state, action)
			}

		case auth.actions.logout.toString():
			// passing undefined will clear everything to default state
			return rootReducer(undefined, action)

		default:
			return rootReducer(state, action)
	}
}

export const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.prepend(listenerMiddleware.middleware)
			.concat(middleware),
})

export const rehydrator = subscribeRehydrator(store)

export type Store = typeof store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
