import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import iconKeyboard from '../../assets/images/icons/keyboard.svg'
import app from '../../redux/app/slice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { appSelector } from '../../redux/selectors'
import { Button } from '../common/Button/Button'
import { Icon } from '../common/Icon/Icon'
import { Modal } from '../common/Modal/Modal'
import styles from './KeyboardRequiredNotice.scss'

/** Display a notice for puzzles that require keyboard if not enabled. */
export const KeyboardRequiredNotice: React.FC = () => {
	const navigate = useNavigate()

	const [show, setShow] = useState(true)

	const dispatch = useAppDispatch()
	const { useKeyboard } = useAppSelector(appSelector)

	if (useKeyboard) {
		return null
	}

	return (
		<Modal className={styles.notice} onClose={() => navigate(-1)} show={show}>
			<Icon className={styles.icon} src={iconKeyboard} />
			<p className={styles.text}>This puzzle requires keyboard to play.</p>
			<Button onClick={() => navigate(-1)} secondary>
				Back
			</Button>
			<Button
				onClick={() => {
					dispatch(app.actions.toggleKeyboard())
					setShow(false)
				}}
			>
				Enable keyboard
			</Button>
		</Modal>
	)
}
