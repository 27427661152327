import * as RegexLib from '@omichelsen/regex-lib'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type { Puzzle } from '../../redux/api/index'
import { Unscramble } from '../Puzzle/Unscramble/Unscramble'
import { Button } from '../common/Button/Button'
import { Error } from '../common/Error/Error'
import { IconButton } from '../common/IconButton/IconButton'
import { InputLine } from '../common/InputLine/InputLine'
import { Switch } from '../common/Switch/Switch'
import { TextButton } from '../common/TextButton/TextButton'
import { TextField } from '../common/TextField/TextField'
import styles from './Builder.scss'
import iconMinus from './minus.svg'
import iconPlus from './plus.svg'

export const BuilderSettings: React.FC<{
	isPuzzleValid: boolean
	puzzle: RegexLib.IPuzzle
	onChange: (puzzle: RegexLib.IPuzzle) => void
	onSubmit: (puzzle: RegexLib.IPuzzle) => Promise<any>
}> = ({ isPuzzleValid, onChange, onSubmit, puzzle }) => {
	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<any>()
	const [unscrambledError, setUnscrambledError] = useState<Error>()
	const [showUnscramble, setShowUnscramble] = useState(false)

	const [characters, setCharacters] = useState(
		mergeCharacters(puzzle.solution, puzzle.characters)
	)

	const median = useMemo(
		() => Math.floor(puzzle.patternsY.length / 2) + 1,
		[puzzle.patternsY]
	)

	const setSize = (fn: typeof RegexLib.addX) => () => {
		onChange(fn(puzzle))
	}

	const setPuzzleProp = useCallback(
		<K extends keyof Puzzle>(key: K, value: Puzzle[K]) => {
			onChange({
				...puzzle,
				[key]: value,
			})
		},
		[onChange, puzzle]
	)

	const setUnscrambled = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const unscrambled = e.target.value
			let solutionMap: number[] | undefined = undefined
			if (unscrambled) {
				try {
					solutionMap = RegexLib.scrambleMap(
						puzzle.solution!.reduce((prev, row) => prev + row.join(''), ''),
						unscrambled as string
					)
					setUnscrambledError(undefined)
				} catch (err) {
					setUnscrambledError(err as Error)
				}
			} else {
				setUnscrambledError(undefined)
			}
			onChange({
				...puzzle,
				unscrambled,
				solutionMap,
			})
		},
		[onChange, puzzle]
	)

	const handleChangeHexagonal = useCallback(() => {
		if (
			window.confirm(
				`This will reset your puzzle! Change to ${
					puzzle.hexagonal ? 'Grid' : 'Hexagonal'
				}?`
			)
		) {
			navigate(`/builder/new/${puzzle.hexagonal ? 'grid' : 'hex'}`, {
				replace: true,
			})
		}
	}, [navigate, puzzle.hexagonal])

	const handleChangeCharacters = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.toUpperCase().split('')
		setCharacters(mergeCharacters(puzzle.solution, value))
	}

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		const savePuzzle = {
			...puzzle,
			characters: mergeCharacters(puzzle.solution, characters),
		}
		setLoading(true)
		onSubmit(savePuzzle)
			.then(() => setError(undefined))
			.catch(setError)
			.then(() => setLoading(false))
	}

	const xLen = puzzle.patternsX.length
	const yLen = puzzle.patternsY.length

	return (
		<form
			className={styles.controlPanel}
			data-testid="loginForm"
			onSubmit={handleSubmit}
		>
			<section className={styles.section}>
				<InputLine>
					<span>Hexagonal</span>
					<Switch
						checked={!!puzzle.hexagonal}
						disabled={!!puzzle.id}
						onChange={handleChangeHexagonal}
					/>
				</InputLine>
				{!!puzzle.id && (
					<div className={styles.legend}>
						Create a new puzzle to change type.
					</div>
				)}
			</section>
			<div>
				<InputLine>
					<span>Col</span>
					<IconButton
						className={styles.iconButton}
						src={iconPlus}
						onClick={setSize(RegexLib.addX)}
						secondary
					/>
					<IconButton
						className={styles.iconButton}
						disabled={xLen <= 1 || (puzzle.hexagonal && xLen <= median)}
						src={iconMinus}
						onClick={setSize(RegexLib.delX)}
						secondary
					/>
				</InputLine>
				<InputLine>
					<span>Row</span>
					<IconButton
						className={styles.iconButton}
						disabled={puzzle.hexagonal && (xLen == 1 || yLen > xLen)}
						src={iconPlus}
						onClick={setSize(RegexLib.addY)}
						secondary
					/>
					<IconButton
						className={styles.iconButton}
						disabled={yLen <= 1}
						src={iconMinus}
						onClick={setSize(RegexLib.delY)}
						secondary
					/>
				</InputLine>
			</div>
			<div>
				<TextField
					data-testid="puzzleName"
					label="Name"
					minLength={3}
					onChange={(e) => setPuzzleProp('name', e.target.value)}
					placeholder="Name"
					required
					value={puzzle.name}
				/>
			</div>
			{(puzzle.size ?? 0) > 1 && (
				<div>
					<TextField
						autoComplete="off"
						data-testid="unscramble"
						errorText={unscrambledError && unscrambledError.message}
						helperText="Type in the correct sequence of the letters from the solution
								and they will be reshuffled once the user solves the puzzle and
								a reveal a word or sentence."
						label="Unscrambled"
						onChange={setUnscrambled}
						placeholder="Unscrambled (optional)"
						value={puzzle.unscrambled ?? ''}
					/>
					{puzzle.unscrambled && !unscrambledError && (
						<TextButton
							className={styles.unscrambleTextLink}
							onClick={() => setShowUnscramble(true)}
						>
							Show unscramble effect
						</TextButton>
					)}
				</div>
			)}
			{puzzle.mobile && (
				<TextField
					autoComplete="off"
					data-testid="characters"
					helperText="Players can choose from these letters when solving the puzzle on
						mobile without a keyboard."
					label="Characters"
					placeholder="Characters"
					value={characters.join('')}
					onChange={handleChangeCharacters}
				/>
			)}
			<section className={styles.section}>
				<InputLine>
					<span>Mobile</span>
					<Switch
						checked={!!puzzle.mobile}
						onChange={() => setPuzzleProp('mobile', !puzzle.mobile)}
					/>
				</InputLine>
				<InputLine>
					<span>Published</span>
					<Switch
						checked={!!puzzle.published}
						disabled={!isPuzzleValid}
						onChange={() => setPuzzleProp('published', !puzzle.published)}
					/>
				</InputLine>
				{!isPuzzleValid && (
					<div className={styles.legend}>
						Puzzle must be valid to be published.
					</div>
				)}
			</section>
			<Button
				data-testid="builderSubmit"
				loadingText="Saving..."
				loading={loading}
				type="submit"
			>
				Save
			</Button>
			<Error error={error} />
			{showUnscramble && puzzle.solutionMap && (
				<Unscramble
					map={puzzle.solutionMap}
					onClick={() => setShowUnscramble(false)}
					unmountAfter={2000}
				/>
			)}
		</form>
	)
}

/** Combines characters from the puzzle solution with user input and returns a unique, sorted list. */
const mergeCharacters = (
	solution: RegexLib.IAnswer = [[]],
	input: string[] = []
) => {
	const base = RegexLib.getCharacters(solution)
	return [...new Set(base.concat(input))].sort()
}
