import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useRef } from 'react'
import { scrollTo } from '../../../services/smoothScroll'
import { Field } from '../Field/Field'
import stylesHex from '../Hexagonal/Hexagonal.scss'
import styles from '../Puzzle.scss'
import stylesKey from './Keyboard.scss'

const animationDelay = 500
const animationTime = 2000

type Props = {
	characters?: string[]
	hexagonal?: boolean
	selectedChar?: string
	onCharClick: (e: React.MouseEvent) => void
}

export const Keyboard: React.FC<Props> = memo(function Keyboard({
	characters = [],
	hexagonal = false,
	selectedChar,
	onCharClick,
}) {
	const handleDragStart = useCallback((e: React.DragEvent) => {
		const elm = e.target as HTMLElement
		elm.classList.add(styles.dragging)
		const char = elm.dataset.value || ''
		e.dataTransfer!.setData('text/plain', char)
	}, [])

	const handleDragEnd = useCallback((e: React.DragEvent) => {
		;(e.target as HTMLElement).classList.remove(styles.dragging)
	}, [])

	const elmRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const elm = elmRef.current
		if (elm && elm.scrollWidth > elm.clientWidth) {
			elm.scrollLeft = elm.scrollWidth
			const id = setTimeout(
				() => scrollTo(elm, 0, animationTime),
				animationDelay
			)
			return () => clearTimeout(id)
		}
	}, [])

	if (!characters) {
		return null
	}

	return (
		<div className={stylesKey.keyboard} data-testid="keyboard" ref={elmRef}>
			{characters.map((char) => (
				<span
					key={`char-${char}`}
					className={classNames(stylesKey.key, {
						[stylesKey.hexagonal]: hexagonal,
					})}
				>
					<Field
						className={classNames(styles.field, {
							[stylesHex.selected]: selectedChar === char,
						})}
						data-value={char}
						draggable
						hexagonal={hexagonal}
						onClick={onCharClick}
						onDragEnd={handleDragEnd}
						onDragStart={handleDragStart}
						selected={selectedChar === char}
						tabIndex={-1}
						value={char!}
					/>
				</span>
			))}
		</div>
	)
})
