import classNames from 'classnames'
import React from 'react'
import styles from './TextButton.scss'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>

export const TextButton: React.FC<Props> = ({
	children,
	className,
	...rest
}) => (
	<button
		className={classNames(styles.textButton, className)}
		type="button"
		{...rest}
	>
		{children}
	</button>
)
