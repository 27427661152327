import * as RegexLib from '@omichelsen/regex-lib'
import { Coordinates, IPatterns } from '@omichelsen/regex-lib'
import classNames from 'classnames'
import React from 'react'
import { TextField } from '../common/TextField/TextField'
import styles from './Puzzle.scss'

type Props = {
	className?: string
	allowEdit?: boolean
	errors?: RegexLib.ValidationResult
	focus: Coordinates
	index: number
	patterns: IPatterns
	onChange?: (i: number, v: string) => void
}

export const CluesRow: React.FC<Props> = React.memo(function CluesRow({
	allowEdit = false,
	errors,
	focus,
	index = 0,
	patterns,
	onChange,
}) {
	if (!allowEdit && !patterns.some((p = []) => p[index] && !!p[index].length)) {
		return null
	}

	return (
		<tr>
			<th />
			{patterns.map((p, y) => (
				<th
					className={classNames({
						[styles.highlight]: y === focus[0],
					})}
					key={`clueX${index}${y}`}
				>
					<div
						className={classNames(styles.clue, {
							[styles.error]: errors?.[y]?.[index] === false,
						})}
					>
						<TextField
							autoComplete="off"
							className={classNames(styles.inner, styles.input)}
							readOnly={!allowEdit}
							tabIndex={-1}
							value={p[index]}
							onChange={(e) => onChange?.(y, e.target.value)}
						/>
					</div>
				</th>
			))}
		</tr>
	)
})
