import classNames from 'classnames'
import React, { HTMLAttributes } from 'react'
import styles from './Popup.scss'

export const Popup: React.FC<HTMLAttributes<HTMLElement>> = ({
	children,
	className,
	...rest
}) => {
	return (
		<div className={classNames(styles.popup, className)} {...rest}>
			<div className={styles.inner}>{children}</div>
		</div>
	)
}
