import { regexApi } from '../api'
import { startAppListening } from '../middleware/listenerMiddleware'
import slice, { solved } from './slice'
import auth from '../auth/slice'
import app from '../app/slice'
import { isAnyOf } from '@reduxjs/toolkit'
import { solvedSelector } from '../selectors'

startAppListening({
	actionCreator: solved,
	effect: ({ payload }, { dispatch, getState }) => {
		const state = getState()
		if (state.auth.isAuthenticated && state.app.online) {
			// send the solve to the api
			dispatch(regexApi.endpoints.addSolved.initiate(payload))
		} else {
			// store for future transmission
			dispatch(slice.actions.add(payload))
			// inject into query cache (source of truth for the app)
			// 		note: upsert needed if no query have been made before
			const solvedState = solvedSelector(state)
			const draft: typeof solvedState = {
				...solvedState,
				[payload.puzzleId]: true,
			}
			dispatch(regexApi.util.upsertQueryData('getSolved', undefined, draft))
		}
	},
})

// transmit cached solves to the API
startAppListening({
	matcher: isAnyOf(auth.actions.authenticated, app.actions.setConnectionStatus),
	effect: (_, { dispatch, getState }) => {
		const { solved, auth, app } = getState()
		if (solved.length && auth.isAuthenticated && app.online) {
			solved.forEach(async (s) => {
				try {
					await dispatch(regexApi.endpoints.addSolved.initiate(s))
					dispatch(slice.actions.remove(s.puzzleId))
				} catch (err) {
					// ignore and leave in queue
					console.error(err)
				}
			})
		}
	},
})
