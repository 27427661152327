import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'
import { tutorialSelector } from '../../redux/selectors'
import { Puzzle } from '../Puzzle/Puzzle'
import { FloatingBox } from '../common/FloatingBox/FloatingBox'
import { Pointer } from '../common/Pointer/Pointer'
import { Seo } from '../common/Seo/Seo'
import styles from './Tutorial.scss'

/** Tutorial: one or more. */
export const Tutorial1: React.FC = (props) => {
	const navigate = useNavigate()

	const tutorial = useAppSelector(tutorialSelector)

	const [step, setStep] = React.useState(0)

	return (
		<div>
			<Seo title="Tutorial: 1" />
			<FloatingBox
				pointer="top"
				selector="[data-testid=clues]"
				show={step === 0}
			>
				<p>
					These are the regex <b>clues</b> that your solution must satisfy.
				</p>
				<p>
					Clues for the selected field are shown at the <b>top of the screen</b>
					.
				</p>
				<p>
					Tap the <b>second field</b> of the puzzle to highlight the clues.
				</p>
			</FloatingBox>
			{step === 0 && (
				<Pointer
					className={styles.pointer1}
					delay={400}
					selector="tbody td:nth-of-type(2)"
				/>
			)}
			<FloatingBox
				pointer="top"
				selector="[data-testid=clues]"
				show={step === 1}
			>
				<p>
					These clues tell us the row <code>A+</code> must be{' '}
					<b>one or more A</b> and the column <code>A|B</code> is <b>A or B</b>.
				</p>
			</FloatingBox>
			<FloatingBox selector="[data-testid=keyboard]" show={step === 1}>
				<p>
					Since only the letter <b>A</b> satisfies both clues, drag the letter{' '}
					<b>A</b> from the bottom into the field.
				</p>
			</FloatingBox>
			{step === 1 && (
				<Pointer
					className={styles.pointer1}
					selector="[data-testid=keyboard] :first-child"
				/>
			)}
			<Puzzle
				{...props}
				answer={tutorial[0].answer || undefined}
				onBackNavigation={() => navigate('/')}
				onPressField={([x]) => setStep(x)}
				onSuccessDismiss={() => navigate('/tutorial/2')}
				puzzle={tutorial[0]}
				showErrors
			/>
		</div>
	)
}
