import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { Network } from '../../services/social'
import type { components } from '../../typings.api'

export type AuthState = {
	hasUsername?: boolean
	isAuthenticated: boolean
	network?: Network
	playerNo?: number
	token?: string
}

export default createSlice({
	name: 'auth',
	initialState: { isAuthenticated: false } as AuthState,
	reducers: {
		authenticated: (
			state,
			{ payload }: PayloadAction<components['schemas']['AuthResponse']>
		) => {
			state.hasUsername = payload.hasUsername
			state.isAuthenticated = true
			state.network = payload.network
			state.playerNo = payload.playerNo
			state.token = payload.token
		},
		logout: () => {
			// logout is handled in the root reducer by resetting everything
		},
	},
})
