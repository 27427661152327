import React from 'react'
import { Helmet } from 'react-helmet-async'

type Props = {
	description?: string
	title?: string
	image?: string
}

export const Seo: React.FC<Props> = ({ title, description, image }) => {
	return (
		<Helmet>
			{title && <title>{title}</title>}
			{description && <meta name="description" content={description} />}
			{image && <link rel="image_src" href={image} />}
			{image && <meta name="og:image" content={image} />}
		</Helmet>
	)
}
