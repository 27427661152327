import React from 'react'
import styles from './Error.scss'

type Props = {
	error?: any
}

export const Error: React.FC<Props> = ({ error }) => {
	if (!error) {
		return null
	}

	return (
		<div className={styles.error} data-testid="error">
			{[error.data?.message, error.data?.errors?.join(', ')]
				.filter(Boolean)
				.join(': ')}
		</div>
	)
}
