import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useResetPasswordMutation } from '../../redux/auth/api'
import { Button } from '../common/Button/Button'
import { Error } from '../common/Error/Error'
import { Header } from '../common/Header/Header'
import { Navbar } from '../common/Navbar/Navbar'
import { Screen } from '../common/Screen/Screen'
import { TextField } from '../common/TextField/TextField'
import styles from './ResetPassword.scss'

export const ResetPassword: React.FC = () => {
	const navigate = useNavigate()

	const [username, setUsername] = useState('')

	const [resetPassword, resetPasswordResult] = useResetPasswordMutation()

	useEffect(() => {
		if (resetPasswordResult.isSuccess) navigate(-1)
	}, [resetPasswordResult.isSuccess, navigate])

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		resetPassword(username)
	}

	return (
		<Screen title="Reset password" description="Reset your password.">
			<Navbar />
			<Header>
				<h1>Reset password</h1>
			</Header>
			<form
				className={styles.form}
				data-testid="resetPasswordForm"
				onSubmit={handleSubmit}
			>
				<p>Enter your email address and we will send you a new password.</p>
				<TextField
					autoCapitalize="off"
					autoComplete="username"
					data-testid="resetPasswordEmail"
					onChange={(e) => setUsername(e.target.value)}
					placeholder="Email"
					pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.[a-zA-Z]{2,6}$"
					required
					value={username}
				/>
				<Button
					data-testid="resetPasswordSubmit"
					loadingText="Resetting..."
					loading={resetPasswordResult.isLoading}
					type="submit"
				>
					Reset
				</Button>
				<Error error={resetPasswordResult.error} />
			</form>
		</Screen>
	)
}
