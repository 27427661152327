import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'
import { tutorialSelector } from '../../redux/selectors'
import { Puzzle } from '../Puzzle/Puzzle'
import { FloatingBox } from '../common/FloatingBox/FloatingBox'
import { Seo } from '../common/Seo/Seo'

/** Tutorial: character ranges. */
export const Tutorial3: React.FC = (props) => {
	const navigate = useNavigate()

	const tutorial = useAppSelector(tutorialSelector)

	return (
		<div>
			<Seo title="Tutorial: 3" />
			<FloatingBox selector="[data-testid=clues]" show>
				<p>
					A <b>range</b> <code>[AB]</code> means <b>one of</b> the given
					letters.
				</p>
				<p>
					A leading caret in the range <code>[^AB]</code> means <b>not</b> these
					letters.
				</p>
			</FloatingBox>
			<Puzzle
				{...props}
				onBackNavigation={() => navigate('/')}
				onSuccessDismiss={() => navigate('/tutorial/4')}
				puzzle={tutorial[2]}
				showErrors
			/>
		</div>
	)
}
