import React, { useEffect } from 'react'

declare global {
	interface Window {
		DISQUS: any
		disqus_config: any
	}
}

type Props = {
	shortname: string
	identifier: string
	url: string
	pageTitle: string
}

export const Disqus: React.FC<Props> = (props) => {
	useEffect(() => {
		const config = function (this: any) {
			this.page.identifier = props.identifier
			this.page.url = props.url
			this.page.title = props.pageTitle
		}

		window.disqus_config = config

		if (!window.DISQUS) {
			;(function () {
				const d = document
				const s = d.createElement('script')
				s.src = `https://${props.shortname}.disqus.com/embed.js`
				s.setAttribute('data-timestamp', (+new Date()).toString())
				;(d.head || d.body).appendChild(s)
			})()
		} else {
			window.DISQUS.reset({
				reload: true,
				config,
			})
		}
	}, [props.identifier, props.url, props.pageTitle, props.shortname])

	return <div id="disqus_thread"></div>
}
