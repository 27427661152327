const dev = {
	api: {
		url: 'http://localhost:8000',
	},
}

export default {
	host: {
		url: 'https://regexcrossword.com',
	},
	api: {
		url: 'https://api.regexcrossword.com/api',
	},
	userReport: {
		bug: 'https://feedback.userreport.com/6f7d752a-9d52-45fd-b1c7-c98a19396143/#submit/bug',
		idea: 'https://feedback.userreport.com/6f7d752a-9d52-45fd-b1c7-c98a19396143/#submit/idea',
		overview:
			'https://feedback.userreport.com/6f7d752a-9d52-45fd-b1c7-c98a19396143/#ideas/popular',
	},
	networks: {
		facebook: '416105278477663',
		github: 'fbc3101dff3a574c7c31',
		google:
			'1050415640469-ek1rfbburmq87lpl64r25945bppg9j63.apps.googleusercontent.com',
		twitter: '82hcXvV9y67D8oQOlOsYQ',
		windows: '000000004C130932',
	},
	...(process.env.NODE_ENV === 'development' ? dev : {}),
}
