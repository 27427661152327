import classNames from 'classnames'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Navbar } from '../common/Navbar/Navbar'
import { Screen } from '../common/Screen/Screen'
import styles from '../Puzzle/Puzzle.scss'
import { Secure } from '../Secure/Secure'
import stylesb from './Builder.scss'
import { RulesModal } from './RulesModal'

export const BuilderScreen: React.FC = () => {
	return (
		<Secure>
			<Screen className={classNames(styles.container, stylesb.builder)}>
				<Navbar>
					<RulesModal />
				</Navbar>
				<Outlet />
			</Screen>
		</Secure>
	)
}
