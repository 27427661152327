import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './ListItem.scss'

type Props = {
	children?: React.ReactNode
	className?: string
	highlight?: boolean
	style?: React.CSSProperties
	url: string
}

export const ListItem: React.FC<Props> = ({
	children,
	className,
	highlight,
	url,
	style,
}) => {
	return (
		<Link
			className={classNames(styles.listItem, className, {
				[styles.highlight]: highlight,
			})}
			draggable={false}
			to={url}
			style={style}
		>
			{children}
		</Link>
	)
}

export const ListItemText: React.FC<{ children?: React.ReactNode }> = ({
	children,
}) => <span className={styles.text}>{children}</span>
