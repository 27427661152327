import type { DeviceInfo } from '@capacitor/device'
import { Device } from '@capacitor/device'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

declare global {
	interface Navigator {
		standalone: boolean
	}
}

export type AppState = {
	deviceInfo?: DeviceInfo
	disableHaptics?: boolean
	disableMusic?: boolean
	disableSoundEffects?: boolean
	homeIndex: number
	isInstalledToHomeScreen?: boolean
	online: boolean
	prefersReducedMotion?: boolean
	showDesktopPuzzles: boolean
	showDonate: boolean
	showHelp: boolean
	showInstall?: boolean
	sortPuzzlesBy: 'name' | 'rating' | 'solved' | 'dateUpdated desc'
	useKeyboard: boolean
}

export const initialState: AppState = {
	disableHaptics: false,
	homeIndex: 0,
	online: true,
	showDesktopPuzzles: false,
	showDonate: false,
	showHelp: false,
	sortPuzzlesBy: 'rating',
	useKeyboard: false,
}

export const getDeviceInfo = createAsyncThunk('app/deviceInfo', Device.getInfo)

export default createSlice({
	name: 'app',
	initialState,
	reducers: {
		setConnectionStatus: (state, action: PayloadAction<boolean>) => {
			state.online = action.payload
		},
		setHomeIndex: (state, action: PayloadAction<number>) => {
			state.homeIndex = action.payload
		},
		setPrefersReducedMotion: (state, action: PayloadAction<boolean>) => {
			state.prefersReducedMotion = action.payload
		},
		toggleDesktopPuzzles: (state) => {
			state.showDesktopPuzzles = !state.showDesktopPuzzles
		},
		toggleDonate: (state, action: PayloadAction<boolean | undefined>) => {
			state.showDonate = action.payload ?? !state.showDonate
		},
		toggleHaptics: (state) => {
			state.disableHaptics = !state.disableHaptics
		},
		toggleHelp: (state, action: PayloadAction<boolean | undefined>) => {
			state.showHelp = action.payload ?? !state.showHelp
		},
		toggleInstall: (state, action: PayloadAction<boolean | undefined>) => {
			state.showInstall = action.payload ?? !state.showInstall
		},
		toggleMusic: (state) => {
			state.disableMusic = !state.disableMusic
		},
		toggleSoundEffects: (state) => {
			state.disableSoundEffects = !state.disableSoundEffects
		},
		toggleKeyboard: (state) => {
			state.useKeyboard = !state.useKeyboard
		},
		sortPuzzlesBy: (
			state,
			action: PayloadAction<AppState['sortPuzzlesBy']>
		) => {
			state.sortPuzzlesBy = action.payload
		},
		getIsAppInstalledToHomeScreen: (state) => {
			const isStandalone = window.matchMedia(
				'(display-mode: standalone)'
			).matches
			if (document.referrer.startsWith('android-app://')) {
				state.isInstalledToHomeScreen = true // TWA
			} else if (navigator.standalone || isStandalone) {
				state.isInstalledToHomeScreen = true
			}
			state.isInstalledToHomeScreen = false
		},
	},
	extraReducers: (builder) =>
		builder.addCase(getDeviceInfo.fulfilled, (state, action) => {
			state.deviceInfo = action.payload
		}),
})
