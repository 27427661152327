import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

export const useKeyPress = (
	keys: string[],
	callback: (e: KeyboardEvent) => void,
	node = null
) => {
	const callbackRef = useRef(callback)
	useLayoutEffect(() => {
		callbackRef.current = callback
	})

	const handleKeyPress = useCallback(
		(event: KeyboardEvent) => {
			if (keys.some((key) => event.key === key)) {
				callbackRef.current(event)
			}
		},
		[keys]
	)

	useEffect(() => {
		const target = node ?? document
		target && target.addEventListener('keydown', handleKeyPress)
		return () => target && target.removeEventListener('keydown', handleKeyPress)
	}, [handleKeyPress, node])
}
