/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Link } from 'react-router-dom'
import iconBug from '../../assets/images/icons/bug.svg'
import iconBullhorn from '../../assets/images/icons/bullhorn.svg'
import config from '../../config'
import app from '../../redux/app/slice'
import { useAppDispatch } from '../../redux/hooks'
import { DonateModal } from '../DonateModal/DonateModal'
import { ButtonGroup } from '../common/ButtonGroup/ButtonGroup'
import { Content } from '../common/Content/Content'
import { IconButton } from '../common/IconButton/IconButton'
import { Navbar } from '../common/Navbar/Navbar'
import { Screen } from '../common/Screen/Screen'

export const About: React.FC = () => {
	const dispatch = useAppDispatch()
	const handleShowDonate = (e: React.MouseEvent) => {
		e.preventDefault()
		dispatch(app.actions.toggleDonate())
	}

	return (
		<>
			<h1>About</h1>

			<p>
				<strong>Regex Crossword</strong> is a crossword puzzle game where the
				clues are defined using regular expressions. The site is created and
				maintained by{' '}
				<a href="https://ole.michelsen.dk" target="_blank" rel="noreferrer">
					Ole Bjørn Michelsen
				</a>
				. It's a great way for newcomers to learn regular expressions and for
				experts to practice their skills.
			</p>

			<h2>FAQ</h2>

			<ul className="list">
				<li>
					<a href="#whatis">What is a regular expression?</a>
				</li>
				<li>
					<a href="#regexengine">Which regex engine do you use?</a>
				</li>
				<li>
					<a href="#whylogin">Why do I have to login?</a>
				</li>
				<li>
					<a href="#canihelp">I have a suggestion. Can I help?</a>
				</li>
				<li>
					<a href="#validation">
						My answer for a puzzle is not accepted, is there more than one
						solution?
					</a>
				</li>
				<li>
					<a href="#learn">Where can I learn more about regular expressions?</a>
				</li>
			</ul>

			<h3 id="whatis">What is a regular expression?</h3>

			<p>
				A regular expression (regex or regexp for short) is a special text
				string for describing a search pattern. You can think of regular
				expressions as wildcards on steroids. You are probably familiar with
				wildcard notations such as *.txt to find all text files in a file
				manager. The regex equivalent is <code>.*\.txt$</code>.
			</p>

			<p>
				For example, if you wanted to find all references to the name "Casper"
				but using all the different ways of spelling it, you could use the
				regular expression <code>[CK]asp[ae]r</code>, which will match both
				"Casper", "Caspar", "Kasper" and "Kaspar".
			</p>

			<h3 id="regexengine">Which regex engine do you use?</h3>

			<p>
				All puzzles are validated live using the JavaScript regex engine.
				Mozilla has detailed the{' '}
				<a
					href="https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions"
					target="_blank"
					rel="noreferrer"
				>
					language implementation here
				</a>
				.
			</p>

			<h3 id="whylogin">Why do I have to login?</h3>

			<p>
				In order to remember which puzzles you have solved, we need to identify
				you in some way. We have chosen to support social logins like Facebook
				etc. because it's easy and because most people have an account with one
				of these services. We will <strong>not</strong> ask for your e-mail or
				post things on your behalf. We are only registering your ID and the
				achievements you have earned solving puzzles.
			</p>

			<h3 id="canihelp">I have a suggestion. Can I help?</h3>

			<p>
				Sure you can! If you have good ideas for new puzzles or suggestions for
				improving the game, we would love to hear them. Just use the "Feedback"
				link in the top of the screen to send us your thoughts. Of course you
				can also{' '}
				<a href="#" onClick={handleShowDonate}>
					donate
				</a>{' '}
				to help us keep the site running.
			</p>

			<h3 id="learn">Where can I learn more about regular expressions?</h3>

			<p>
				This{' '}
				<a
					href="https://en.wikipedia.org/wiki/Regular_expression"
					target="_blank"
					rel="noreferrer"
				>
					Wikipedia article
				</a>{' '}
				is a good place to start. For a more technical walkthrough you can refer
				to{' '}
				<a
					href="https://www.regular-expressions.info/"
					target="_blank"
					rel="noreferrer"
				>
					regular-expressions.info
				</a>
				. Finally, if you need a quick reference sheet and a handy tool to test
				your regular expressions, you can use this great tool:{' '}
				<a href="https://regex101.com/" target="_blank" rel="noreferrer">
					Regex101.com
				</a>
				.
			</p>

			<h3 id="validation">
				My answer for a puzzle is not accepted, are there more than one
				solution?
			</h3>

			<p>
				All solutions are validated by matching the regular expression clues
				against the answer. We are not comparing it to a pre-defined solution.
				If your answer is not accepted, it means that one of the clues are not
				validating.
			</p>

			<DonateModal />

			<p>
				<Link to="/privacy" draggable={false}>
					Privacy Policy
				</Link>
			</p>
		</>
	)
}
export const AboutScreen: React.FC = () => (
	<Screen>
		<Navbar>
			<ButtonGroup>
				<IconButton
					alt="Bug Report"
					onClick={() => window.open(config.userReport.bug, '_blank')}
					src={iconBug}
				/>
				<IconButton
					alt="Feedback & Ideas"
					onClick={() => window.open(config.userReport.overview, '_blank')}
					src={iconBullhorn}
				/>
			</ButtonGroup>
		</Navbar>
		<Content>
			<About />
		</Content>
	</Screen>
)
