import { InAppReview } from '@capacitor-community/in-app-review'
import { Capacitor } from '@capacitor/core'
import { solved } from '../solved/slice'
import { startAppListening } from './listenerMiddleware'

if (Capacitor.isNativePlatform()) {
	startAppListening({
		actionCreator: solved,
		effect: () => {
			// Prompt user to rate the app in the app store
			InAppReview.requestReview()
		},
	})
}
