import { createSelector } from '@reduxjs/toolkit'
import { regexApi } from './api'
import { overrides } from './selectorsOverride'
import type { RootState as State } from './store'

export const achievementsSelector = (state: State) => state.achievements

const baseAppSelector = (state: State) => state.app

export const appSelector = createSelector(baseAppSelector, (s) => ({
	...s,
	...overrides.app,
}))
export const isOnlineSelector = createSelector(
	appSelector,
	({ online }) => online
)
export const homeIndexSelector = (state: State) => state.app.homeIndex
export const prefersReducedMotionSelector = (state: State) =>
	state.app.prefersReducedMotion

export const authSelector = (state: State) => state.auth
export const isAuthenticatedSelector = (state: State) =>
	state.auth.isAuthenticated

export const notificationsSelector = (state: State) => state.notifications

export const puzzlesSelector = (state: State) => state.puzzles
export const tutorialSelector = (state: State) => state.tutorial

export const solvedSelector = (state: State) => ({
	...regexApi.endpoints.getSolved.select()(state).data,
	...overrides.solved,
})

export const nextPuzzleSelector = createSelector(
	solvedSelector,
	puzzlesSelector,
	(solved, puzzles) => puzzles.find(({ id }) => !(id in solved))
)
export const isPuzzlesCompletedSelector = createSelector(
	solvedSelector,
	puzzlesSelector,
	(solved, puzzles) => puzzles.every(({ id }) => id in solved)
)

export const solvedTutorialSelector = createSelector(
	solvedSelector,
	tutorialSelector,
	(solved, tutorial) => tutorial.filter(({ id }) => id in solved)
)
export const nextTutorialSelector = createSelector(
	solvedSelector,
	tutorialSelector,
	(solved, tutorial) => tutorial.findIndex(({ id }) => !(id in solved)) + 1
)
export const isTutorialCompletedSelector = createSelector(
	solvedSelector,
	tutorialSelector,
	(solved, tutorial) => tutorial.every(({ id }) => id in solved)
)

export const solvedPuzzlesSelector = createSelector(
	solvedSelector,
	puzzlesSelector,
	(solved, puzzles) => puzzles.filter(({ id }) => id in solved)
)

export const challengesSelector = (state: State) =>
	state.challenges.filter(({ id }) => id != 'tutorial' && id != 'mythology')
export const challengesMapSelector = createSelector(
	challengesSelector,
	(challenges) => new Map(challenges.map((c) => [c.id, c]))
)
export const challengePuzzlesSelector = () => challengePuzzleIds
export const challengePuzzlesSolvedSelector = createSelector(
	challengesSelector,
	solvedSelector,
	challengePuzzlesSelector,
	(challenges, solved, puzzles) =>
		challenges.reduce(
			(o, c) => ({
				...o,
				[c.id]: {
					total: c.puzzleCount,
					solved: puzzles[c.id].filter((id) => id in solved).length,
				},
			}),
			{} as {
				[key: string]: {
					total: number
					solved: number
				}
			}
		)
)
export const challengePuzzlesSolvedSumSelector = createSelector(
	challengesSelector,
	challengePuzzlesSolvedSelector,
	(challenges, solved) => ({
		total: challenges.reduce((sum, { puzzleCount }) => sum + puzzleCount, 0),
		solved: Object.values(solved).reduce((sum, value) => sum + value.solved, 0),
	})
)

/** TODO: find another way to identify solved challenge puzzles to save space */
const challengePuzzleIds = {
	beginner: [
		'27725dbd-2c90-44ef-8a2e-53f54e7a2590',
		'475e811a-da59-4ce8-9b80-3124b33cc041',
		'85b1c976-6c89-4d3a-9a2a-f90a6a12a564',
		'c9a9dce7-9300-47f7-b47f-ff0a0403b411',
		'd02a5949-9b96-475f-9311-18bf886654fb',
	],
	cities: [
		'2b6c38e4-f11b-49e9-857a-1a9c782c38b6',
		'449f4c0d-8894-46bf-9311-15d07b7d6c7d',
		'4588b3cc-9898-46e6-a9fc-3bc9599220c6',
		'8ce3aedf-9f35-4096-8437-17d29593dbd5',
		'dab395e3-378a-4e57-bb33-557c33fd00d5',
	],
	doublecross: [
		'01b9eade-f344-4064-86f4-897bba049b13',
		'363eb9ad-836f-4aae-a8c0-56e3a652ec29',
		'3ef0777c-ac56-484d-951a-0e268188057d',
		'e36245e6-ec27-4c8e-9dac-1bddba5353fb',
		'f0f06b00-ec0a-4572-935d-7459e2a13064',
	],
	experienced: [
		'1b94c150-c801-46c0-9670-ce3c6e8661b1',
		'2395f2d8-c2bb-4f8b-8954-1a1d597a400d',
		'824d4868-0688-45b4-87c5-a87ced011747',
		'a59414b5-a2ec-4aa9-99e7-1c0a73d694fd',
		'e9970ff7-eef5-40fe-b36d-d482be01dffa',
	],
	hamlet: [
		'107a5820-17af-41af-a57e-8e842a151e9b',
		'40c01a03-677d-47b4-ab73-ea1b1b6e3fba',
		'5a6cf6a5-4578-456d-8381-56c591cfcf49',
		'8d22e2e9-39be-465b-b623-dbcf57219ed5',
		'b6265328-c88f-49ac-9052-dd2227cb325b',
		'ead4196d-43bb-4643-ac3e-39224fd13e95',
	],
	hexagonal: [
		'07272029-2d13-4528-a3c6-e41ab69a8841',
		'0aac7710-8428-4209-82a8-f8f1015f9262',
		'2e9955c8-a87a-4e7d-8e7d-fae2e4bdbde2',
		'3d870dd4-9c37-4e82-bbce-033d38acf36f',
		'49442a2d-ec56-4dcf-981c-ba497db89e6d',
	],
	intermediate: [
		'48f25c7e-0416-410e-b96a-c7ee19dfa110',
		'5b3bb0df-5f7c-4705-84b9-a800ea8f6455',
		'7aa20819-ea88-4bb4-b7e0-022ffaaeae64',
		'837fcf0c-7cd1-46d8-8a63-9902e689b772',
		'cb82634d-fd6a-4d14-9fac-e7f6be244c2d',
	],
	mobile: [
		'5daa327eabbe3',
		'5daa334b28d81',
		'5daa3b050454c',
		'5daa3bb181c3b',
		'5daa3c38ad389',
	],
	mythology: [
		'5f2dd5430e920',
		'5f2f37e48d2d0',
		'5f42b6a5198d1',
		'5f453de32fa64',
		'5f5d6b74b5312',
		'5f6053f70b4c3',
		'5f60fc15572fb',
		'5f6103ee689c7',
		'5f6236aa1d55d',
		'5f6293da8406b',
		'5f64157849bce',
		'5f651f42e9a95',
		'61e8eccec9f47',
		'61e9de2a8b89f',
		'61eca76bee9ad',
	],
	palindromeda: [
		'0eef9c22-d204-4704-81b6-86c556b242b1',
		'3b3b2247-8338-48ea-9c5c-b9d602d1c529',
		'604a09f7-9e77-4e3a-8ed5-3d3139a69678',
		'6dd2c6ae-8e90-4f60-bf11-310aa62f09ca',
		'86905c1e-68b2-436b-b4d9-869cffb2df89',
	],
	tutorial: [
		'272901bb-0855-4157-9b45-272935da8c93',
		'5570da18-e8a5-4f5f-8813-185457f9918c',
		'5e881cd1-8592-4bbf-8af0-51849d3994ef',
		'6915fafc-3323-484d-b801-5daac73ddb56',
		'98f04981-677e-4f72-97c0-32778a7471a5',
		'b7d83f9c-609d-4861-8975-93b1e56acdd9',
		'd2059aa3-c670-4259-91d0-595d8faa3897',
		'e6fe3a0a-077b-4ff9-b063-2a786883b9d6',
		'ef043b34-4b11-46ee-908d-8fa0a5fb7f9e',
	],
	volapuk: [
		'345b9c2f-5f1c-4466-995b-539b02adf2b6',
		'96dc9b36-3ccd-4cbb-be04-41df7375f0e1',
		'adeedb93-946e-465d-ac9e-aa6429d61228',
		'b637f5f6-ec71-4642-b3f8-b681714e133f',
		'e4e4cc1b-deb3-4a74-bb6c-0a5137727d4c',
	],
} as Record<string, string[]>
