import {
	IPuzzle,
	createEmptyGridPuzzle,
	createEmptyHexPuzzle,
} from '@omichelsen/regex-lib'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAddPuzzleMutation } from '../../redux/api'
import { Seo } from '../common/Seo/Seo'
import { Builder } from './Builder'

const grid = Object.freeze(createEmptyGridPuzzle(2, 2))

const hex = Object.freeze(createEmptyHexPuzzle(1, 1))

export const BuilderNewContainer: React.FC<{ hexagonal?: boolean }> = ({
	hexagonal,
}) => {
	const navigate = useNavigate()

	const [addPuzzle] = useAddPuzzleMutation()

	const handleSave = useCallback(
		async (p: IPuzzle) => {
			const res = await addPuzzle(p as any).unwrap()
			navigate(`/builder/${res.id}`, { replace: true })
		},
		[addPuzzle, navigate]
	)

	const puzzle = hexagonal ? hex : grid

	return (
		<>
			<Seo title={`Builder: new ${hexagonal ? 'hexagonal' : 'grid'} puzzle`} />
			<Builder puzzle={puzzle} onSave={handleSave} />
		</>
	)
}
