import React from 'react'
import { useAppSelector } from '../../redux/hooks'
import { notificationsSelector } from '../../redux/selectors'
import { AchievementNotification } from '../AchievementNotification/AchievementNotification'

export const Notifications: React.FC = () => {
	const notifications = useAppSelector(notificationsSelector)

	return (
		<>
			{notifications.map((notification) => (
				<AchievementNotification key={notification.id} {...notification} />
			))}
		</>
	)
}
