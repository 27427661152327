import React, { useEffect, useState } from 'react'
import app from '../../redux/app/slice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { appSelector } from '../../redux/selectors'
import { sendEvent } from '../../services/analytics'
import { Button } from '../common/Button/Button'
import { Dialog } from '../common/Dialog/Dialog'

type Props = {
	onDismiss?: () => void
	onInstall?: () => void
}

interface InstallEvent extends Event {
	prompt: () => void
	userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>
}

window.addEventListener('appinstalled', () => {
	sendEvent('Install', 'Installed')
})

export const Install: React.FC<Props> = ({ onDismiss, onInstall }) => {
	const dispatch = useAppDispatch()

	const { deviceInfo, isInstalledToHomeScreen, showInstall } =
		useAppSelector(appSelector)

	const [installEvent, setInstallEvent] = useState<InstallEvent>()

	useEffect(() => {
		if (!deviceInfo) {
			return
		}
		const { platform, operatingSystem } = deviceInfo
		if (
			platform === 'web' &&
			(operatingSystem === 'ios' || operatingSystem === 'android') &&
			!isInstalledToHomeScreen &&
			showInstall === undefined
		) {
			dispatch(app.actions.toggleInstall(true))
		}
	}, [deviceInfo, isInstalledToHomeScreen, showInstall, dispatch])

	useEffect(() => {
		const showInstallPrompt = (e: Event) => {
			e.preventDefault()
			setInstallEvent(e as InstallEvent)
			sendEvent('Install', 'Prompt')
		}

		window.addEventListener('beforeinstallprompt', showInstallPrompt)

		return () =>
			window.removeEventListener('beforeinstallprompt', showInstallPrompt)
	}, [])

	const handleDismiss = () => {
		onDismiss?.()
		dispatch(app.actions.toggleInstall(false))
		setInstallEvent(undefined)
		sendEvent('Install', 'Dismissed')
	}

	const handleInstall = async () => {
		if (!installEvent) {
			return
		}

		installEvent.prompt()

		const choice = await installEvent.userChoice
		if (choice.outcome === 'accepted') {
			onInstall?.()
		}

		handleDismiss()
	}

	if (!installEvent && !showInstall) {
		return null
	}

	return (
		<Dialog data-testid="install" onClose={handleDismiss}>
			<p>
				For the best game experience please add Regex Crossword to the
				homescreen.
			</p>
			<Button
				aria-label="Close"
				data-testid="closeButton"
				onClick={handleDismiss}
				secondary
			>
				Close
			</Button>
			{!!installEvent && (
				<Button aria-label="Add To Homescreen" onClick={handleInstall}>
					Add
				</Button>
			)}
		</Dialog>
	)
}
