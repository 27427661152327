import { Capacitor } from '@capacitor/core'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import imgArrow from '../../assets/images/icons/arrow.svg'
import iconBug from '../../assets/images/icons/bug.svg'
import iconBullhorn from '../../assets/images/icons/bullhorn.svg'
import config from '../../config'
import { useGetPlayerPuzzlesQuery } from '../../redux/api'
import app from '../../redux/app/slice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
	appSelector,
	authSelector,
	challengePuzzlesSolvedSumSelector,
	nextPuzzleSelector,
	nextTutorialSelector,
	puzzlesSelector,
	solvedPuzzlesSelector,
	solvedSelector,
	solvedTutorialSelector,
	tutorialSelector,
} from '../../redux/selectors'
import { DonateModal } from '../DonateModal/DonateModal'
import { Install } from '../Install/Install'
import { AnimSquares } from '../common/AnimSquares/AnimSquares'
import { Button } from '../common/Button/Button'
import { ButtonGroup } from '../common/ButtonGroup/ButtonGroup'
import { ContextMenu } from '../common/ContextMenu/ContextMenu'
import { IconButton } from '../common/IconButton/IconButton'
import { LinkButton } from '../common/LinkButton/LinkButton'
import { Progress } from '../common/Progress/Progress'
import { Screen } from '../common/Screen/Screen'
import { Snow } from '../common/Snow/Snow'
import { Svg } from '../common/Svg/Svg'
import styles from './Home.scss'
import { HomeCards } from './HomeCards'

const isDecember = new Date().getMonth() === 11

export const Home: React.FC = () => {
	const navigate = useNavigate()

	const dispatch = useAppDispatch()
	const { homeIndex, showDesktopPuzzles } = useAppSelector(appSelector)
	const creds = useAppSelector(authSelector)
	const nextPuzzle = useAppSelector(nextPuzzleSelector)
	const nextTutorial = useAppSelector(nextTutorialSelector)
	const solved = useAppSelector(solvedSelector)
	const puzzlesCount = useAppSelector(puzzlesSelector).length
	const playerPuzzles = useGetPlayerPuzzlesQuery(showDesktopPuzzles)
	const solvedPlayerPuzzlesCount =
		playerPuzzles.data?.filter(({ id }) => id in solved).length || 0
	const solvedPuzzlesCount = useAppSelector(solvedPuzzlesSelector).length
	const solvedTutorialCount = useAppSelector(solvedTutorialSelector).length
	const tutorialCount = useAppSelector(tutorialSelector).length
	const solvedChallengePuzzlesSum = useAppSelector(
		challengePuzzlesSolvedSumSelector
	)

	const setHomeIndex = useCallback(
		(index: number) => dispatch(app.actions.setHomeIndex(index)),
		[dispatch]
	)

	const handleNextPuzzleClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		if (nextPuzzle) {
			navigate(`/puzzles/${nextPuzzle.id}`)
		} else {
			navigate('/puzzles')
		}
	}

	const handleNextTutorialClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		navigate(`/tutorial/${nextTutorial || 1}`)
	}

	const handleNextPlayerPuzzleClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		navigate('/playerpuzzles')
	}

	return (
		<Screen
			className={styles.home}
			data-testid="screenHome"
			title="Regex Crossword"
		>
			<HomeCards onChange={setHomeIndex} selectedIndex={homeIndex}>
				<div className={styles.tutorialCard} onClick={handleNextTutorialClick}>
					<AnimSquares />
					<div className={styles.tutorialDescription}>
						Learn the rules of regular expressions by solving crosswords
						<Svg className={styles.arrow} src={imgArrow} />
					</div>
					<div className={styles.name}>Tutorial</div>
					<div className={styles.menu}>
						<p>
							<Button
								className={classNames(
									styles.playButton,
									isDecember && styles.snowButton
								)}
								onClick={handleNextTutorialClick}
							>
								{nextTutorial > 1 ? 'Continue' : 'Play'}
							</Button>
						</p>
						<Progress
							className={styles.progress}
							value={solvedTutorialCount}
							maxValue={tutorialCount}
						/>
					</div>
				</div>
				<div
					className={styles.puzzlesCard}
					onClick={() => navigate('/puzzles')}
				>
					<AnimSquares />
					<div className={styles.name}>Puzzles</div>
					<div className={styles.menu}>
						<p>
							<Button
								className={classNames(
									styles.playButton,
									isDecember && styles.snowButton
								)}
								onClick={handleNextPuzzleClick}
							>
								{solvedPuzzlesCount ? 'Continue' : 'Play'}
							</Button>
						</p>
						<Progress
							className={styles.progress}
							value={solvedPuzzlesCount}
							maxValue={puzzlesCount}
						/>
					</div>
				</div>
				<div
					className={styles.challengesCard}
					onClick={() => navigate('/challenges')}
				>
					<AnimSquares />
					<div className={styles.name}>Challenges</div>
					<div className={styles.menu}>
						<p>
							<Button
								className={classNames(
									styles.playButton,
									isDecember && styles.snowButton
								)}
							>
								{solvedChallengePuzzlesSum.solved ? 'Continue' : 'Play'}
							</Button>
						</p>
						<Progress
							className={styles.progress}
							value={solvedChallengePuzzlesSum.solved}
							maxValue={solvedChallengePuzzlesSum.total}
						/>
					</div>
				</div>
				<div
					className={styles.playerPuzzlesCard}
					onClick={() => navigate('/playerpuzzles')}
				>
					<AnimSquares />
					<div className={styles.name}>Player Puzzles</div>
					<div className={styles.menu}>
						<p>
							<Button
								className={classNames(
									styles.playButton,
									isDecember && styles.snowButton
								)}
								onClick={handleNextPlayerPuzzleClick}
							>
								{solvedPlayerPuzzlesCount ? 'Continue' : 'Play'}
							</Button>
						</p>
						{!!playerPuzzles.data?.length && (
							<Progress
								className={styles.progress}
								value={solvedPlayerPuzzlesCount}
								maxValue={playerPuzzles.data?.length}
							/>
						)}
					</div>
				</div>
			</HomeCards>
			<Install />
			{creds.isAuthenticated ? (
				<LinkButton
					className={styles.profileButton}
					data-testid="profileButton"
					to={`/players/${creds.playerNo}`}
				>
					Profile
				</LinkButton>
			) : (
				<LinkButton
					className={styles.loginButton}
					data-testid="loginButton"
					to="/login"
				>
					Login
				</LinkButton>
			)}
			<ButtonGroup className={styles.userReportButtons}>
				<IconButton
					alt="Bug Report"
					onClick={() => window.open(config.userReport.bug, '_blank')}
					src={iconBug}
				/>
				<IconButton
					alt="Feedback & Ideas"
					onClick={() => window.open(config.userReport.overview, '_blank')}
					src={iconBullhorn}
				/>
			</ButtonGroup>
			<ContextMenu className={styles.infoButton}>
				<LinkButton className={styles.linkButton} to="/about" transparent>
					About
				</LinkButton>
				<LinkButton className={styles.linkButton} to="/howtoplay" transparent>
					How to play
				</LinkButton>
				<LinkButton className={styles.linkButton} to="/privacy" transparent>
					Privacy
				</LinkButton>
				<LinkButton
					className={styles.linkButton}
					target="_blank"
					to="https://www.facebook.com/RegexCrossword"
					transparent
				>
					Facebook
				</LinkButton>
			</ContextMenu>
			{!Capacitor.isNativePlatform() && <DonateModal />}
			<Snow />
		</Screen>
	)
}
