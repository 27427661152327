import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { App } from './components/App'
import { AppUrlListener } from './components/AppUrlListener/AppUrlListener'
import { Splash } from './components/Splash/Splash'
import { ReduxRehydrateGate } from './components/common/RehydrateGate/RehydrateGate'
import { rehydrator, store } from './redux/store'
import { init as appInit } from './services/app'
import './services/mobile'
import './styles/index.scss'

if (process.env.NODE_ENV === 'production') {
	import('./services/analytics').then((analytics) => analytics.init())
	import('./services/offline').then((offline) => offline.init())
}

rehydrator.then(appInit)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
	<HelmetProvider>
		<Provider store={store}>
			<ReduxRehydrateGate loader={<Splash />} rehydrator={rehydrator}>
				<BrowserRouter>
					<AppUrlListener />
					<App />
				</BrowserRouter>
			</ReduxRehydrateGate>
		</Provider>
	</HelmetProvider>
)
