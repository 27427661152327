import classNames from 'classnames'
import React from 'react'
import styles from './Switch.scss'

type Props = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> & {
	variant?: 'small' | 'normal'
}

export const Switch: React.FC<Props> = (props) => (
	<label
		className={classNames(styles.switch, {
			[styles.small]: props.variant === 'small',
		})}
	>
		<input type="checkbox" {...props} readOnly={!props.onChange} />
		<span
			className={classNames(styles.slider, {
				[styles.readOnly]: !props.onChange,
			})}
		></span>
	</label>
)
