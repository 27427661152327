import React, { useLayoutEffect, useState } from 'react'
import { getPosition } from '../../../services/dom'
import { Dot } from '../Dot/Dot'

type Props = React.HTMLAttributes<HTMLDivElement> & {
	/** Delay in ms to account for animations etc. Default: 100ms. */
	delay?: number
	selector: string
}

type Position = {
	top: number
	left: number
}

export const Pointer: React.FC<Props> = (props) => {
	const [pos, setPos] = useState<Position>()

	useLayoutEffect(() => {
		if (!props.selector) return
		setTimeout(() => {
			requestAnimationFrame(() => {
				const nodeFrom = document.querySelector(props.selector)
				if (nodeFrom) {
					setPos(getPosition(nodeFrom))
				}
			})
		}, props.delay || 100)
	}, [props.delay, props.selector])

	if (!pos) {
		return null
	}

	return <Dot className={props.className} style={pos} />
}
