import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetPuzzleQuery, useGetPuzzleSolutionQuery } from '../../redux/api'
import { RtkLoader } from '../common/RtkLoader/RtkLoader'
import { NotAllowed } from '../NotAllowed/NotAllowed'
// import { NotFound } from '../NotFound/NotFound'
import { Puzzle } from '../Puzzle/Puzzle'

/** Demo the solved puzzle animation for any given puzzle. */
export const SolvedContainer: React.FC = () => {
	const { puzzleId } = useParams()

	const puzzle = useGetPuzzleQuery(puzzleId!)
	const solution = useGetPuzzleSolutionQuery(puzzleId!)

	// TODO
	// if (puzzle.error?.message.includes('404')) {
	// 	return <NotFound />
	// }

	if (puzzle.data && !solution.data) {
		return <NotAllowed />
	}

	return (
		<RtkLoader {...puzzle}>
			{(data) => <Puzzle puzzle={data} answer={solution.data?.solution} />}
		</RtkLoader>
	)
}
