import { createAction } from '@reduxjs/toolkit'
import { throttle } from 'lodash-es'
import { loadState, saveState } from '../services/storage'
import { regexApi } from './api'
import type { RootState, Store } from './store'

/** Versioning of the reducer. */
const v = 1

type StoredState = Partial<RootState> & { v?: number }

export const rehydrate = createAction<StoredState>('rehydrate')

export const subscribeRehydrator = (store: Store) =>
	loadState<StoredState>().then((storedState) => {
		if (storedState && storedState.v === v) {
			delete storedState.v // rtk throws error for unknow props in state
			store.dispatch(rehydrate(storedState))
		}

		store.subscribe(
			throttle(() => {
				const state = store.getState()

				saveState({
					answers: state.answers,
					app: state.app,
					auth: state.auth,
					solved: state.solved,
					[regexApi.reducerPath]: state[regexApi.reducerPath],
					// add versioning
					v,
				} as StoredState)
			}, 1000)
		)

		return store
	})
