/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Link } from 'react-router-dom'
import { Content } from '../common/Content/Content'
import { Navbar } from '../common/Navbar/Navbar'
import { Screen } from '../common/Screen/Screen'

export const HowToPlay: React.FC = () => {
	return (
		<Screen>
			<Navbar />
			<Content>
				<h1>How to play</h1>

				<p>
					Regex Crossword is a game similar to sudoku or the traditional
					crossword puzzle, where you must guess the correct letters in the
					horizontal and vertical lines of a grid. In Regex Crossword you are
					not given a word to guess, but a pattern that tells you which letters
					are allowed.
				</p>

				<h4>Regex pattern</h4>
				<p>
					So what does such a regular expression pattern look like? Well, it can
					be very simple like <code>[AB]</code>, which will match the letter A
					or B. There are also special characters which indicate how many times
					a letter can appear in the text, e.g. <code>[AB]*</code> which means
					that A or B can occur <i>zero or more times</i>. The syntax can get a
					little more advanced than this, but you can always find a quick
					reference by clicking "<i className="icon-question"></i> Help" in the
					top right menu above.
				</p>

				<h2>Example solution</h2>

				<p>
					Let's walk through a test example, just to get the hang of it. Given
					the following Regex Crossword puzzle, you first need to look at the
					patterns, and see if there are some of them that can only have one
					solution.
				</p>

				<p>
					If we look at the first "clue" <code>A*</code>, it means that the
					first row can have <i>zero or more</i> A's. No other letters are
					allowed, so we just write A's all the way. That was easy!
				</p>

				<p>
					If we then look at the clue in the first column, <code>AB</code> is
					actually literally an A followed by B. We already have the A, so we
					add the B in the last position.
				</p>

				<p>
					Now we only need to fill in the last position, but the last two clues
					are a bit more tricky. <code>[CA]*</code> means that the <i>range</i>{' '}
					of letters C or A can occur <i>zero or more</i> times. The | symbol
					means <i>or</i>, so <code>(B|C)*</code> will match the letter B or C{' '}
					<i>zero or more</i> times. If we look at the last column, we already
					have an A in the first position, and the last position can be either C
					or A. But the clue in the last row only allow a B or C. Thus a C is
					the only letter we can put in the last position. Success - we have now
					solved the puzzle!
				</p>

				<p>
					This has hopefully prepared you for solving the{' '}
					<Link to="/">more challenging puzzles</Link>. Have fun!
				</p>
			</Content>
		</Screen>
	)
}
