import React from 'react'
import {
	useGetPuzzleRatingsQuery,
	useRatePuzzleMutation,
} from '../../redux/api'
import { useAppSelector } from '../../redux/hooks'
import { isAuthenticatedSelector } from '../../redux/selectors'
import { IconButton } from '../common/IconButton/IconButton'
import styles from './Rate.scss'
import iconStarEmpty from './star-empty.svg'
import iconStarFull from './star-full.svg'

type Props = {
	puzzleId: string
}

export const Rate: React.FC<Props> = ({ puzzleId }) => {
	const isAuthenticated = useAppSelector(isAuthenticatedSelector)
	const rating = useGetPuzzleRatingsQuery(puzzleId)
	const [ratePuzzle] = useRatePuzzleMutation()

	if (!isAuthenticated) {
		return null
	}

	const handleClick = (rating: number, e: React.MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
		ratePuzzle({ puzzleId, rating })
	}

	return (
		<div className={styles.rate}>
			{new Array(5).fill(0).map((_, i) => (
				<IconButton
					key={`star-${i}`}
					alt={`Rate puzzle ${i + 1}`}
					onClick={handleClick.bind(this, i + 1)}
					src={(rating.data?.rating || 0) > i ? iconStarFull : iconStarEmpty}
				/>
			))}
		</div>
	)
}
