import classNames from 'classnames'
import React from 'react'
import styles from './Select.scss'

type Props<T> = {
	className?: string
	items: Array<{ value: T; label: string }>
	label?: string
	selected: T
	onChange: (value: T) => void
}

export const Select = <T extends string>(props: Props<T>) => {
	return (
		<>
			{props.label && <label htmlFor="standard-select">{props.label}</label>}
			<div className={classNames(styles.container, props.className)}>
				<select
					id="standard-select"
					className={styles.select}
					onChange={(e) => props.onChange(e.target.value as T)}
					value={props.selected}
				>
					{props.items.map(({ value, label }) => (
						<option key={value} value={value}>
							{label}
						</option>
					))}
				</select>
				<span className={styles.focus}></span>
			</div>
		</>
	)
}
