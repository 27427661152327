// extracted by mini-css-extract-plugin
var _1 = "tfNyVdKNfL0mQ9idRbKw";
var _2 = "ZmmX1rSSuVbfb9ZvmUIc";
var _3 = "U_Rtpf13BMOa9QrIr1F5";
var _4 = "uL7xpxxZeVu4HFL4Xnoj";
var _5 = "lKRrkWte1ttayg4kEm8K";
var _6 = "Vcr5iM38bX9fcST4fekm";
var _7 = "dDKumNwsGJIQQI5amv34";
export { _1 as "active", _2 as "button", _3 as "disabled", _4 as "hover", _5 as "loading", _6 as "secondary", _7 as "transparent" }
export default { "active": _1, "button": _2, "disabled": _3, "hover": _4, "loading": _5, "secondary": _6, "transparent": _7 }
