import { isAnyOf } from '@reduxjs/toolkit'
import { regexApi } from '../api'
import { startAppListening } from '../middleware/listenerMiddleware'
import { rehydrate } from '../rehydrator'
import { authApi } from './api'
import slice from './slice'

// dispatch authenticate event to merge all successful auth events
startAppListening({
	matcher: isAnyOf(
		authApi.endpoints.login.matchFulfilled,
		authApi.endpoints.register.matchFulfilled,
		authApi.endpoints.authenticateSocial.matchFulfilled
	),
	effect: (action, listenerApi) => {
		listenerApi.dispatch(slice.actions.authenticated(action.payload as any))
	},
})

// fetch user data on authentication (above)
startAppListening({
	matcher: isAnyOf(slice.actions.authenticated, rehydrate),
	effect: (_, { dispatch, getState }) => {
		const {
			auth: { isAuthenticated, playerNo },
		} = getState()
		if (isAuthenticated && playerNo) {
			dispatch(regexApi.endpoints.getPlayerAchievements.initiate(playerNo))
			dispatch(regexApi.endpoints.getSolved.initiate())
		}
	},
})
