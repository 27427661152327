import classNames from 'classnames'
import React from 'react'
import styles from './ButtonGroup.scss'

type Props = { children: React.ReactNode; className?: string }

export const ButtonGroup: React.FC<Props> = ({ children, className }) => {
	return (
		<div className={classNames(styles.buttonGroup, className)}>{children}</div>
	)
}
