import React from 'react'
import imgLogo from '../../assets/images/logo.svg'
import { Svg } from '../common/Svg/Svg'
import styles from './Splash.scss'

export const Splash: React.FC = () => (
	<div className={styles.splash} data-testid="splash">
		<Svg className={styles.logo} src={imgLogo} />
	</div>
)
