// extracted by mini-css-extract-plugin
var _1 = "sQYWbNylHZDZMhfcsgb_";
var _2 = "Zc7Y_652XargEoXtj83x";
var _3 = "H8UW5TmAVTs_98YxYtpK";
var _4 = "KtNx2mJy0ms6kHT2jVRU";
var _5 = "XAAHK_I3iTUvmutnfxIA";
var _6 = "cMbD6BdiOXDQTao67r_m";
var _7 = "bg2Eme7NmOzUXCJjFL0J";
var _8 = "lh_k9oSXwxFRjAUf_LHu";
var _9 = "Dqvk5zkayXW8DgNyzlBK";
var _a = "LcS_CCQtBDoIyPfIicOM";
var _b = "GpRAxOxlGbJYyeuDfX2B";
var _c = "tp3bbJENBSWRa_BV9gtC";
var _d = "Gzg1CnxZlfg1lWEF_5bF";
var _e = "U7MH3OnsuMZNjfMJFdHQ";
var _f = "F6XUazCTk7s1h2uH5wLa";
var _10 = "HtyI4rxnu9DDSifV7M9M";
var _11 = "oM2Gyk3RBcZCsTwz6ORX";
var _12 = "s7QWwNX_nH6EKGlOFiBz";
var _13 = "YXkBsaAmXcDE8bCky7gQ";
var _14 = "GLecUG_p2QNn8Fqlvo3X";
export { _1 as "arrow", _2 as "challengesCard", _3 as "desktop", _4 as "home", _5 as "infoButton", _6 as "linkButton", _7 as "loginButton", _8 as "menu", _9 as "name", _a as "navButtonLeft", _b as "navButtonRight", _c as "playButton", _d as "playerPuzzlesCard", _e as "profileButton", _f as "progress", _10 as "puzzlesCard", _11 as "snowButton", _12 as "tutorialCard", _13 as "tutorialDescription", _14 as "userReportButtons" }
export default { "arrow": _1, "challengesCard": _2, "desktop": _3, "home": _4, "infoButton": _5, "linkButton": _6, "loginButton": _7, "menu": _8, "name": _9, "navButtonLeft": _a, "navButtonRight": _b, "playButton": _c, "playerPuzzlesCard": _d, "profileButton": _e, "progress": _f, "puzzlesCard": _10, "snowButton": _11, "tutorialCard": _12, "tutorialDescription": _13, "userReportButtons": _14 }
