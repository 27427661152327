import { Haptics, ImpactStyle } from '@capacitor/haptics'
import {
	Coordinates,
	IAnswer,
	IPuzzlePatterns,
	validateAnswer,
	validatePatterns,
} from '@omichelsen/regex-lib'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../redux/hooks'
import { appSelector } from '../../../redux/selectors'
import { TextField } from '../../common/TextField/TextField'
import { CluesRow } from '../CluesRow'
import { Field } from '../Field/Field'
import { getMaxClueLength, scrollToCenter } from '../Helpers'
import styles from '../Puzzle.scss'

export type Props = {
	allowEdit?: boolean
	allowInput?: boolean
	answer: IAnswer
	focus?: Coordinates
	puzzle: IPuzzlePatterns
	patternsValidation?: ReturnType<typeof validatePatterns>
	solutionValidation?: ReturnType<typeof validateAnswer>
	solved?: boolean
	onFocusField?: (coords: Coordinates) => void
	onPressField?: (coords: Coordinates) => void
	setFieldValue?: (coords: Coordinates, value: string) => void
	setPatternValue?: (d: 'X' | 'Y' | 'Z', i: number, j: 0 | 1, v: string) => void
}

export const Grid: React.FC<Props> = ({
	allowEdit = false,
	allowInput = false,
	answer,
	focus: propsFocus = [0, 0],
	puzzle,
	patternsValidation,
	solutionValidation,
	solved = false,
	onFocusField,
	onPressField,
	setFieldValue,
	setPatternValue,
}) => {
	const [focus, setFocus] = useState<Coordinates>(propsFocus)
	const { disableHaptics } = useAppSelector(appSelector)

	const gridRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		requestAnimationFrame(() => scrollToCenter(gridRef.current))
	}, [])

	const handlePressField = (coords: Coordinates, e: React.MouseEvent) => {
		setFocus(coords)
		onFocusField?.(coords)
		onPressField?.(coords)
		// allows click to focus since pointer-events are disabled
		if (allowInput) {
			;(e.target as HTMLElement).querySelector('input')?.focus()
		}
	}

	const handleDoubleClickField = (coords: Coordinates) => {
		// assumes that single click always fires as well (only works on desktop)
		setFieldValue?.(coords, '')
	}

	const handleDrop = (coords: Coordinates, e: React.DragEvent) => {
		e.preventDefault()
		;(e.target as HTMLElement).classList.remove(styles.over)
		const char = e.dataTransfer!.getData('text/plain')
		setFocus(coords)
		onFocusField?.(coords)
		setFieldValue?.(coords, char)
		if (!disableHaptics)
			Haptics.impact({ style: ImpactStyle.Light }).catch(() => {
				// ignore fail on devices without haptics
			})
	}

	const clueLength = getMaxClueLength(puzzle)

	return (
		<div
			className={classNames(
				styles.gridPuzzle,
				styles[`clueWidth${clueLength}`]
			)}
			data-testid="puzzle"
			ref={gridRef}
		>
			<table>
				<thead>
					<CluesRow
						allowEdit={allowEdit}
						errors={patternsValidation?.patternsX}
						focus={focus!}
						index={0}
						patterns={puzzle.patternsX}
						onChange={(i, v) => setPatternValue?.('X', i, 0, v)}
					/>
				</thead>
				<tbody>
					{puzzle.patternsY.map(([patternA, patternB], y) => (
						<tr
							className={classNames({
								[styles.highlight]: y === focus![1],
							})}
							key={`clueYA${y}`}
						>
							<th
								className={classNames(styles.clue, {
									[styles.error]:
										patternsValidation?.patternsY[y]?.[0] === false,
								})}
							>
								<TextField
									autoComplete="off"
									className={styles.input}
									readOnly={!allowEdit}
									tabIndex={-1}
									value={patternA}
									onChange={(e) => setPatternValue?.('Y', y, 0, e.target.value)}
								/>
							</th>
							{answer[y].map((char, x) => (
								<td key={`field${x}-${y}`}>
									<Field
										className={classNames({
											[styles[`solvedAnimation${x + y}`]]: solved,
										})}
										data-id={`field${x}-${y}`}
										data-value={char}
										highlight={x === focus![0]}
										error={solutionValidation?.answer[y]?.[x] === false}
										onClick={handlePressField.bind(this, [x, y])}
										onDoubleClick={handleDoubleClickField.bind(this, [x, y])}
										onDrop={handleDrop.bind(this, [x, y])}
										value={char?.trim()}
										readOnly={!allowInput}
										onChange={(e) => setFieldValue?.([x, y], e.target.value)}
										onFocus={() => {
											setFocus([x, y])
											onFocusField?.([x, y])
										}}
									/>
								</td>
							))}
							{(allowEdit || patternB) && (
								<th
									className={classNames(styles.clue, {
										[styles.error]:
											patternsValidation?.patternsY[y]?.[1] === false,
									})}
									key={`clueYB${y}`}
								>
									<TextField
										autoComplete="off"
										className={styles.input}
										readOnly={!allowEdit}
										tabIndex={-1}
										value={patternB}
										onChange={(e) =>
											setPatternValue?.('Y', y, 1, e.target.value)
										}
									/>
								</th>
							)}
						</tr>
					))}
				</tbody>
				<tfoot>
					<CluesRow
						allowEdit={allowEdit}
						errors={patternsValidation?.patternsX}
						focus={focus!}
						index={1}
						patterns={puzzle.patternsX}
						onChange={(i, v) => setPatternValue?.('X', i, 1, v)}
					/>
				</tfoot>
			</table>
		</div>
	)
}
