import * as RegexLib from '@omichelsen/regex-lib'
import React, { useMemo } from 'react'
import { useGetPuzzleAmbiguousQuery } from '../../redux/api'
import styles from './Builder.scss'

const formatSpaceToBlocks = (s: string) => s.replace(/ /g, '█')
const formatToAscii = (a: RegexLib.IAnswer[]) =>
	formatSpaceToBlocks(
		a.reduce((s, a) => `${s}[${a.map((row) => row.join('')).join()}]\n`, '')
	)

export const ValidationSummary: React.FC<{
	puzzle: RegexLib.IPuzzle
	patternsValidation: ReturnType<typeof RegexLib.validatePatterns>
	solutionValidation?: ReturnType<typeof RegexLib.validateAnswer>
}> = ({ puzzle, patternsValidation, solutionValidation }) => {
	const ambiguous = useGetPuzzleAmbiguousQuery(puzzle.id)

	const summary = useMemo(() => {
		const output: string[] = []

		if (!patternsValidation.isValid) output.push('Invalid puzzle patterns')
		if (solutionValidation?.hasEmpty) output.push('Solution has empty fields')

		if (!output.length) {
			const u = RegexLib.testUniqueness(
				{
					patternsX: puzzle.patternsX,
					patternsY: puzzle.patternsY,
					patternsZ: puzzle.patternsZ,
				},
				puzzle.solution!
			)

			if (u.length === 0) return 'Puzzle or solution is invalid'
			if (u.length === 1) return 'Puzzle is valid'

			output.push(
				`Puzzle is ambiguous (${u.length} solutions):\n${formatToAscii(u)}`
			)
		}

		return output.join('\n')
	}, [
		patternsValidation.isValid,
		solutionValidation?.hasEmpty,
		puzzle.patternsX,
		puzzle.patternsY,
		puzzle.patternsZ,
		puzzle.solution,
	])

	return (
		<div>
			<pre className={styles.validationSummary}>{summary}</pre>
			{!!ambiguous.data?.length && (
				<pre className={styles.validationSummary}>
					{`Valid ambiguous answers:\n${formatToAscii(
						ambiguous.data.map((s) => JSON.parse(s))
					)}`}
				</pre>
			)}
			<div className={styles.validationDisclaimer}>
				Notice that validation is not a 100% guarantee that the puzzle is
				unambiguous. Feedback from other players can help you find potential
				ambiguities.
			</div>
		</div>
	)
}
