import classNames from 'classnames'
import React from 'react'
import { Content } from '../Content/Content'
import { TextField } from './TextField'
import styles2 from './TextField.example.scss'
import styles from './TextField.scss'

/**
 * Format: [label, placeholder, value, helperText, errorText, disabled, required, focus, readOnly]
 */
const data = [
	[undefined, 'No label', '', '', '', false, false, false, false],
	['Empty', 'No value', '', '', '', false, false, false, false],
	['Name', 'Name', 'Mr Magoo', '', '', false, false, false, false],
	['Focus', 'Focus', 'Donald Duck', '', '', false, false, true, false],
	['Readonly', '', 'Field is readonly', '', '', false, false, false, true],
	['Disabled', '', 'Field is disabled', '', '', true, false, false, false],
	[
		'Required',
		'Error state',
		'',
		'',
		'This is error text shown below the text field',
		false,
		true,
		false,
		false,
	],
	[
		'Helper',
		'',
		'Helper text',
		'This is helper text shown below the text field',
		'',
		false,
		false,
		false,
		false,
	],
] as const

const Example: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<Content className={className}>
			{data.map(
				(
					[
						label,
						placeholder,
						defaultValue,
						helperText,
						errorText,
						disabled,
						required,
						focus,
						readOnly,
					],
					i
				) => (
					<div key={`ex${i}`}>
						<TextField
							{...{
								label,
								placeholder,
								defaultValue,
								helperText,
								errorText,
								disabled,
								required,
								readOnly,
							}}
							className={classNames({
								[styles.dirty]: required,
								[styles.focus]: focus,
							})}
						/>
					</div>
				)
			)}
		</Content>
	)
}

export const TextFieldExample: React.FC = () => {
	return (
		<div>
			<Example />
			<Example className={styles2.orange} />
			<Example className={styles2.green} />
			<Example className={styles2.red} />
			<Example className={styles2.purple} />
		</div>
	)
}
