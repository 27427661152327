import classNames from 'classnames'
import React from 'react'
import { Seo } from '../Seo/Seo'
import styles from './Screen.scss'

// const cancel = (e: TouchEvent) => e.preventDefault()

type Props = {
	children?: React.ReactNode
	className?: string
	description?: string
	style?: React.CSSProperties
	title?: string
}

export const Screen: React.FC<Props> = ({
	children,
	className,
	description,
	style,
	title,
	...rest
}) => {
	// React.useEffect(() => {
	// 	// Prevent content scroll on touch devices
	// 	if ('ontouchstart' in window) {
	// 		document.addEventListener('touchmove', cancel, { passive: false })
	// 	}
	// 	return () => document.removeEventListener('touchmove', cancel)
	// }, [props.title])

	return (
		<div
			className={classNames(styles.screen, className)}
			style={style}
			{...rest}
		>
			{(title || description) && (
				<Seo title={title} description={description} />
			)}
			{children}
		</div>
	)
}
