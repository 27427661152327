import { useEffect, useLayoutEffect } from 'react'
import { noop } from '../../../services/noop'
import { cleanupUnscramble, unscramble } from '../Helpers'

export const Unscramble = ({
	map = [],
	unmountAfter,
	onClick = noop,
}: {
	map: number[]
	unmountAfter?: number
	onClick?: () => void
}) => {
	useLayoutEffect(() => {
		let timer: NodeJS.Timeout
		requestAnimationFrame(() => {
			unscramble(map)
			if (unmountAfter) {
				timer = setTimeout(() => {
					onClick()
					cleanupUnscramble()
				}, unmountAfter)
			}
		})
		return () => {
			clearTimeout(timer)
			cleanupUnscramble()
		}
	}, [map, onClick, unmountAfter])

	useEffect(() => {
		const elm = document.querySelector('[data-testid=puzzle]')
		elm?.addEventListener('click', onClick)
		return () => elm?.removeEventListener('click', onClick)
	}, [onClick])

	return null
}
