import * as RegexLib from '@omichelsen/regex-lib'
import React, { useMemo, useState } from 'react'
import { Grid } from '../Puzzle/Grid/Grid'
import { Hexagonal } from '../Puzzle/Hexagonal/Hexagonal'
import stylesb from './Builder.scss'
import { BuilderSettings } from './BuilderSettings'
import { ValidationSummary } from './ValidationSummary'

type Props = {
	puzzle: RegexLib.IPuzzle
	onSave: (puzzle: RegexLib.IPuzzle) => Promise<any>
}

export const Builder: React.FC<Props> = (props) => {
	const [puzzle, setPuzzle] = useState(props.puzzle)

	const patternsValidation = useMemo(
		() =>
			RegexLib.validatePatterns({
				patternsX: puzzle.patternsX,
				patternsY: puzzle.patternsY,
				patternsZ: puzzle.patternsZ,
			}),
		[puzzle.patternsX, puzzle.patternsY, puzzle.patternsZ]
	)

	const solutionValidation = useMemo(
		() =>
			patternsValidation.isValid
				? RegexLib.validateAnswer(
						{
							patternsX: puzzle.patternsX,
							patternsY: puzzle.patternsY,
							patternsZ: puzzle.patternsZ,
						},
						puzzle.solution!
				  )
				: undefined,
		[
			patternsValidation,
			puzzle.patternsX,
			puzzle.patternsY,
			puzzle.patternsZ,
			puzzle.solution,
		]
	)

	const isPuzzleValid = useMemo(
		() => patternsValidation.isValid && !!solutionValidation?.isValid,
		[patternsValidation.isValid, solutionValidation?.isValid]
	)

	const setPatternValue = (
		d: 'X' | 'Y' | 'Z',
		i: number,
		j: 0 | 1,
		v: string
	) => {
		const prop = `patterns${d}` as const
		const newPattern = [...(puzzle[prop] ?? [])]
		newPattern[i][j] = v
		setPuzzle({
			...puzzle,
			[prop]: newPattern,
		})
	}

	const setFieldValue = (coords: RegexLib.Coordinates, value: string) => {
		const solution = RegexLib.setAnswerValue(puzzle.solution!, coords, value)
		setPuzzle({ ...puzzle, solution })
	}

	const PuzzleTemplate = puzzle.hexagonal ? Hexagonal : Grid

	return (
		<div className={stylesb.builderScreen}>
			<div>
				<div>
					<PuzzleTemplate
						allowEdit
						allowInput
						answer={puzzle.solution!}
						puzzle={puzzle}
						patternsValidation={patternsValidation}
						solutionValidation={solutionValidation}
						setFieldValue={setFieldValue}
						setPatternValue={setPatternValue}
					/>
				</div>
				<ValidationSummary
					puzzle={puzzle}
					patternsValidation={patternsValidation}
					solutionValidation={solutionValidation}
				/>
			</div>
			<BuilderSettings
				isPuzzleValid={isPuzzleValid}
				puzzle={puzzle}
				onChange={setPuzzle}
				onSubmit={props.onSave!}
			/>
		</div>
	)
}
