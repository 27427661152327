import classNames from 'classnames'
import React from 'react'
import { Content } from '../Content/Content'
import { Button } from './Button'
import styles2 from './Button.example.scss'
import styles from './Button.scss'

const types = [
	['Default'],
	['Secondary', { secondary: true }],
	['Transparent', { transparent: true }],
] as const

const states = [
	{},
	// { focus: true }, don't have this state due to touch
	{ hover: true },
	{ active: true },
	{ disabled: true },
	{ loading: true },
]

const Example: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<Content className={className}>
			<div className={styles2.container}>
				<h3>Default</h3>
				<h3>Hover</h3>
				<h3>Active</h3>
				<h3>Disabled</h3>
				<h3>Loading</h3>
			</div>
			{types.map(([text, props], i) => (
				<div className={styles2.container} key={`ex${i}`}>
					{states.map((s, j) => (
						<Button
							key={`state${j}`}
							{...props}
							loading={s.loading}
							className={classNames({
								// [styles.focus]: s.focus,
								[styles.hover]: s.hover,
								[styles.active]: s.active,
								[styles.disabled]: s.disabled,
							})}
						>
							{text}
						</Button>
					))}
				</div>
			))}
		</Content>
	)
}

export const ButtonExample: React.FC = () => {
	return (
		<div>
			<Example className={styles2.blue} />
			<Example className={styles2.orange} />
			<Example className={styles2.green} />
			<Example className={styles2.red} />
			<Example className={styles2.purple} />
		</div>
	)
}
