import type { IAnswer } from '@omichelsen/regex-lib'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AnswersState = {
	[puzzleId: string]: IAnswer
}

export const initialState: AnswersState = {}

export type AnswerPayload = {
	puzzleId: string
	answer: IAnswer
}

export default createSlice({
	name: 'answers',
	initialState,
	reducers: {
		set: (
			state,
			{ payload: { puzzleId, answer } }: PayloadAction<AnswerPayload>
		) => {
			state[puzzleId] = answer
		},
		remove: (state, action: PayloadAction<string>) => {
			delete state[action.payload]
		},
	},
})
