import React from 'react'
import styles from './Spinner.scss'

type Props = {
	className?: string
}

export const Spinner: React.FC<Props> = ({ className }) => (
	<div className={className} data-testid="spinner">
		<div className={styles.spinner} />
	</div>
)
