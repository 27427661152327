import classNames from 'classnames'
import React, { useState } from 'react'
import audioPop from '../../assets/sounds/pop.mp4'
import { useAudio } from '../../hooks/useAudio'
import { useAppSelector } from '../../redux/hooks'
import { achievementsSelector, appSelector } from '../../redux/selectors'
import { getImgPath } from '../../services/achievementImages'
import { Content } from '../common/Content/Content'
import { Pill } from '../common/Pill/Pill'
import { Popup } from '../common/Popup/Popup'
import styles from './Achievements.scss'

type Props = {
	earned?: Record<string, number>
}

export const Achievements: React.FC<Props> = ({ earned = {} }) => {
	const achievements = useAppSelector(achievementsSelector)
	const { disableSoundEffects } = useAppSelector(appSelector)
	const [details, setDetails] = useState<(typeof achievements)[number]>()
	const [, toggle] = useAudio(audioPop)

	return (
		<Content className={styles.container}>
			{achievements.map((achievement) => (
				<div
					className={classNames(styles.achievement, {
						[styles.earned]: achievement.id in earned,
					})}
					key={achievement.id}
					onClick={() => {
						setDetails(achievement)
						if (!disableSoundEffects) {
							toggle()
						}
					}}
				>
					<img
						className={styles.image}
						src={getImgPath(achievement.id)}
						alt={achievement.name}
						draggable={false}
					/>
					<div className={styles.name}>{achievement.name}</div>
				</div>
			))}
			{!!details && (
				<Popup onClick={() => setDetails(undefined)}>
					<img
						className={styles.image}
						src={getImgPath(details.id)}
						alt={details.name}
						draggable={false}
					/>
					<h2>{details.name}</h2>
					<p>{details.description}</p>
					<Pill>{rarity(details.earned)}</Pill>
				</Popup>
			)}
		</Content>
	)
}

const rarity = (n: number) => {
	if (n >= 10000) return 'common'
	if (n >= 5000) return 'moderate'
	if (n >= 1000) return 'elite'
	return 'legendary'
}
