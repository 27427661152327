import React from 'react'
import styles from './PlayerName.scss'

type Props = {
	playerNo: number
	nickname?: string
}

export const PlayerName: React.FC<Props> = ({ playerNo, nickname }) => {
	if (nickname) {
		return (
			<span className={styles.playerName}>
				<span className={styles.nickname}>{nickname}</span>{' '}
				<span className={styles.playerNo}>(#{playerNo})</span>
			</span>
		)
	}

	return <>{`Player #${playerNo}`}</>
}
