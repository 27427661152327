import React from 'react'
import { useNavigate } from 'react-router-dom'
import iconBack from '../../../assets/images/icons/nav-back.svg'
import { Icon } from '../Icon/Icon'
import { TextButton } from '../TextButton/TextButton'
import styles from './Navbar.scss'

export const Navbar: React.FC<{
	children?: React.ReactNode
	onBackNavigation?: () => void
}> = (props) => {
	const navigate = useNavigate()

	return (
		<div className={styles.navbar}>
			<TextButton
				className={styles.backButton}
				data-testid="navbar"
				onClick={() =>
					props.onBackNavigation ? props.onBackNavigation() : navigate(-1)
				}
			>
				<Icon className={styles.icon} src={iconBack} title="Go back" />
				Back
			</TextButton>
			{props.children}
		</div>
	)
}
