import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FixedSizeList as List } from 'react-window'
import useResizeObserver from 'use-resize-observer'
import { useGetMyPuzzlesQuery } from '../../redux/api'
import { useAppSelector } from '../../redux/hooks'
import { authSelector } from '../../redux/selectors'
import { PuzzleListItem } from '../PlayerPuzzles/PuzzleListItem'
import { Header } from '../common/Header/Header'
import { Navbar } from '../common/Navbar/Navbar'
import { RtkLoader } from '../common/RtkLoader/RtkLoader'
import { Screen } from '../common/Screen/Screen'
import { TextButton } from '../common/TextButton/TextButton'

export const MyPuzzles: React.FC = () => {
	const params = useParams() as any
	const playerNo = Number(params.playerNo)
	const navigate = useNavigate()

	const myPuzzles = useGetMyPuzzlesQuery(playerNo)

	const me = useAppSelector(authSelector)
	const isMe = playerNo === Number(me.playerNo)

	const { ref, height: heightContent = 1 } = useResizeObserver<HTMLDivElement>()

	return (
		<Screen title="My puzzles">
			<Navbar>
				<TextButton
					data-testid="newPuzzleButton"
					onClick={() => navigate('/builder')}
				>
					New puzzle
				</TextButton>
			</Navbar>
			<Header>
				<h1>My Puzzles</h1>
			</Header>
			<RtkLoader {...myPuzzles} myRef={ref}>
				{(puzzles) => (
					<List
						height={heightContent}
						itemCount={puzzles.length}
						itemSize={50}
						width="100%"
					>
						{({ index, style }) => (
							<PuzzleListItem
								puzzle={puzzles[index]}
								style={style}
								url={`/${isMe ? 'builder' : 'playerpuzzles'}/${
									puzzles[index].id
								}`}
							/>
						)}
					</List>
				)}
			</RtkLoader>
		</Screen>
	)
}
