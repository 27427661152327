/* eslint-disable react/display-name */
import classNames from 'classnames'
import React, { ReactElement, Ref } from 'react'
import { Button } from '../Button/Button'
import { Spinner } from '../Spinner/Spinner'
import styles from './RtkLoader.scss'

type Props<T> = {
	children?: (data: T) => ReactElement
	className?: string
	data?: T
	error?: any
	isFetching?: boolean
	isError?: boolean
	isLoading?: boolean
	refetch?: () => void
}

export const RtkLoader = <T extends object>({
	children,
	className,
	data,
	error,
	isFetching,
	isLoading,
	isError,
	myRef,
	refetch,
}: Props<T> & { myRef?: Ref<HTMLDivElement> }) => {
	if (isLoading) {
		return <Spinner className={styles.spinner} />
	}

	if (isError) {
		return (
			<Button className={styles.retryButton} onClick={refetch}>
				Retry
			</Button>
		)
	}

	return (
		<div
			className={classNames(styles.children, className)}
			ref={myRef}
			data-testid="loader"
		>
			{isFetching && (
				<div className={styles.inlineSpinner} data-testid="spinner" />
			)}
			{error && <div className={styles.inlineError} data-testid="error" />}
			{data && children?.(data)}
		</div>
	)
}
