import React from 'react'
import { Navbar } from '../common/Navbar/Navbar'
import { Screen } from '../common/Screen/Screen'
import { Hexagonal } from '../Puzzle/Hexagonal/Hexagonal'
import styles from './NotAllowed.scss'

const puzzle = Object.freeze({
	id: '401',
	name: 'Not allowed',
	patternsX: [
		['Not', ''],
		['allowed', ''],
	],
	patternsY: [
		['Page', ''],
		['not', ''],
		['allowed', ''],
	],
	patternsZ: [
		['Not', ''],
		['allowed', ''],
	],
	hexagonal: true,
})

const answer = [['4'], ['0', '0'], [null, null, '1']]

export const NotAllowed: React.FC = () => {
	return (
		<Screen className={styles.notAllowed} title="Not allowed">
			<Navbar />
			<div className={styles.content}>
				<Hexagonal puzzle={puzzle} answer={answer} />
			</div>
		</Screen>
	)
}
