import { App } from '@capacitor/app'
import { batch } from 'react-redux'
import { authApi } from '../redux/auth/api'
import app from '../redux/app/slice'
import { getDeviceInfo } from '../redux/app/slice'
import type { Store } from '../redux/store'
import { init as authInit } from './social'

const {
	getIsAppInstalledToHomeScreen,
	setConnectionStatus,
	setPrefersReducedMotion,
} = app.actions

/**
 * General app logic that happens either at startup
 * or intervals during the app lifecycle.
 */
export const init = (store: Store) => {
	// Handle hardware back button on Android
	App.addListener('backButton', ({ canGoBack }) => {
		if (!canGoBack) {
			App.exitApp()
		} else {
			window.history.back()
		}
	})

	batch(() => {
		// Sync connection status to Redux state
		const dispatchConnectionStatus = () =>
			store.dispatch(setConnectionStatus(navigator.onLine))
		window.ononline = dispatchConnectionStatus
		window.onoffline = dispatchConnectionStatus

		// Sync prefers reduced motion
		const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
		store.dispatch(setPrefersReducedMotion(mediaQuery.matches))
		mediaQuery.addEventListener('change', () =>
			store.dispatch(setPrefersReducedMotion(mediaQuery.matches))
		)

		// Sync PWA state to Redux state
		store.dispatch(getIsAppInstalledToHomeScreen())

		// Sync app info to Redux state
		store.dispatch(getDeviceInfo())

		// Auth with social if cookies present
		authInit((user) => {
			if (user) {
				store.dispatch(authApi.endpoints.authenticateSocial.initiate(user))
			}
		})
	})
}
