import React from 'react'
import { FixedSizeList as List } from 'react-window'
import useResizeObserver from 'use-resize-observer'
import { useGetMyPuzzlesQuery } from '../../../redux/api'
import { useAppSelector } from '../../../redux/hooks'
import { authSelector } from '../../../redux/selectors'
import { PuzzleListItem } from '../../PlayerPuzzles/PuzzleListItem'
import { Content } from '../../common/Content/Content'
import { Header } from '../../common/Header/Header'
import { LinkButton } from '../../common/LinkButton/LinkButton'
import { RtkLoader } from '../../common/RtkLoader/RtkLoader'
import styles from './BuilderLanding.scss'

export const BuilderLanding = () => {
	const me = useAppSelector(authSelector)
	const playerNo = Number(me.playerNo)
	const myPuzzles = useGetMyPuzzlesQuery(playerNo)

	const { ref, height: heightContent = 1 } = useResizeObserver<HTMLDivElement>()

	return (
		<>
			<Header>
				<h1>Puzzle Builder</h1>
				<p>Create your own puzzles to share with the community.</p>
			</Header>
			<Content className={styles.buttons}>
				<LinkButton className={styles.button} to="/builder/new/grid">
					New Grid
				</LinkButton>
				<LinkButton className={styles.button} to="/builder/new/hex">
					New Hexagonal
				</LinkButton>
			</Content>
			<RtkLoader {...myPuzzles} myRef={ref}>
				{(puzzles) => (
					<List
						className={styles.list}
						height={heightContent}
						itemCount={puzzles.length}
						itemSize={50}
						width="100%"
					>
						{({ index, style }) => (
							<PuzzleListItem
								puzzle={puzzles[index]}
								style={style}
								url={`/builder/${puzzles[index].id}`}
							/>
						)}
					</List>
				)}
			</RtkLoader>
		</>
	)
}
