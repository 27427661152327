import React from 'react'
import styles from './Meter.scss'

type Props = React.HTMLAttributes<HTMLElement> & {
	label: string
	max: number
	value: number
}

export const Meter: React.FC<Props> = ({ label, max, value, ...rest }) => (
	<div className={styles.meter} {...rest}>
		<label htmlFor={label} className={styles.meterLabel}>
			{label}
		</label>
		<meter
			id={label}
			className={styles.meter}
			min={0}
			max={max}
			value={value}
		/>
	</div>
)
