// extracted by mini-css-extract-plugin
var _1 = "efXjl27QvVDgEN_Q5D_E";
var _2 = "bs35R6Rk9KhbMtLvs1Ah";
var _3 = "xTf8sRfiJonaQZjfoSC3";
var _4 = "Snm3nQygNfPLQzpMLkyd";
var _5 = "EsanS_rpsqMgPepXamRA";
var _6 = "WyLAMP2_5jxVgRxojhWd";
var _7 = "_X5PRDEvKf7DLNd5GofX";
var _8 = "sG6VbwiwIzWHRMFXSOuQ";
var _9 = "iJSlZlI1y_qO0e_g0dso";
var _a = "h780RmLLZRU3AvmGQWDg";
var _b = "frwFnggttBmAQstDcVmH";
export { _1 as "controls", _2 as "fieldAmbiguous", _3 as "fieldDate", _4 as "fieldPlayer", _5 as "fieldSize", _6 as "group", _7 as "label", _8 as "list", _9 as "playerPuzzles", _a as "screen", _b as "select" }
export default { "controls": _1, "fieldAmbiguous": _2, "fieldDate": _3, "fieldPlayer": _4, "fieldSize": _5, "group": _6, "label": _7, "list": _8, "playerPuzzles": _9, "screen": _a, "select": _b }
