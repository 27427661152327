import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import styles from './Field.scss'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
	error?: boolean
	hexagonal?: boolean
	highlight?: boolean
	selected?: boolean
}

export const Field: React.FC<Props> = ({
	children,
	className,
	error,
	hexagonal,
	highlight,
	onChange,
	onDrop,
	onFocus,
	readOnly,
	selected,
	tabIndex,
	value,
	...rest
}) => {
	const handleDrop = useCallback(
		(e: React.DragEvent<HTMLInputElement>) => {
			e.preventDefault()
			;(e.target as HTMLElement).classList.remove(styles.over)
			onDrop?.(e)
		},
		[onDrop]
	)

	const dragTargetHandlers = useMemo(
		() =>
			rest.draggable
				? {}
				: {
						onDragEnter,
						onDragLeave,
						onDragOver,
						onDrop: handleDrop,
				  },
		[rest.draggable, handleDrop]
	)

	return (
		<span
			className={classNames(
				styles.field,
				{
					[styles.hexagonal]: hexagonal,
					[styles.highlight]: highlight,
					[styles.error]: error,
					[styles.selected]: selected,
				},
				className
			)}
			{...dragTargetHandlers}
			{...rest}
		>
			<input
				autoComplete="off"
				className={styles.charInput}
				maxLength={1}
				onChange={onChange}
				onFocus={onFocus}
				readOnly={readOnly}
				required
				tabIndex={tabIndex}
				value={value}
			/>
			{children}
		</span>
	)
}

const onDragEnter = (e: React.DragEvent) => {
	e.preventDefault()
	;(e.target as HTMLElement).classList.add(styles.over)
}

const onDragLeave = (e: React.DragEvent) => {
	e.preventDefault()
	;(e.target as HTMLElement).classList.remove(styles.over)
}

const onDragOver = (e: React.DragEvent) => {
	e.preventDefault()
}
