import React from 'react'
import iconGift from '../../assets/images/icons/gift.svg'
import app from '../../redux/app/slice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { appSelector } from '../../redux/selectors'
import { sendEvent } from '../../services/analytics'
import { IconButton } from '../common/IconButton/IconButton'
import { Modal } from '../common/Modal/Modal'
import { TextButton } from '../common/TextButton/TextButton'
import { TextField } from '../common/TextField/TextField'
import styles from './DonateModal.scss'

export const DonateModal: React.FC = () => {
	const dispatch = useAppDispatch()
	const { showDonate } = useAppSelector(appSelector)

	return (
		<>
			<IconButton
				alt="Support us!"
				className={styles.openModalButton}
				onClick={() => dispatch(app.actions.toggleDonate())}
				src={iconGift}
			/>
			<Modal
				className={styles.donateModal}
				onClose={() => dispatch(app.actions.toggleDonate(false))}
				show={showDonate}
			>
				<h1>Support Regex Crossword!</h1>
				<p>
					Creating and maintaining Regex Crossword is something we do for fun.
					If you enjoy our crossword puzzles please consider making a small
					donation to show your appreciation. It will allow us to pay for
					hosting and keep on improving the site.
				</p>
				<p>
					<TextButton
						className={styles.donateButton}
						onClick={() => {
							sendEvent('Donate', 'Paypal')
							window.open(
								'https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=GMUAF7MWFZWDC&lc=GB&item_name=Regex%20Crossword&item_number=regexcrossword&currency_code=EUR&bn=PP%2dDonationsBF%3abtn_donate_SM%2egif%3aNonHosted',
								'_blank'
							)
						}}
						title="Donate via PayPal"
					>
						Donate (PayPal)
					</TextButton>
				</p>
				<h2>Crypto</h2>
				<ul>
					<li>
						<h3>Bitcoin (BTC):</h3>
						<TextField
							value="189Wtw36eFyWM5JfrBChwr6y9bT4QJncuP"
							onClick={() => sendEvent('Donate', 'BTC')}
							readOnly
						/>
					</li>
					<li>
						<h3>Bitcoin Cash (BCH):</h3>
						<TextField
							value="qr6dt9ctxd3ad02sp3xursgm2xdrwlh9au5gym0773"
							onClick={() => sendEvent('Donate', 'BCH')}
							readOnly
						/>
					</li>
					<li>
						<h3>Ethereum (ETH):</h3>
						<TextField
							value="0x38B33240A28c1099e9B69a454cbF58b64fffDc46"
							onClick={() => sendEvent('Donate', 'ETH')}
							readOnly
						/>
					</li>
				</ul>
			</Modal>
		</>
	)
}
