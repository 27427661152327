import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import answers from '../../redux/answers/slice'
import { useGetChallengePuzzlesQuery } from '../../redux/api'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { challengesMapSelector } from '../../redux/selectors'
import { getImgPath } from '../../services/metadataImages'
import { NotFound } from '../NotFound/NotFound'
import { Puzzle } from '../Puzzle/Puzzle'
import { RtkLoader } from '../common/RtkLoader/RtkLoader'
import { Seo } from '../common/Seo/Seo'

export const ChallengePuzzleContainer: React.FC = () => {
	const navigate = useNavigate()
	const { challengeId, puzzleId } = useParams()

	const challenge = useAppSelector(challengesMapSelector).get(challengeId!)

	const answer = useAppSelector((state) => state.answers[puzzleId!])
	const dispatch = useAppDispatch()

	const challengePuzzles = useGetChallengePuzzlesQuery(challengeId!)

	if ((challengePuzzles.error as any)?.status === 404 || !challenge) {
		return <NotFound />
	}

	const challengePuzzle = challengePuzzles.data?.find(
		({ id }) => id === puzzleId
	)

	return (
		<RtkLoader {...challengePuzzles} data={challengePuzzle}>
			{(puzzle) => (
				<>
					<Seo
						title={`${challenge.name}: ${puzzle.name}`}
						description={`Puzzle "${puzzle.name}" in the "${challenge.name}" challenge. Complete all challenge puzzles to earn the "${challenge.achievementId}" achievement.`}
						image={getImgPath(puzzle.id)}
					/>
					<Puzzle
						answer={answer}
						puzzle={puzzle}
						onAnswer={(puzzleId, answer) =>
							dispatch(answers.actions.set({ puzzleId, answer }))
						}
						onBackNavigation={() => navigate(`/challenges/${challengeId}`)}
						onSuccessDismiss={() => navigate(`/challenges/${challengeId}`)}
					/>
				</>
			)}
		</RtkLoader>
	)
}
