import React from 'react'
import { Outlet } from 'react-router-dom'
import { Secure } from '../Secure/Secure'
import { Screen } from '../common/Screen/Screen'

export const SettingsScreen: React.FC = () => {
	return (
		<Secure>
			<Screen>
				<Outlet />
			</Screen>
		</Secure>
	)
}
