import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import iconKeyboard from '../../assets/images/icons/keyboard.svg'
import { useAppSelector } from '../../redux/hooks'
import {
	challengePuzzlesSolvedSelector,
	challengesSelector,
} from '../../redux/selectors'
import { AnimSquares } from '../common/AnimSquares/AnimSquares'
import { Content } from '../common/Content/Content'
import { Header } from '../common/Header/Header'
import { Icon } from '../common/Icon/Icon'
import { Navbar } from '../common/Navbar/Navbar'
import { Progress } from '../common/Progress/Progress'
import { Seo } from '../common/Seo/Seo'
import styles from './Challenges.scss'

export const Challenges: React.FC = () => {
	const navigate = useNavigate()

	const challenges = useAppSelector(challengesSelector)
	const solved = useAppSelector(challengePuzzlesSolvedSelector)

	return (
		<>
			<Seo title="Challenges" />
			<AnimSquares />
			<Navbar onBackNavigation={() => navigate('/')} />
			<Header>
				<h1>Challenges</h1>
			</Header>
			<Content>
				<div className={styles.grid}>
					{challenges.map(({ id, name, mobile }) => (
						<Link
							className={styles.button}
							key={id}
							to={`/challenges/${id}`}
							draggable={false}
						>
							<span className={styles.label}>{name}</span>
							<Progress
								className={styles.progress}
								value={solved[id].solved}
								maxValue={solved[id].total}
							/>
							{!mobile && <Icon className={styles.icon} src={iconKeyboard} />}
						</Link>
					))}
				</div>
			</Content>
		</>
	)
}
