import classNames from 'classnames'
import React from 'react'
import { Svg } from '../Svg/Svg'
import styles from './Icon.scss'

type Props = React.HTMLAttributes<HTMLElement> & {
	src: string
}

export const Icon: React.FC<Props> = ({ className, ...rest }) => (
	<Svg {...rest} className={classNames(styles.icon, className)} />
)
