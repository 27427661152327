import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'
import { tutorialSelector } from '../../redux/selectors'
import { Puzzle } from '../Puzzle/Puzzle'
import { FloatingBox } from '../common/FloatingBox/FloatingBox'
import { Seo } from '../common/Seo/Seo'

export const Tutorial4: React.FC = (props) => {
	const navigate = useNavigate()

	const tutorial = useAppSelector(tutorialSelector)

	const [step, setStep] = React.useState(0)

	return (
		<div>
			<Seo title="Tutorial: 4" />
			<FloatingBox selector="[data-testid=clues]" show={step === 0}>
				<p>
					A <b>backreference</b> <code>(A)\1</code> is repeating the content of
					a group (anything in a parenthesis). Group are numbered from 1 to x.
				</p>
			</FloatingBox>
			<FloatingBox selector="[data-testid=keyboard]" show={step === 1}>
				<p>
					<b>Curly brackets</b> <code>A&#123;2,4&#125;</code> repeats something{' '}
					<b>X to Y times</b>. Just one number and comma means from X to
					infinite.
				</p>
			</FloatingBox>
			<Puzzle
				{...props}
				onBackNavigation={() => navigate('/')}
				onPressField={([x]) => setStep(x)}
				onSuccessDismiss={() => navigate('/tutorial/5')}
				puzzle={tutorial[3]}
				showErrors
			/>
		</div>
	)
}
