import classNames from 'classnames'
import React from 'react'
import styles from './Content.scss'

export const Content: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
	children,
	className,
	...rest
}) => {
	return (
		<div className={classNames(styles.content, className)} {...rest}>
			{children}
		</div>
	)
}
