import hello, { HelloJSEventArgument } from 'hellojs'
import config from '../config'

export type Network = 'facebook' | 'github' | 'google' | 'twitter' | 'windows'

export interface SocialPlayer {
	id: string
	name: string
	network: Network
	token: string
}

let authChangeCallback: (user?: SocialPlayer | void) => void = (user) => user

export const getUserInfo = async ({
	network,
	authResponse,
}: HelloJSEventArgument) => {
	const profile = await hello(network).api('me', 'get')

	let { name } = profile
	if (typeof name === 'object') {
		name = `${profile.first_name} ${profile.last_name}`
	}

	const user = {
		id: profile.id as string,
		name: name as string,
		network: network as Network,
		token: authResponse?.access_token ?? '',
	}

	authChangeCallback(user)
}

export const login = (network: Network) => hello(network).login()

export const logout = (network: Network) =>
	hello(network)
		.logout()
		.then(
			// logout() returns an object with only 'network' so cast to undefined
			() => undefined,
			// ignore error if session has already been terminated
			(e: any) => {
				if (e?.error?.code !== 'invalid_session') {
					throw e
				}
			}
		)
		.then(authChangeCallback)

export const init = (onAuthChange: (user?: SocialPlayer | void) => void) => {
	authChangeCallback = onAuthChange

	hello.init(config.networks, {
		redirect_uri: '/redirect.html',
	})

	hello.on('auth.login', getUserInfo)
}
