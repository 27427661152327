import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import answers from '../../redux/answers/slice'
import { useGetPuzzleQuery } from '../../redux/api'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { authSelector } from '../../redux/selectors'
import { getImgPath } from '../../services/metadataImages'
import { NotFound } from '../NotFound/NotFound'
import { Puzzle } from '../Puzzle/Puzzle'
import { RtkLoader } from '../common/RtkLoader/RtkLoader'
import { Seo } from '../common/Seo/Seo'

export const PlayerPuzzleContainer: React.FC = () => {
	const navigate = useNavigate()
	const { puzzleId } = useParams()

	const puzzle = useGetPuzzleQuery(puzzleId!)
	const answer = useAppSelector((state) => state.answers[puzzleId!])
	const creds = useAppSelector(authSelector)
	const dispatch = useAppDispatch()

	if ((puzzle.error as any)?.status === 404) {
		return <NotFound />
	}

	const isMine = creds.playerNo === puzzle.data?.playerNo

	return (
		<RtkLoader {...puzzle}>
			{(data) => (
				<>
					<Seo
						title={data.name}
						description={`Puzzle "${data.name}" created by Player #${data.playerNo}. You can create your own grid or hexagonal puzzles, and rank the best puzzles made by other players.`}
						image={getImgPath(data.id)}
					/>
					<Puzzle
						allowRating
						answer={answer}
						puzzle={data}
						showComments
						showEdit={isMine}
						showSharing
						onAnswer={(puzzleId, answer) =>
							dispatch(answers.actions.set({ puzzleId, answer }))
						}
						onBackNavigation={() => navigate('/playerpuzzles')}
						onSuccessDismiss={() => navigate('/playerpuzzles')}
					/>
				</>
			)}
		</RtkLoader>
	)
}
