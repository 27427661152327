import classNames from 'classnames'
import React, { useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'
import iconClose from '../../../assets/images/icons/close.svg'
import { useKeyPress } from '../../../hooks/useKeyPress'
import { IconButton } from '../IconButton/IconButton'
import styles from './Modal.scss'

type Props = {
	children?: React.ReactNode
	className?: string
	onClose: () => void
	show: boolean
}

export const Modal: React.FC<Props> = ({
	children,
	className,
	onClose,
	show,
}) => {
	const handleEscape = useCallback(
		(e: KeyboardEvent) => {
			if (show) {
				e.stopPropagation()
				onClose()
			}
		},
		[show, onClose]
	)
	useKeyPress(['Escape'], handleEscape)

	return (
		<CSSTransition classNames="fade" in={show} unmountOnExit timeout={200}>
			<div className={styles.container}>
				<IconButton
					alt="Close"
					className={styles.closeButton}
					onClick={onClose}
					src={iconClose}
				/>
				<div className={classNames(styles.modal, className)}>{children}</div>
			</div>
		</CSSTransition>
	)
}
