import type { components } from '../../typings.api'
import { regexApi } from '../api/index'

type AuthResponse = components['schemas']['AuthResponse']

export const authApi = regexApi.injectEndpoints({
	endpoints: (builder) => ({
		authenticateSocial: builder.mutation<
			AuthResponse,
			{ network: string; token: string }
		>({
			query: (args) => ({
				url: 'auth',
				method: 'POST',
				body: args,
			}),
		}),
		register: builder.mutation<
			AuthResponse,
			{ username: string; password: string }
		>({
			query: (args) => ({
				url: 'auth/register',
				method: 'POST',
				body: args,
			}),
		}),
		addCredentials: builder.mutation<
			AuthResponse,
			{ username: string; password: string }
		>({
			query: (args) => ({
				url: 'auth/register',
				method: 'PUT',
				body: args,
			}),
		}),
		login: builder.mutation<
			AuthResponse,
			{ username: string; password: string }
		>({
			query: (args) => ({
				url: 'auth/sessions',
				method: 'POST',
				body: args,
			}),
		}),
		changePassword: builder.mutation<
			void,
			{ oldPassword: string; newPassword: string }
		>({
			query: (args) => ({
				url: 'auth/password',
				method: 'PATCH',
				body: args,
			}),
		}),
		resetPassword: builder.mutation<void, string>({
			query: (username) => ({
				url: 'auth/reset_password',
				method: 'POST',
				body: { username },
			}),
		}),
	}),
	overrideExisting: false,
})

export const {
	useAddCredentialsMutation,
	useAuthenticateSocialMutation,
	useChangePasswordMutation,
	useLoginMutation,
	useRegisterMutation,
	useResetPasswordMutation,
} = authApi
