import React, { useEffect, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
	useGetMyPuzzlesQuery,
	useGetPlayerAchievementsQuery,
	useGetPlayerQuery,
} from '../../redux/api'
import { useAppSelector } from '../../redux/hooks'
import { authSelector } from '../../redux/selectors'
import { Achievements } from '../Achievements/Achievements'
import { NotFound } from '../NotFound/NotFound'
import { PlayerName } from '../PlayerName/PlayerName'
import { Header } from '../common/Header/Header'
import { Navbar } from '../common/Navbar/Navbar'
import { RtkLoader } from '../common/RtkLoader/RtkLoader'
import { Screen } from '../common/Screen/Screen'
import { TextButton } from '../common/TextButton/TextButton'
import styles from './Profile.scss'

export const Profile = () => {
	const mountedRef = useRef(true)
	const navigate = useNavigate()
	const params = useParams()
	const playerNo = Number(params.playerNo)

	useEffect(
		() => () => {
			mountedRef.current = false
		},
		[playerNo]
	)

	const me = useAppSelector(authSelector)
	const isMe = playerNo === Number(me.playerNo)
	const myPuzzles = useGetMyPuzzlesQuery(playerNo)

	const profile = useGetPlayerQuery(playerNo)
	const achievements = useGetPlayerAchievementsQuery(playerNo)

	if (isNaN(playerNo) || (profile.error as any)?.status >= 400) {
		return <NotFound />
	}

	const joined =
		profile.data?.joined &&
		new Date(profile.data?.joined * 1000).toLocaleString('default', {
			month: 'short',
			year: 'numeric',
		})

	return (
		<Screen
			title={`Player #${playerNo}`}
			description={`Profile for Player #${playerNo} showing puzzles solved, achievements earned and their global rank.`}
		>
			<Navbar>
				{isMe && (
					<TextButton
						data-testid="settingsButton"
						onClick={() => navigate('/settings')}
					>
						Settings
					</TextButton>
				)}
			</Navbar>
			<Header>
				<h1>
					<PlayerName playerNo={playerNo} {...profile.data} />
				</h1>
			</Header>
			<RtkLoader
				className={styles.stats}
				data={{}} // want to show content before data is loaded
				isFetching={profile.isFetching}
			>
				{() => (
					<>
						<Link className={styles.badge} to="/leaderboard" draggable={false}>
							<span className={styles.number}>
								{profile.data?.ranking || '-'}
							</span>{' '}
							rank
						</Link>
						<Link className={styles.badge} to="/stats" draggable={false}>
							<span className={styles.number}>
								{profile.data?.score || '-'}
							</span>{' '}
							solved
						</Link>
						<Link className={styles.badge} to="puzzles" draggable={false}>
							<span className={styles.number}>
								{myPuzzles.data?.length ?? '-'}
							</span>{' '}
							puzzles
						</Link>
						<div className={styles.badge}>
							<span className={styles.number}>{joined || '-'}</span> joined
						</div>
					</>
				)}
			</RtkLoader>
			<Achievements earned={achievements.data} />
		</Screen>
	)
}
