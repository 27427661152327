import { authApi } from '../auth/api'
import { solved } from '../solved/slice'
import { startAppListening } from './listenerMiddleware'

declare global {
	interface Window {
		fbq?(
			event: 'track',
			action: 'CompleteRegistration',
			options?: {
				content_name?: string
				currency?: string
				status?: boolean
				value?: number
			}
		): void
		fbq?(
			event: 'trackCustom',
			action: string,
			options?: {
				content_name?: string
				currency?: string
				status?: boolean
				value?: number
			}
		): void
	}
}

startAppListening({
	matcher: authApi.endpoints.register.matchFulfilled,
	effect: () => {
		window.fbq?.('track', 'CompleteRegistration', { status: true })
	},
})

startAppListening({
	actionCreator: solved,
	effect: () => {
		window.fbq?.('trackCustom', 'SolvedPuzzle')
	},
})
