import { regexApi } from '../api'
import './achievements'
import './analytics'
import './appRating'
import { authMiddleware } from './auth'
import './fbq'

import '../answers/listeners'
import '../auth/listeners'
import '../solved/listeners'

export const middleware = [regexApi.middleware, authMiddleware]

if (process.env.NODE_ENV === 'development') {
	const { logger } = require('redux-logger') // eslint-disable-line global-require
	middleware.push(logger)
}
