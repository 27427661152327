import classNames from 'classnames'
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import iconCheckboxChecked from '../../../assets/images/icons/checkbox-checked.svg'
import iconCheckbox from '../../../assets/images/icons/checkbox.svg'
import iconHome from '../../../assets/images/icons/home.svg'
import { useGetChallengePuzzlesQuery } from '../../../redux/api'
import { useAppSelector } from '../../../redux/hooks'
import { challengesMapSelector, solvedSelector } from '../../../redux/selectors'
import { NotFound } from '../../NotFound/NotFound'
import { AnimSquares } from '../../common/AnimSquares/AnimSquares'
import { ButtonGroup } from '../../common/ButtonGroup/ButtonGroup'
import { Content } from '../../common/Content/Content'
import { Header } from '../../common/Header/Header'
import { Icon } from '../../common/Icon/Icon'
import { IconButton } from '../../common/IconButton/IconButton'
import { Navbar } from '../../common/Navbar/Navbar'
import { RtkLoader } from '../../common/RtkLoader/RtkLoader'
import { Seo } from '../../common/Seo/Seo'
import styles from './ChallengePuzzles.scss'

export const ChallengePuzzles: React.FC = () => {
	const { challengeId } = useParams()

	const navigate = useNavigate()

	const challenge = useAppSelector(challengesMapSelector).get(
		challengeId as any
	)
	const solved = useAppSelector(solvedSelector)
	const challengePuzzles = useGetChallengePuzzlesQuery(challengeId!)

	if ((challengePuzzles.error as any)?.status === 404 || !challenge) {
		return <NotFound />
	}

	return (
		<>
			<Seo title={challenge.name} />
			<AnimSquares />
			<Navbar onBackNavigation={() => navigate('/challenges')}>
				<ButtonGroup>
					<IconButton
						alt="Leaderboard"
						className={styles.icon}
						onClick={() => navigate('/')}
						src={iconHome}
					/>
				</ButtonGroup>
			</Navbar>
			<Header>
				<h1>{challenge.name}</h1>
			</Header>
			<RtkLoader {...challengePuzzles}>
				{(puzzles) => (
					<Content>
						<div className={styles.grid}>
							{puzzles.map((p) => (
								<Link
									className={styles.button}
									key={p.id}
									to={`/challenges/${challenge.id}/puzzles/${p.id}`}
									draggable={false}
								>
									<Icon
										className={classNames(styles.icon, {
											[styles.checked]: p.id in solved,
										})}
										src={p.id in solved ? iconCheckboxChecked : iconCheckbox}
									/>
									<span className={styles.label}>{p.name}</span>
								</Link>
							))}
						</div>
					</Content>
				)}
			</RtkLoader>
		</>
	)
}
