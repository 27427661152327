import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import answers from '../../redux/answers/slice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { nextPuzzleSelector, puzzlesSelector } from '../../redux/selectors'
import { getImgPath } from '../../services/metadataImages'
import { NotFound } from '../NotFound/NotFound'
import { Seo } from '../common/Seo/Seo'
import { Puzzle } from './Puzzle'

export const PuzzleContainer: React.FC = () => {
	const navigate = useNavigate()
	const { puzzleId } = useParams()

	const puzzles = useAppSelector(puzzlesSelector)
	const nextPuzzle = useAppSelector(nextPuzzleSelector)

	const handleSuccessDismiss = () => {
		if (nextPuzzle) {
			navigate(`/puzzles/${nextPuzzle.id}`)
		} else {
			navigate('/puzzles')
		}
	}

	const puzzle = puzzles.find(({ id }) => id === puzzleId)
	const answer = useAppSelector((state) => state.answers[puzzleId!])
	const dispatch = useAppDispatch()

	if (!puzzle) {
		return <NotFound />
	}

	return (
		<>
			<Seo title={puzzle.name} image={getImgPath(puzzle.id)} />
			<Puzzle
				answer={answer}
				puzzle={puzzle}
				onAnswer={(puzzleId, answer) =>
					dispatch(answers.actions.set({ puzzleId, answer }))
				}
				onBackNavigation={() => navigate('/puzzles')}
				onSuccessDismiss={handleSuccessDismiss}
			/>
		</>
	)
}
