import classNames from 'classnames'
import React from 'react'
import styles from './Pill.scss'

export const Pill: React.FC<
	{
		children?: React.ReactNode
		className?: string
	} & React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLSpanElement>,
		HTMLSpanElement
	>
> = ({ children, className, ...rest }) => (
	<span className={classNames(styles.pill, className)} {...rest}>
		{children}
	</span>
)
